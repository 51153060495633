import { ToastOptions } from "@ionic/react";

/*
 * APP
 */
export const APP_DEBUG                                = true;
export const APP_VERSION                              = "0.3";
export const APP_API_VERSION                          = "v.0.1";

export const APP_COMPANY_ID                           = "DM";
export const APP_COMPANY_NAME                         = "DM";

export const APP_LANGUAGE_SUPPORTS                    = ['de', 'en'];
export const APP_LANGUAGE_DEFAULT                     = 'de';

export const APP_USE_DATASOURCES                      = true; // @deprecated
export const APP_DATASOURCES                          = ['muc', 'fma']; // @deprecated

/*
 * COMPONENTS
 */
export const APP_COMPONENT_ID_PRESELECT               = 'preselect' as const;
export const APP_COMPONENT_ID_NOTES                   = 'notes' as const;

// not implemented. for changing ui from list items to card items
export const APP_COMPONENT_ID_LISTVIEW                = 'listview' as const;

export const APP_COMPONENTS_AVAILABLE                 = [
  APP_COMPONENT_ID_PRESELECT,
  APP_COMPONENT_ID_NOTES
] as ReadonlyArray<string>;

// Notes
export const APP_COMPONENT_NOTES_USE_BACK_ICON        = false;

// Documents
export const APP_COMPONENT_DOCS_USE_BACK_ICON         = false;

/*
 * ANALYTICS
 */
export const ANALYTICS_ACTIVE                         = false;
export const ANALYTICS_TENANT_ID                      = '175914d0ca000136527515b2';

/*
 * Storage
 */
export const STORAGE_CELL_SEPERATOR                   = '_' as const;

// Config
export const STORAGE_TABLE_CONFIG                     = 'config';

// User
export const STORAGE_TABLE_USER                       = 'user';
export const STORAGE_TABLE_USER_CELL_LOGIN            = 'isLoggedIn';

/*
 * QUERIES
 */
export const QUERY_USE_PLACEHOLDER_DATA               = true;

// Keys
export const QUERY_KEY_SEPERATOR                      = '.';
export const QUERY_KEY_APPLICANT                      = 'applicant';
export const QUERY_KEY_APPLICANTS                     = 'applicants';
export const QUERY_KEY_JOB                            = 'job';
export const QUERY_KEY_JOBS                           = 'jobs';
export const QUERY_KEY_STATUS                         = 'status';

/*
 * TOAST
 */
export const TOAST_DURATION_DEFAULT                   = 2000;
export const TOAST_OPTIONS_DEFAULT: ToastOptions      = {
  duration: TOAST_DURATION_DEFAULT,
  position: "bottom"
};

/*
 * LAYOUT
 */
export const LAYOUT_LIST_STYLE_ID_LIST                = 'list' as const;
export const LAYOUT_LIST_STYLE_ID_CARD                = 'card' as const;
export const LAYOUT_LIST_STYLES                       = [
  LAYOUT_LIST_STYLE_ID_LIST,
  LAYOUT_LIST_STYLE_ID_CARD
] as const;
export const LAYOUT_LIST_STYLE_DEFAULT                = LAYOUT_LIST_STYLE_ID_LIST;
export const LAYOUT_LIST_ITEM_INDICATOR               = true;
export const LAYOUT_LIST_ITEM_SLIDE                   = true;
export const LAYOUT_LIST_ITEM_SLIDE_BTNS_START        = 1;
export const LAYOUT_LIST_ITEM_SLIDE_BTNS_END          = 1;

// CARD VIEWS
export const LAYOUT_CARD_VIEWS_POSITION_ID_HEADER     = 1;
export const LAYOUT_CARD_VIEWS_POSITION_ID_BODY       = 2;
export const LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER     = 3;
export const LAYOUT_CARD_VIEWS_CSS_CLASS_ITEM_DETAIL  = 'c-value';

// STATUS DISPLAY TYPES
export const LAYOUT_STATUS_DISPLAY_TYPE_ID_JOBS       = 1;
export const LAYOUT_STATUS_DISPLAY_TYPE_ID_APPLICANTS = 2;

// APPLICANT
export const LAYOUT_APPLICANT_LIST                    = LAYOUT_LIST_STYLE_DEFAULT;

// JOBS
export const LAYOUT_JOBS_LIST                         = LAYOUT_LIST_STYLE_DEFAULT;

// JOBS APPLICANTS
export const LAYOUT_JOBS_APPLICANTS_LIST              = LAYOUT_LIST_STYLE_DEFAULT;

/*
 * PDF
 */
export const PDF_WORKER_SRC                           = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/{:version}/pdf.worker.js' as const;

/*
 * Status
 */
export const STATUS_ID_ALL                            = -1 as const;
export const STATUS_SLUG_ALL                          = 'all' as const;

/*
 * Applicant
 */
export const APPLICANT_SEGMENT_ID_PROFILE             = 'profile' as const;
export const APPLICANT_SEGMENT_ID_NOTES               = 'comments' as const;
export const APPLICANT_SEGMENT_ID_CONTACT             = 'contact' as const;
export const APPLICANT_SEGMENT_ID_HISTORY             = 'auditHistory' as const;
export const APPLICANT_SEGMENT_ID_INVITES             = 'invites' as const;
export const APPLICANT_SEGMENT_DEFAULT                = APPLICANT_SEGMENT_ID_PROFILE;
export const APPLICANT_SEGMENTS_AVAILABLE             = [
  APPLICANT_SEGMENT_ID_PROFILE,
  APPLICANT_SEGMENT_ID_NOTES,
  APPLICANT_SEGMENT_ID_CONTACT,
  APPLICANT_SEGMENT_ID_HISTORY
] as ReadonlyArray<string>;

/*
 * Experimental
 */
export const EXPERIMENTAL_GET_STORAGE_DATA_FIRST             = false;
export const EXPERIMENTAL_GET_STORAGE_DATA_FIRST_DELAY       = 4000;