import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonLoading, IonPage, IonRouterLink, IonRow, IonSkeletonText, IonSpinner, IonText, IonTitle, IonToolbar, useIonAlert, useIonRouter, useIonToast } from '@ionic/react';

import './JobDetail.css';
import { useJobApplicantsQuery, useJobDetailQuery } from '../hooks/useApi';
import { RouteComponentProps } from 'react-router';
import QueryStateIndicator from '../components/QueryStateIndicator';
import { getPageUrl } from '../utils/urls';
import { getDateReadable } from '../utils/util';
import { copyOutline } from 'ionicons/icons';
import { APP_DEBUG, TOAST_DURATION_DEFAULT } from '../utils/const';
import { TJob, TJobDetail } from '../types';
import { getCardViewDetail, getCardViewPart } from "../components/cardviews/CardViews";
import { renderCardViewDetailsValue } from "../components/cardviews/CardViewsItemDetail";
import { renderCardViewDetails } from "../components/cardviews/CardViewsItem";
import { useTranslation } from 'react-i18next';

interface JobData {
  job: TJobDetail
}

const JobData: React.FC<JobData> = ( {...props} ) => {
  const job = props.job;

  const headerRows = useMemo(() => getCardViewPart('header', job.details.cardViews), [job.details.cardViews]);
  const bodyRows = useMemo(() => getCardViewPart('body', job.details.cardViews), [job.details.cardViews]);
  
  const bodyRowsMain = useMemo(() => {
    return bodyRows.filter((row) => row.grouping === 'main');
  }, [bodyRows]);

  return <IonGrid style={{paddingTop: 0}} className='v-job-detail__section'>
    <IonRow>
      <IonCol>
        <h1 className='v-job-detail__title'>
          {headerRows?.map(( row ) => {            
            return row.cardViewDetails.map(( detail ) => {
              if( detail.displayType === 'show_localized_label' ) return;
              return renderCardViewDetailsValue(detail);
            });
          })}
        </h1>        
        <table className='v-job-detail__data-table'>
          <tbody>
            {bodyRowsMain.map(( row ) => {
              return <tr>
                {row.cardViewDetails.map(( detail ) => {
                  if( detail.displayType === 'show_localized_label' ) {
                    return <td>{detail.labelName}:</td>
                  }
                  return <td>{renderCardViewDetailsValue(detail)}</td>
                })}
              </tr>
            })}            
          </tbody>
        </table>
      </IonCol>
    </IonRow>
  </IonGrid>
}

interface JobCurrentApplicantsProps {
  job: TJobDetail
};
const JobCurrentApplicants:  React.FC<JobCurrentApplicantsProps> = ( {...props} ) => {
  
  const job = props.job;

  return <IonGrid className='v-job-detail__section'>
    <IonRow>
      <IonCol><h2 className="v-job-detail__section-headline">Aktuelle Bewerber</h2></IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        <div className='v-job-detail__section-content'>
          
        </div>
      </IonCol>
    </IonRow>
  </IonGrid>;
}

interface JobPersonsProps {
  job: TJobDetail
};
const JobPersons: React.FC<JobPersonsProps> = ( {...props} ) => {
  
  const job = props.job;

  const headerRows = useMemo(() => getCardViewPart('header', job.details.cardViews), [job.details.cardViews]);
  const bodyRows = useMemo(() => getCardViewPart('body', job.details.cardViews), [job.details.cardViews]);

  const headlineRef = getCardViewDetail('responsiblePersons', headerRows);

  const bodyRowsRecruiter = useMemo(() => {
    return bodyRows.filter((row) => row.grouping === 'recruiter');
  }, [bodyRows]);  
  const bodyRowsHiringManager = useMemo(() => {
    return bodyRows.filter((row) => row.grouping === 'hiringManager');
  }, [bodyRows]);  
  const bodyRowsHiringManagerTeam = useMemo(() => {
    return bodyRows.filter((row) => row.grouping === 'hiringManagerTeam');
  }, [bodyRows]);  

  return <IonGrid className='v-job-detail__section'>
    <IonRow>
      <IonCol><h2 className="v-job-detail__section-headline">{headlineRef?.labelName}:</h2></IonCol>
    </IonRow>
    
    {/* Recruiter */}
    {bodyRowsRecruiter && <><IonRow>
      <IonCol><h2 className="v-job-detail__section-headline v-job-detail__section-headline--sub mb-0">Recruiter:</h2></IonCol>
    </IonRow> 
    <IonRow>
      <IonCol>
        <div className='v-job-detail__section-content'>

          <ul>
            {bodyRowsRecruiter.map((row) => {
              return <li className="u-value-flow">{renderCardViewDetails(row)}</li>
            })}
          </ul>

        </div>
      </IonCol>
    </IonRow></>}
    
    {/* Mitarbeitersuchender */}
    {bodyRowsHiringManager && bodyRowsHiringManager.length > 0 && <><IonRow>
      <IonCol><h2 className="v-job-detail__section-headline v-job-detail__section-headline--sub">Mitarbeitersuchender:</h2></IonCol>
    </IonRow>  
    <IonRow>
      <IonCol>
        <div className='v-job-detail__section-content'>

          <ul>
            {bodyRowsHiringManager.map((row) => {
              return <li className="u-value-flow">{renderCardViewDetails(row)}</li>
            })}
          </ul>

        </div>

      </IonCol>
    </IonRow></>}
    
    {/* Mitarbeitersuchender (Team) */}
    {bodyRowsHiringManagerTeam && bodyRowsHiringManagerTeam.length > 0 && <><IonRow>
      <IonCol><h2 className="v-job-detail__section-headline v-job-detail__section-headline--sub">Mitarbeitersuchender (Team):</h2></IonCol>
    </IonRow>  
    <IonRow>
      <IonCol>
        <div className='v-job-detail__section-content'>
          
        <ul>
            {bodyRowsHiringManagerTeam.map((row) => {
              return <li className="u-value-flow">{renderCardViewDetails(row)}</li>
            })}
          </ul>
          
        </div>

      </IonCol>
    </IonRow></>}    

  </IonGrid>
}

const JobPersonsApplicants: React.FC<JobPersonsProps> = ( {...props} ) => {
  
  const job = props.job;
  
  {/* Bewerber */}
  return <>
    <IonRow>
      <IonCol><h2 className="v-job-detail__section-headline v-job-detail__section-headline--sub mb-0">Bewerber:</h2></IonCol>
    </IonRow> 
    <IonRow>
      <IonCol>
        <div className='v-job-detail__section-content'>
        </div>
      </IonCol>
    </IonRow>
  </>
}

interface JobDescriptionProps {
  job: TJobDetail
};
const JobDescription:  React.FC<JobDescriptionProps> = ( {...props} ) => {
  const job = props.job;

  return <IonGrid className='v-job-detail__section'>
    <IonRow>
      <IonCol><h3 className="v-job-detail__section-headline">Beschreibung</h3></IonCol>
    </IonRow>
    <IonRow>
      <IonCol>        
        {/* <div className='v-job-detail__section-content' dangerouslySetInnerHTML={{__html: job?.description}}></div> */}
      </IonCol>
    </IonRow>
  </IonGrid>;
}

interface JobTasksProps {
  job: TJobDetail
};
const JobTasks:  React.FC<JobTasksProps> = ( {...props} ) => {
  const job = props.job;

  const [ presentToast ] = useIonToast();  

  const copyToClipboard = useCallback((event: any) => {
    // let canCopy = false;
    
    // if( typeof navigator.clipboard !== 'undefined' ) {
    //   canCopy = true;
    //   navigator.clipboard?.writeText(job?.tasks);
    // }

    // presentToast({
    //   message: canCopy ? 'Text in die Zwischenablage kopiert' : 'Text konnte nicht kopiert werden.',
    //   duration: TOAST_DURATION_DEFAULT,
    //   position: 'top',
    //   color: canCopy ? 'success' : 'danger'
    // });    
  }, []);

  return <IonGrid className='v-job-detail__section v-job-detail__section--tasks'>
    <IonRow>
      <IonCol style={{position: 'relative'}}>
        <h3 className="v-job-detail__section-headline">Ihre Aufgaben</h3>
        <IonButton className='v-job-detail__section-btn--copy' fill='clear' onClick={copyToClipboard}>
          <IonIcon slot='start' src={copyOutline}></IonIcon>
          <IonLabel>Kopieren</IonLabel>
        </IonButton>
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        {/* <div className='v-job-detail__section-content' dangerouslySetInnerHTML={{__html: job?.tasks}}></div> */}
      </IonCol>
    </IonRow>
  </IonGrid>;
}

interface JobApplicantProfileProps {
  job: TJobDetail
};
const JobApplicantProfile:  React.FC<JobApplicantProfileProps> = ( {...props} ) => {
  const job = props.job;
  return <IonGrid className='v-job-detail__section'>
    <IonRow>
      <IonCol><h3 className="v-job-detail__section-headline">Ihre Profil</h3></IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        {/* <div className='v-job-detail__section-content' dangerouslySetInnerHTML={{__html: job?.profile}}></div> */}
      </IonCol>
    </IonRow>
  </IonGrid>;
}

interface JobBenefitsProps {
  job: TJobDetail
};
const JobBenefits:  React.FC<JobBenefitsProps> = ( {...props} ) => {
  const job = props.job;
  return <IonGrid className='v-job-detail__section'>
    <IonRow>
      <IonCol><h3 className="v-job-detail__section-headline">Das bieten wir Ihnen</h3></IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        {/* <div className='v-job-detail__section-content' dangerouslySetInnerHTML={{__html: job?.benefits}}></div> */}
      </IonCol>
    </IonRow>
  </IonGrid>;
}

interface JobDetailProps extends RouteComponentProps<{
  id: string;
}> {}

const JobDetail: React.FC<JobDetailProps> = ({ match }) => {

  const jobId = match.params.id;

  const { 
    isLoading, 
    isError, 
    isFetching, 
    isSuccess, 
    error, 
    data 
  } = useJobDetailQuery( jobId );

  const { t } = useTranslation();

  const hasExternalLink = useMemo(() => {
    return false;
  }, [data]);

  useEffect(() => {    
    if( APP_DEBUG ) console.log('%c JobDetail.tsx | MOUNTED', 'color: yellow');
    return () => {
      if( APP_DEBUG ) console.log('%c JobDetail.tsx | UNMOINT cleanup', 'color: red');
    }
  }, []);

  return (
    <IonPage className='v-job-detail u-page-above-menu'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={getPageUrl('job_applicants', {id: jobId})}></IonBackButton>
          </IonButtons>
          <IonTitle>
            {t('job.header.title')}
          </IonTitle>        
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
        <QueryStateIndicator isError={isError} isLoading={isLoading} error={error}></QueryStateIndicator>
        
        <div className='v-job-detail__inner'>
          {isSuccess && 
            <>
              <JobData job={data} />
              <JobPersons job={data} />
              <JobDescription job={data} />
              <JobTasks job={data} />
              <JobApplicantProfile job={data} />
              <JobBenefits job={data} />
            </>
          }
        </div>
      </IonContent>  

      {hasExternalLink && <IonFooter className="v-job-detail__footer" style={{'--background': 'white'}}>
        <IonGrid>
          <IonRow>
            <IonCol size='12'><IonButton expand='block' target='_blank' href="NOT_IMPLEMENTED">In dm-jobs öffnen</IonButton></IonCol>
            <IonCol size='12' className='v-job-detail__footer-info ion-text-center'>
              <small>Hinweis: Es kann Umstände geben, in denen die Verfügbarkeit auf dm-jobs nicht gegeben ist.</small>
            </IonCol>
          </IonRow>
        </IonGrid>        
      </IonFooter>}
      
    </IonPage>
  );
};

export default JobDetail;
