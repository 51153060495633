import { IonItem, IonLabel } from "@ionic/react";
import { TListJobsItem, TListJobsItemComponent } from "../../../types";
import { LAYOUT_CARD_VIEWS_POSITION_ID_BODY, LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER, LAYOUT_CARD_VIEWS_POSITION_ID_HEADER } from "../../../utils/const";
import InlineSeperator from "../../inline/InlineSeperator";
import JobCardMetric from "../../JobStatusMetric";
import { getDateReadable } from "../../../utils/util";
import { getPageUrl } from "../../../utils/urls";
import { useMemo } from "react";
import React from "react";
import { getCardViewPart } from "../../cardviews/CardViews";
import CardViewsItemDetail from "../../cardviews/CardViewsItemDetail";
import CardViewsItem from "../../cardviews/CardViewsItem";
import CardViews from "../../cardviews/CardViews";

export const ListJobsItemList: React.FC<TListJobsItem & TListJobsItemComponent> = ({ ...props }) => {
  const job = props.job;
  
  const cardViews = job.jobReqInfo.cardViews;
  const headerRows = useMemo(() => getCardViewPart('header', cardViews), [ cardViews ]);
  const bodyRows = useMemo(() => getCardViewPart('body', cardViews), [ cardViews ]);
  const footerRows = useMemo(() => getCardViewPart('footer', cardViews), [ cardViews ]);

  return <IonItem className={`c-job-item ${props.className ?? ''}`} routerLink={getPageUrl('job_applicants', { id: job.jobReqId })}>
    <div className="c-job-item__inner">
      
      {headerRows.length > 0 && <div className="c-job-item__header">
        <IonLabel className="c-job-item__header-label ion-text-wrap u-value-flow" color='primary'>
          <strong><CardViews cardViews={headerRows} /></strong>
        </IonLabel>
      </div>}
      
      {bodyRows.length > 0 && <CardViews className="c-job-item__body" cardViews={bodyRows} render={(cardView) => {
        let outputCount = 0;

        return <IonLabel key={`c-job-item__body-label`} color='medium' className="c-job-item__body-label ion-text-wrap u-value-flow ion-padding-vertical:2">
              
          {cardView.cardViewDetails.map(( bodyDetail: any, bodyDetailIndex: number ) => {         
            
            if( bodyDetail.displayType !== 'reference_only' ){
              outputCount++;
            }

            if( bodyDetail.labelName === 'recruiterLastName' ) {  
              return <CardViewsItemDetail key={`c-job-item__body-label-inner--${bodyDetail.labelName}`} cardViewItemDetail={bodyDetail} />
            }

            return <React.Fragment key={`c-job-item__body-label-inner--${bodyDetail.labelName}`}>
              {outputCount > 1 ? <InlineSeperator /> : null}
              <CardViewsItemDetail cardViewItemDetail={bodyDetail} />
            </React.Fragment>              

          })}
          
        </IonLabel>
      }}/>}

      {footerRows.length > 0 && <div className="c-job-item__footer-row">
        <CardViews cardViews={footerRows} render={(cardView) => {
          return <IonLabel color='medium' className="c-job-item__footer-row-label ion-text-wrap u-value-flow">
            <CardViewsItem cardViewItem={cardView} classNameItemDetail="c-job-item__footer-row-label-inner" />
          </IonLabel> 
        }} />
      </div>}       

      <div className="c-job-item__footer">
        <JobCardMetric job={job} />
      </div>
    </div>
  </IonItem>;
};