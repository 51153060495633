import { useId } from "react";
import { LAYOUT_APPLICANT_LIST, LAYOUT_LIST_STYLE_ID_CARD } from "../../../utils/const";
import { IonCol, IonItemDivider, IonItemGroup, IonRow, IonText } from "@ionic/react";
import { ListApplicantsItem } from "./ListApplicantsItem";
import { TListApplicantsBase, TListApplicantsGroup } from "../../../types";

export const ListApplicantsGroup: React.FC<TListApplicantsBase & TListApplicantsGroup> = ({ ...props }) => {

  const uniqueId      = useId();
  const groupId       = props.groupId ?? uniqueId;
  const listType      = props.type ?? LAYOUT_APPLICANT_LIST;
  const isSlide       = props.slide !== false ? true : false;

  const applicants    = props.applicants;

  /*
   * CARD LAYOUT
   */
  if( listType === LAYOUT_LIST_STYLE_ID_CARD ) {
    
    if( typeof props.groupHeader !== 'function' ) {
      return applicants.map((applicant: any, applicantIndex: number) => {
        return (
          <IonCol key={`c-applicants-list__item-item--job-${groupId}-${applicant.applicationId}`} size='12' sizeMd="6" className="c-applicants-list__item-body">
            <ListApplicantsItem 
              type={listType} 
              slide={isSlide} 
              key={`c-applicants-list-item--${applicant.applicationId}`} 
              applicant={applicant} 
            />
          </IonCol>
        );
      })      
    }

    return <IonCol className='c-applicants-list__group ion-no-padding' size="12">
      <IonRow>
        <IonCol key={`c-applicants-list__item-header--${groupId}`} size="12" sizeMd="12" className="c-applicants-list__group-header">
          {/*
            * Maybe Add Link to JobPage here=
            * <IonRouterLink routerLink={getPageUrl('job', {'id': job.id})}>
            * </IonRouterLink>
            */}                    
          <IonText>
            {props.groupHeader()}
          </IonText>
        </IonCol>
        {applicants.map((applicant: any, applicantIndex: number) => {
          return (
            <IonCol key={`c-applicants-list__item-item--job-${groupId}-${applicant.applicationId}`} size='12' sizeMd="6" className="c-applicants-list__item-body">
              <ListApplicantsItem 
                type={listType} 
                slide={isSlide} 
                key={`c-applicants-list-item--${applicant.applicationId}`} 
                applicant={applicant} 
              />
            </IonCol>
          );
        })}     
      </IonRow>   
    </IonCol> 

  }

  /*
   * LIST LAYOUT
   */  
  
  if( typeof props.groupHeader !== 'function' ) {
    return applicants.map((applicant: any) => {
      return <ListApplicantsItem type={listType} slide={isSlide} key={`c-applicants-list-item--${applicant.applicationId}`} applicant={applicant} />
    });
  }

  return <IonItemGroup className="c-applicants-list__group">
    <IonItemDivider className="c-applicants-list__group-header" color='light' sticky>
      <strong className="c-applicants-list__group-header-inner">
        {props.groupHeader()}
      </strong>
    </IonItemDivider>
    {applicants.map((applicant: any) => {
      return <ListApplicantsItem type={listType} slide={isSlide} key={`c-applicants-list-item--${applicant.applicationId}`} applicant={applicant} />
    })}
  </IonItemGroup>
};
