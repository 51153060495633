import React from 'react';
import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';

import packageJson from '../../package.json';
import { useTranslation } from 'react-i18next';
import { _objectMap, objectForEach, objectMap } from '../utils/util';

const DONT_SHOW_PACKAGES_THAT_START_WITH = [
  '@capacitor',
  '@types',
  '@testing',
];

/*
 * License Page
 */
interface LicenseProps {}

const License: React.FC<LicenseProps> = () => {

  const { t } = useTranslation();

  // useEffect(() => {    
  //   console.log('%c License.tsx | MOUNTED', 'color: yellow');
  //   return () => {
  //     console.log('%c License.tsx | UNMOINT cleanup', 'color: red');
  //   }
  // }, []);

  return (
    <>
    <IonPage className='v-license v-page v-page--license u-page-above-menu'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref='/'></IonBackButton>
          </IonButtons>
          <IonTitle>
            { t('pages.license.title') }
          </IonTitle>        
        </IonToolbar>
      </IonHeader>      
      <IonContent fullscreen className='ion-padding'>          
        <div className="v-page__inner">
          <h1>License Agreements</h1>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum enim a corporis aperiam amet tempora accusantium corrupti ipsa itaque esse! Corporis magni dolore laborum error ratione quia saepe repellat quam?</p>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum enim a corporis aperiam amet tempora accusantium corrupti ipsa itaque esse! Corporis magni dolore laborum error ratione quia saepe repellat quam?</p>
          <h3>NPM Packages</h3>
          
          {/*
            * Output package.json dependencies
            */}
          <IonGrid style={{'--ion-grid-padding': 0, 'marginInline': 'calc(var(--ion-grid-column-padding, 5px) * -1)'}}>
            <IonRow>
              <IonCol size='9'><strong>Package</strong></IonCol>
              <IonCol size='3' className='ion-text-right'><strong>Version</strong></IonCol>
            </IonRow>

            {packageJson?.dependencies && _objectMap(packageJson.dependencies, (value, key, index) => {
              if(
                DONT_SHOW_PACKAGES_THAT_START_WITH
                  .find(( _pack ) => { return key.startsWith(_pack) })
              ) return;
              
              return <IonRow key={key}>
                <IonCol size='10'>{key}</IonCol>
                <IonCol size='2' className='ion-text-right'>{value}</IonCol>
              </IonRow>
            })}

          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
    </>
  );
};

export default License;
