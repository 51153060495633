import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonHeader, IonSpinner } from '@ionic/react';
import './QueryStateIndicator.css';

interface QueryStateIndicatorProps {
	isLoading: boolean,
  isError: boolean,
  error?: any, 
  colorSpinner?: string
}

const QueryStateIndicator: React.FC<QueryStateIndicatorProps> = ({ ...props }: any) => {
  const isLoading = props.isLoading;
  const isError = props.isError;
  const error = props.error ?? 'Leider ist ein Fehler aufgetreten.';
  const colorSpinner = props.colorSpinner;

  if( isLoading ) {
    return (
      <IonCol size='12' style={{display: 'flex'}} className='ion-justify-content-center ion-align-items-center'>
        <br /><br /><br /><br /><br /><br />
        <IonSpinner color={colorSpinner ?? 'primary'}></IonSpinner>
      </IonCol>
    );
  }
  
  if( isError ) {
    return (
      <IonCol style={{display: 'flex'}} className='ion-justify-content-center ion-align-items-center'>
      <IonCol size='12' sizeMd='6'>
        <br /><br />
        <IonCard color='danger'>
          <IonCardHeader>
            <IonCardTitle>Fehler</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Leider ist ein Fehler aufgetreten.</p>
          </IonCardContent>
        </IonCard>
      </IonCol>
      </IonCol>
    );    
  }

  return;
};

export default QueryStateIndicator;