import { useCallback, useMemo } from "react";
import useApplicantActionSheet from "../../../hooks/useApplicantActionSheet";
import { LAYOUT_APPLICANT_LIST, LAYOUT_LIST_ITEM_SLIDE_BTNS_END, LAYOUT_LIST_ITEM_SLIDE_BTNS_START, LAYOUT_LIST_STYLE_ID_CARD } from "../../../utils/const";
import { ListApplicantsItemCard } from "./ListApplicantsItemCard";
import { ListApplicantsItemList } from "./ListApplicantsItemList";
import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding } from "@ionic/react";
import { getIoniconByString } from "../../../utils/helper";
import { TListApplicantsBase, TListApplicantsItem } from "../../../types";

export const ListApplicantsItem: React.FC<TListApplicantsBase & TListApplicantsItem> = ( { ...props } ) => {
  
  const applicant             = props.applicant;
  const listType              = props.type ?? LAYOUT_APPLICANT_LIST;
  const hasIndicator          = props.indicator;

  const applicantActionSheet  = useApplicantActionSheet();
  
  // are there any states in sliding options?!
  //
  const showSlidingOptions = useMemo(() => {
    return applicantActionSheet.data?.length && props.slide;
  }, [applicantActionSheet.data, props.slide]);

  // which states are shown in the slides?
  //
  const statesShown = useMemo(() => {
    if( !showSlidingOptions ) return;

    const _statesPos: any = {};
    _statesPos.start = [];
    _statesPos.end = [];
    
    applicantActionSheet.data?.forEach(( group: any, groupIndex: number ) => {
      const _group = group.group;
      if( groupIndex < LAYOUT_LIST_ITEM_SLIDE_BTNS_START ) {
        _statesPos.start.push(_group);
      }
      if( groupIndex >= LAYOUT_LIST_ITEM_SLIDE_BTNS_START && groupIndex < ( LAYOUT_LIST_ITEM_SLIDE_BTNS_START + LAYOUT_LIST_ITEM_SLIDE_BTNS_END ) ) {
        _statesPos.end.push(_group);
      }
    });

    _statesPos._endReversed = _statesPos.end.reverse();
    return _statesPos;

  }, [showSlidingOptions, applicantActionSheet.data]);

  // Show Action Sheet
  //
  const doStateAction = useCallback(( statusGroupId: any ) => {
    if( statusGroupId === -1 ) {
      return;
    }
    applicantActionSheet.present( applicant.applicationId, statusGroupId, applicant?.jobReqId );
  }, [ applicant.applicationId, applicantActionSheet.data ]);

  // if list not has slide
  //
  if( !props.slide ) {
    if( listType === LAYOUT_LIST_STYLE_ID_CARD ) {
      return <ListApplicantsItemCard indicator={props.indicator} applicant={applicant} />
    }
    return <ListApplicantsItemList indicator={props.indicator} applicant={applicant} />
  }

  return <IonItemSliding className="c-applicants-list__item-slide">

    {showSlidingOptions && <IonItemOptions className="c-applicants-list__item-actions c-applicants-list__item-actions--start" side="start" onIonSwipe={statesShown?.start?.length > 0 ? () => { doStateAction(statesShown.start[0].id) } : undefined}>
      {statesShown?.start?.map((stateGroup: any, index: number) => {        
        return <IonItemOption expandable={index === 0} key={`c-applicants-list__item-actions-option--start-${stateGroup.id}`} color={stateGroup.color ?? 'primary'} onClick={() => doStateAction(stateGroup.id)}>
          <IonIcon slot="top" icon={getIoniconByString(stateGroup.icon)}></IonIcon>
          {stateGroup.name}
        </IonItemOption>
      })}   
    </IonItemOptions>}
    
    {(() => {
      /*
       * If Type is Card
       */
      if( listType === LAYOUT_LIST_STYLE_ID_CARD ) {
        if( showSlidingOptions ) {     
          return <IonItem className={`c-applicant-card__slide-inner c-applicants-list__item-slide-inner`}>
            <ListApplicantsItemCard indicator={hasIndicator} applicant={applicant} />
          </IonItem>
        }
        return <ListApplicantsItemCard indicator={hasIndicator} applicant={applicant} />
      }

      return <ListApplicantsItemList indicator={hasIndicator} applicant={applicant} />
    })()}

    {showSlidingOptions && <IonItemOptions side="end" className="c-applicants-list__item-actions c-applicants-list__item-actions--end" onIonSwipe={statesShown?.end?.length > 0 ? () => { doStateAction(statesShown.end[statesShown?.end?.length - 1].id) } : undefined}>
      {statesShown?.end?.length > 0 && statesShown?._endReversed?.map((stateGroup: any, index: number) => {
        return <IonItemOption expandable={statesShown?._endReversed?.length - 1 === index} key={`c-applicants-list__item-actions-option--end-${stateGroup.id}`} color={stateGroup.color ?? 'primary'} onClick={() => doStateAction(stateGroup.id)}>
          <IonIcon slot="top" icon={getIoniconByString(stateGroup.icon)}></IonIcon>
          {stateGroup.name}
        </IonItemOption>
      })} 
    </IonItemOptions>}

  </IonItemSliding>
};