import { useTranslation } from "react-i18next";

export const sleep = (n: number) => new Promise(r => setTimeout(r, n));

export const msToTime = (d: number) => {
  var seconds = Math.floor((d / 1000) % 60),
    minutes = Math.floor((d / (1000 * 60)) % 60);

  return minutes + ":" + (seconds < 10 ? `0${seconds}` : seconds);
}

export function getValueWithCurrency( value: number, currency: string = 'EUR', lang: string = 'de-DE' ){
  const _value = new Intl.NumberFormat(lang, { style: 'currency', currency }).format( value );
  return _value;
}

export const ValueWithCurrency: React.FC<{
  value: number, 
  currency?: string
}> = ( {value, currency } ) => {
  const { t } = useTranslation();
  return getValueWithCurrency(value, currency);
};

export function getTimeSince(
  input: string | Date | number,
  lang?: string
) {
  const date = (input instanceof Date) ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat(lang);
  const ranges = [
    ['years', 3600 * 24 * 365],
    ['months', 3600 * 24 * 30],
    ['weeks', 3600 * 24 * 7],
    ['days', 3600 * 24],
    ['hours', 3600],
    ['minutes', 60],
    ['seconds', 1],
  ] as const;

  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
 
  for (const [rangeType, rangeVal] of ranges) {
    if (rangeVal < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / rangeVal;
      return formatter.format(Math.round(delta), rangeType);
    }
  }
}

export const TimeSince: React.FC<{date: string | Date | number}> = ( {date} ) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return getTimeSince(date);
};

export function getDateReadable(_date: Date|string, inclTime: boolean|'only' = true, pretty: boolean = false){
  const date: Date = (_date instanceof Date) ? _date : new Date(_date);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("de-DE", options).format(date);
}

export const DateReadable: React.FC<{date: Date|string, inclTime?: boolean|'only', pretty?: boolean}> = ( {date, inclTime, pretty} ) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return getDateReadable(date, inclTime, pretty);
};

export function convertDateToTimezone(date: Date|string, tzString: string = 'Europe/Berlin', returnString: boolean = true) {
  const convertedDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));  

  if( returnString ) {
    let _dateString: string = '';
    _dateString += convertedDate.getFullYear();
    _dateString += '-'; // seperator
    _dateString += convertedDate.getMonth() < 10 ? '0' + (convertedDate.getMonth() + 1) : convertedDate.getMonth() + 1;
    _dateString += '-'; // seperator
    _dateString += convertedDate.getDate() < 10 ? '0' + convertedDate.getDate() : convertedDate.getDate();
    _dateString += 'T'; // seperator
    _dateString += convertedDate.getHours() >= 10 ? convertedDate.getHours() : '0' + convertedDate.getHours();
    _dateString += ':'; // seperator
    _dateString += convertedDate.getMinutes() >= 10 ? convertedDate.getMinutes() : '0' + convertedDate.getMinutes();    
    _dateString += ':'; // seperator
    _dateString += convertedDate.getSeconds() >= 10 ? convertedDate.getSeconds() : '0' + convertedDate.getSeconds();        
    return _dateString;
  }

  return convertedDate;
}

export function stringToHash(str: string, seed: number = 0){
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;

  for(let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1  = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2  = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return (4294967296 * (2097151 & h2) + (h1 >>> 0)) + '';
};

export function sortArrayBy(array: any[], sortByField: string = 'sort', sortObjByField: string = 'sort'): any[] {
  const canSort = array.every((a: any) => a.hasOwnProperty(sortByField));

  if( !canSort ) return array;

  return array.sort((a: any, b: any) => {
    const _fieldA = Array.isArray(a[sortByField]) ? a[sortByField][0] : a[sortByField];
    const _fieldB = Array.isArray(b[sortByField]) ? b[sortByField][0] : b[sortByField];
    const fieldA = isObject(_fieldA) && _fieldA.hasOwnProperty(sortObjByField) ? _fieldA[sortObjByField] : _fieldA;
    const fieldB = isObject(_fieldB) && _fieldB.hasOwnProperty(sortObjByField) ? _fieldB[sortObjByField] : _fieldB;

    if ( fieldA < fieldB ) return -1;
    if ( fieldA > fieldB ) return 1;
    return 0;     
  });
}

export function isObject( obj: any ) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
}


export const objectMap = (obj: any, fn:(v: any, k: any, i: any) => void) => Object.fromEntries(
  Object.entries(obj).map(
    ([k, v], i) => [k, fn(v, k, i)]
  )
);

export const _objectMap = (obj: any, fn:(v: any, k: string, i: number) => any) => {
  return Object.entries(obj).map(( entry: [string, any], entryIndex: number ) => {
    return fn(entry[1], entry[0], entryIndex );
  });
};

export const objectSort = (obj: any, fn:(a: [string, unknown], b: [string, unknown]) => number) => Object.fromEntries(
  Object.entries(obj).sort(fn)
);

export function objectForEach( obj: {}, callback: ( objKey: string, objValue: any ) => void ) {
  for (const [key, value] of Object.entries( obj )) {
    if( !value ) return;
    callback( key, value );
  }  
}