import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

// import { ServerProvider } from './hooks/useServer'; // is for server action management
import { AppContextProvider } from "./data/AppContext"; // is for state handling
import AppTranslation from './components/AppTranslation';

// Create a client
const queryClient = new QueryClient()

const container: any = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <AppTranslation />
        <App />
      </AppContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
