import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useAppConfigQuery, useJobsByStatusQuery } from "../hooks/useApi";
import QueryStateIndicator from "../components/QueryStateIndicator";
import ListApplicants from "../components/list/ListApplicants";
import { TJobStatus, TLayoutListStyles, TStatus } from "../types";
import { LAYOUT_APPLICANT_LIST, LAYOUT_LIST_STYLE_ID_CARD, LAYOUT_LIST_STYLE_ID_LIST } from "../utils/const";
import { UseQueryResult } from "@tanstack/react-query";

interface JobsByStatusWithApplicantsProps {
  status: TJobStatus
  type?: TLayoutListStyles
}

export const JobsByStatusWithApplicants: React.FC<JobsByStatusWithApplicantsProps> = ( {...props} ) => {

  const appConfig = useAppConfigQuery('layout.list_type_applicants');
  
  const status = props.status; 
  const statusId = status?.id;
  
  const { 
    isLoading: jobsApplicantsIsLoading, 
    isError: jobsApplicantsIsError, 
    isSuccess: jobsApplicantsIsSuccess, 
    data: jobsApplicantsData 
  } = useJobsByStatusQuery( statusId );

  if( !statusId ) return;

  const listType = props.type ?? (appConfig.isSuccess ? appConfig.data as TLayoutListStyles : LAYOUT_APPLICANT_LIST);

  return (
    <div className={`c-jobs-applicants-by-status c-jobs-applicants-by-status--${listType}`} id={`c-jobs-applicants-by-status--id:${statusId}`}>
      {listType === LAYOUT_LIST_STYLE_ID_CARD && <IonGrid>
        <IonRow>            
          <QueryStateIndicator colorSpinner='light' isLoading={jobsApplicantsIsLoading} isError={jobsApplicantsIsError}/>

          { jobsApplicantsIsSuccess &&
            <IonCol className='ion-no-padding' size='12'>
              <ListApplicants type={listType} data={jobsApplicantsData} groupedBy='job'/>
            </IonCol>
          }

        </IonRow>
      </IonGrid>}
      
      {listType === LAYOUT_LIST_STYLE_ID_LIST && <>
        <QueryStateIndicator isLoading={jobsApplicantsIsLoading} isError={jobsApplicantsIsError}/>
        { jobsApplicantsIsSuccess && <ListApplicants type={listType} data={jobsApplicantsData} groupedBy='job' /> }
      </>}
    </div>
  )
};

const JobsByStatusWithApplicantsPage: React.FC<JobsByStatusWithApplicantsProps> = ( {...props} ) => {

  const appConfig = useAppConfigQuery('layout.list_type_applicants');
  
  const status = props.status;
  const statusId = status.id;

  const listType = props.type ?? (appConfig.isSuccess ? appConfig.data as TLayoutListStyles : LAYOUT_APPLICANT_LIST);
  const listTypeStyles = listType === LAYOUT_LIST_STYLE_ID_CARD ? { '--background': 'transparent' } : {};

  return (
    <IonPage className={`v-jobs-applicants-by-status v-jobs-applicants-by-status--${listType} v-jobs-applicants-by-status--id:${statusId}`}>
      <IonContent fullscreen style={listTypeStyles} className='has-border-radius'>
        
        <JobsByStatusWithApplicants status={status} type={listType} />

      </IonContent>  
    </IonPage>    
  )
};

export default JobsByStatusWithApplicantsPage;