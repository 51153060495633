import React, { useCallback, useMemo, useRef }  from 'react';
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonSkeletonText, IonBadge } from '@ionic/react';
import { Route, Redirect } from 'react-router';

import { getPageUrl } from '../utils/urls';

import './Overview.css';

// Pages
import Jobs from './Jobs';

// Context
import { useStatusQuery, useConfigQuery, useNavStatusListQuery, useJobListQuery, useNavStatusTotalsQuery } from '../hooks/useApi';
import QueryStateIndicator from '../components/QueryStateIndicator';
import JobsByStatusWithApplicantsPage from './JobsByStatusWithApplicants';
import useAppTracking from '../hooks/useAppTracking';
import JobsByStatus from './JobsByStatus';
import { TJobStatus, isTJobStatus } from '../types';
import { LAYOUT_STATUS_DISPLAY_TYPE_ID_JOBS } from '../utils/const';

interface OverviewProps { }

const Overview: React.FC<OverviewProps> = () => {

  // Tracking
  //  
  const tracking    = useAppTracking();

  const navStatusListQuery    = useNavStatusListQuery('overview');  
  const navStatusTotalsQuery  = useNavStatusTotalsQuery('overview');

  const hasStatusListItems = useMemo(() => {
    return (
      navStatusListQuery?.isSuccess && 
      navStatusListQuery?.data && 
      Array.isArray(navStatusListQuery?.data) && 
      navStatusListQuery?.data.length > 0
    );
  }, [navStatusListQuery]);

  const navStatusListItems = useMemo(() => {
    return navStatusListQuery?.data;
  }, [navStatusListQuery?.data]);

  const routerOutletRef = useRef<any | null>(null);

  return (
    <>
    <QueryStateIndicator colorSpinner='light' isLoading={navStatusListQuery.isLoading} isError={navStatusListQuery.isError} />

    {navStatusListQuery?.isSuccess && hasStatusListItems && <IonTabs className='c-tabs c-tabs--overview'>
      <IonRouterOutlet ref={routerOutletRef} className="v-overview" id="v-overview">
        
        {/**
         * First in Array is Default Page
         */}

        <Redirect exact path={getPageUrl('overview')} to={`${getPageUrl('overview')}/${navStatusListItems![0].displayname}`} />

        {navStatusListQuery.data?.map(( status ) => {

          if( !isTJobStatus(status) ) return;

          // Jobs component
          //
          if( status.displaytype === LAYOUT_STATUS_DISPLAY_TYPE_ID_JOBS ) {
            return <Route key={status.id} path={`${getPageUrl('overview')}/${status.displayname}`} render={() => {
              return <JobsByStatus status={status} />
            }} />
          }

          // Jobs with Applicants by Status
          //
          return <Route key={status.id} path={`${getPageUrl('overview')}/${status.displayname}`} render={() => {
            return <JobsByStatusWithApplicantsPage status={status} />
          }} />
          
        })}

      </IonRouterOutlet>
      <IonTabBar slot="top" className='c-tabs__bar' style={{'--background': 'transparent', '--color': 'white', '--color-selected': 'white'}}>
        
        {navStatusListQuery?.isSuccess && hasStatusListItems && navStatusListItems?.map(( status ) => {
          
          if( !isTJobStatus(status) ) return;

          const statusTotalLoaded = navStatusTotalsQuery.isSuccess && !navStatusTotalsQuery.isLoading;
          const statusTotal = statusTotalLoaded && 
            navStatusTotalsQuery.data &&
            Array.isArray(navStatusTotalsQuery.data) &&
            navStatusTotalsQuery.data.length > 0 && 
            navStatusTotalsQuery.data.find(_total => _total.displayStatusId === status.id);

          return <IonTabButton key={status.id} tab={status.displaytext} href={`${getPageUrl('overview')}/${status.displayname}`}>
            <IonLabel className='ion-text-nowrap' data-inner-text={status.displaytext}>
              <span>{status.displaytext}</span>
              {!statusTotalLoaded && 
                <IonSkeletonText style={{display: 'inline-block', height: '1.1em', width:'1.75ch', marginLeft: '0.5em', marginBottom: '-0.3em'}}/>}
              {statusTotalLoaded && statusTotal && <IonBadge color='light' style={{marginLeft: '0.5em', marginBottom: '-0.3em', fontSize: '0.8em', '--padding-bottom': '3px'}}>{statusTotal.amount}</IonBadge>}              
            </IonLabel>
          </IonTabButton>
          
        })}

      </IonTabBar>
      
    </IonTabs>}
    </>
  );
};

export default Overview;