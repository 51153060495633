import { useTranslation } from 'react-i18next';

const BadgeInternal: React.FC = () => {

  const { t, i18n } = useTranslation();

  return (
    <span className="c-inline-badge">{t('internal')}</span> 
  );
};

export default BadgeInternal;