import { useTranslation } from 'react-i18next';

const BadgeInternal: React.FC = () => {

  const { t, i18n } = useTranslation();

  return (
    <span className="c-inline-badge">SB-Status</span> 
  );
};

export default BadgeInternal;