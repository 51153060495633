import React, { ReactNode, useId } from 'react';
import { TCardViewItem, TCardViewItemDetail, TCardViewItemDetailDisplayType, TCardViews } from '../../types';
import CardViewsItem from './CardViewsItem';
import { LAYOUT_CARD_VIEWS_POSITION_ID_HEADER, LAYOUT_CARD_VIEWS_POSITION_ID_BODY, LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER } from '../../utils/const';

export function getCardViewPart(part: 'header' | 'body' | 'footer', cardViews: TCardViews) {

  const cardViewPart = cardViews?.filter((view) => {
    if (part === 'header') {
      return view.position === LAYOUT_CARD_VIEWS_POSITION_ID_HEADER;
    }
    if (part === 'body') {
      return view.position === LAYOUT_CARD_VIEWS_POSITION_ID_BODY;
    }
    if (part === 'footer') {
      return view.position === LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER;
    }
  });

  return cardViewPart;
}
const _fieldDetails = 'cardViewDetails';

export function getCardViewItemWith(
  labelName: string,
  cardViews: TCardViews,
  displayType?: TCardViewItemDetailDisplayType
) {
  const _isViews = cardViews.length > 0 && cardViews[0]?.cardViewDetails?.length > 0;
  const _isItems = cardViews.length > 0 && cardViews;

  const _findDetail = (detail: any) => {
    if (_isViews) {
      if (displayType) {
        return detail.labelName === labelName && detail.displayType === displayType;
      }
      return detail.labelName === labelName;
    }
    return;
  };

  return cardViews?.find((card) => {
    if (card.hasOwnProperty(_fieldDetails)) {
      return card[_fieldDetails].find(_findDetail);
    }
    return false;
  });
}

export function getCardViewDetail(
  labelName: string,
  cardViews: TCardViews,
  displayType?: TCardViewItemDetailDisplayType
) {
  const _findDetail = (detail: TCardViewItemDetail) => {
    if (displayType) {
      return detail.labelName === labelName && detail.displayType === displayType;
    }
    return detail.labelName === labelName;
  };

  const rowHit = getCardViewItemWith(labelName, cardViews, displayType);

  if (rowHit?.hasOwnProperty(_fieldDetails)) {
    return rowHit[_fieldDetails].find(_findDetail);
  }

  return;
}

export function getCardViewDetailValue(
  labelName: string,
  cardViewItemDetails: TCardViewItemDetail[]
) {
  return cardViewItemDetails.find((c) => c.labelName === labelName)?.value;
}

interface CardViewsProps {
  cardViews: TCardViews,
  render?: ( cardView: TCardViewItem ) => ReactNode,
  part?: 'header' | 'body' | 'footer'
  excludeDetails?: string[],
  // excludeDetails?: {
  //   displayType?: string
  //   labelName?: string
  // }[],
  className?: string,
  classNameItem?: string,
  classNameItemDetail?: string,
}

const CardViews: React.FC<CardViewsProps> = ( {...props} ) => {
  const cardViews = props.cardViews;
  const part = props.part;
  const id = useId();

  const _renderView = ( cardView: TCardViewItem, index: number ) => {
    
    if( props.excludeDetails && props.excludeDetails.length > 0 ) {
      if(cardView.cardViewDetails.find(( cardViewDetail ) => {
        return props.excludeDetails?.includes(cardViewDetail.labelName)        
      })) return null;
    }

    if( typeof props.render !== 'undefined' ) {
      return props.render(cardView);
    }

    if( props.className ) {
      return <div key={`c-card-view--${id}-${index}`} className={props.className}>
        <CardViewsItem cardViewItem={cardView} className={props.classNameItem} classNameItemDetail={props.classNameItemDetail}/>
      </div>
    }

    return <CardViewsItem key={`c-card-view--${id}-${index}`} cardViewItem={cardView} className={props.classNameItem} classNameItemDetail={props.classNameItemDetail} />
  };

  if( part === 'header' ) {
    const headerRows  = getCardViewPart('header', cardViews); 
    return headerRows.map(_renderView);
  }

  if( part === 'body' ) {
    const bodyRows    = getCardViewPart('body', cardViews); 
    return bodyRows.map(_renderView);
  }
  
  if( part === 'footer' ) {
    const footerRows  = getCardViewPart('footer', cardViews);
    return footerRows.map(_renderView);
  }

  return cardViews.map(_renderView);
};
 
export default CardViews;

export function renderCardView(cardViews: TCardViews, part?: 'header' | 'body' | 'footer') {
  return <CardViews cardViews={cardViews} part={part} />;
}

