import { Storage, Drivers } from "@ionic/storage";
import { APP_COMPANY_ID } from "../utils/const";

const storage = new Storage({        
  name: `storage_${APP_COMPANY_ID}`,
  driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
});
storage.create();

// @deprecated
export const createStorage = async (name: string = `storage_${APP_COMPANY_ID}`) => {
  return;
}

export const clearStorage = async () => {
  return await storage?.clear();
}

export const setStorageData = async (key: string, val: any) => {
  return await storage?.set(key, val);
}

export const getStorageData = async (key: string) => {
  const value = await storage?.get(key);
  return value;
}

export const removeStorageData = async (key: string) => {
  await storage.remove(key); 
}

export const setStorageDataObject = async (key: any, id: any, val: any) => {
  const all = await storage.get(key);
  const objIndex = await all.findIndex((a: any) => parseInt(a.id) === parseInt(id));

  all[objIndex] = val;
  setStorageData(key, all);
}

export const getStorageDataObject = async (key: any, id: any) => {
  const all = await storage.get(key);
  const obj = await all.filter((a: any) => parseInt(a.id) === parseInt(id))[0];
  return obj;
}

export const removeStorageDataObject = async (key: any, id: any) => {
  const all = await storage.get(key);
  const objIndex = await all.findIndex((a: any) => parseInt(a.id) === parseInt(id));

  all.splice(objIndex, 1);
  setStorageData(key, all);
}