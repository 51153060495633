const InlineSeperator: React.FC<{
  className?: string
  style?: undefined | 'bullet' | 'slash',
  seperator?: string
}> = ({ className, style, seperator }) => {
  const _seperator = seperator ?? style === 'slash' ? '/' : '&bull;';
  return <span dangerouslySetInnerHTML={{
    __html: `&nbsp;${_seperator}&nbsp;`
  }} className={`c-inline-seperator ${className ?? ''}`}></span>
};

export default InlineSeperator;