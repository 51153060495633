import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TCardViewItemDetail } from '../../types';
import { LAYOUT_CARD_VIEWS_CSS_CLASS_ITEM_DETAIL } from '../../utils/const';
import { DateReadable, TimeSince, ValueWithCurrency, getDateReadable } from '../../utils/util';
import BadgeDisabled from '../inline/BadgeDisabled';
import BadgeInternal from '../inline/BadgeInternal';
import InlineStatus from '../inline/InlineStatus';

const baseCSSClass = LAYOUT_CARD_VIEWS_CSS_CLASS_ITEM_DETAIL;

function _isDate(_date: string){
  const _regExp  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
  return _regExp.test(_date);
}

interface CardViewsItemDetailProps {
  cardViewItemDetail: TCardViewItemDetail,
  render?: ( cardView: TCardViewItemDetail ) => ReactNode,
  className?: string
}

const CardViewsItemDetail: React.FC<CardViewsItemDetailProps> = ( {...props} ) => {
  
  const { t }        = useTranslation();

  // shortcuts
  const viewDetail = props.cardViewItemDetail;
  const className  = props.className;

  // Own Render Method?
  //
  if( typeof props.render !== 'undefined' ) {
    return props.render(viewDetail);
  }

  // Reference Only
  //
  if( viewDetail.displayType === 'reference_only') {
    return null;
  }

  // Frontend Component
  //
  if( viewDetail.displayType === 'frontend_component' ) {

    if( 
      viewDetail.labelName === 'applicationRating' ||
      viewDetail.labelName === 'applicationCommentsCount'
    ) {
      // never output. only get values directly in needed component
      // via getCardViewDetail( labelName: string, cards: any[] )
      return null;
    }      

    if( viewDetail.labelName === 'applicationIsExternal' ) {
      return viewDetail.value !== 'true' ? <BadgeInternal /> : null;
    }

    if( viewDetail.labelName === 'applicantHasDisability' ) {
      return viewDetail.value ? <BadgeDisabled /> : null;
    } 

    if( viewDetail.labelName === 'applicationStatusLastChanged' ) {
      return <span className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName}`}>
        <TimeSince date={viewDetail.value} />
      </span>
    }

    return <strong>{'Placeholder Component: ' + viewDetail.labelName}</strong>
  }

  // Plain Value
  //  
  if( viewDetail.displayType === 'show_value' ) {
    
    let viewDetailValue = viewDetail.value;

    if( viewDetail.labelName === 'applicationStatus' ) {
      return <InlineStatus status={viewDetail} className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName}`} />
    }

    if( viewDetail.labelName === 'applicantDesiredSalary' ) {
      return <ValueWithCurrency value={viewDetailValue} />
    }

    if( viewDetail.labelName === 'msOverdue' ) {
      return <span className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName}`}>
        <small>{'Overdue: '}
        <DateReadable date={viewDetailValue} /></small>
      </span>
    }

    if( _isDate(viewDetailValue) ) {
      viewDetailValue = getDateReadable(viewDetailValue, false);
    }

    return className ? <span className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName} ${className ?? ''}`} key={`${className}--${viewDetail.labelName}`}>
      {viewDetailValue}
    </span> : <span className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName}`}>
      {viewDetailValue}
    </span>;
  }

  // Localized Value
  //    
  if( viewDetail.displayType === 'show_localized_label' ) {
    let viewDetailValue = viewDetail.hasOwnProperty('value') ? viewDetail.value : viewDetail.labelName;
    
    if( _isDate(viewDetailValue) ) {
      return <span className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName}`}>
        <DateReadable date={viewDetailValue} />
      </span>
    }

    return <span className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName}`}>
      {viewDetailValue}
    </span>
  }

  return <div className={`${baseCSSClass} ${baseCSSClass}--type:${viewDetail.displayType} ${baseCSSClass}--name:${viewDetail.labelName}`}>
    <strong>{viewDetail.labelName}:</strong>
    {viewDetail.value}
    <small>{viewDetail.displayType}</small>
  </div>
};

export default CardViewsItemDetail;

export function renderCardViewDetailsValue(viewDetail: TCardViewItemDetail, className?: string) {
  return <CardViewsItemDetail cardViewItemDetail={viewDetail} className={className} />;
}

