import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonRouterLink, IonTextarea, IonTitle, IonToolbar, useIonActionSheet, useIonAlert, useIonModal, useIonToast } from "@ionic/react";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { TOAST_OPTIONS_DEFAULT } from "../utils/const";
import { useApplicantStatusMutation, useConfigQuery, useStatusQuery } from "./useApi";
import { objectMap } from "../utils/util";
import { closeOutline, flashOutline } from "ionicons/icons";
import InlineAlert from "../components/inline/InlineAlert";
import { useTranslation } from "react-i18next";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import URLS, { getPageUrl } from "../utils/urls";

/*
 * @TODO:
 *
 * --- BACKDROP BUG ----
 * Modal kann mit backdrop geschlossen werden
 * Ohne das eine Frage kommt, ob wirklich geschlossen werden soll
 * wenn in der textarea content ist.
 * 
 * wird prevented durch:
 * {
 *  ...
 *  backdropDismiss: false
 * }
 * in present function
 * 
 * aber dann kann einfach nur kein backdrop mehr geklickt werden.
 * wie event.detail.data befüllen vor backdrop?
 * --- --- --- --- ---
 * 
 */

const USE_HOTFIX_BACKDROP = true;

const ApplicantActionSheetModal = ({
  onDismiss,
  setStatusChangeText,
  selectedStatus,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  setStatusChangeText: ( statusText: string ) => void;
  selectedStatus: any;
}) => {

  // get all Status
  //
  const statusQuery = useStatusQuery('status');
  
  // Translation
  const { t } = useTranslation();

  // Modal Dom Refs
  // 
  const modalInner = useRef<HTMLIonModalElement>(null);
  const modalDOMRef = useRef<any>(null);
  const modalDOM = useMemo(() => {
    // wish there would be a better way to get 
    // the modal parent
    modalDOMRef.current = modalInner.current?.closest('ion-modal');
    return modalInner.current?.closest('ion-modal');
  }, [ modalInner.current ]);  
  const modalTextarea = useRef<HTMLIonTextareaElement>(null);  

  // Status Obj
  // from "selectedStatus" from actionSheet
  //
  const statusObj = useMemo(() => {
    if( !statusQuery.data || !selectedStatus ) return null;
    return statusQuery.data.find(( _stat: any ) => { return _stat.id == selectedStatus });
  }, [ statusQuery.data, selectedStatus ]);

  // Exit intention alert
  //
  const [ presentAlert ] = useIonAlert();

  // Comment vars
  //
  const [ commentData, setCommentData ] = useState('');
  const hasTextareaText = useMemo(() => {
    return !commentData || commentData === '' ? false : true;
  }, [ commentData ]);
  const updateCommentData = useCallback( (_commentData: string) => {
    setCommentData( _commentData.trim() );
    setStatusChangeText( _commentData.trim() );    
  }, []);

  // Present Alert
  // if Textare has data and user wants to exit
  //
  const dismissIntention = useCallback(( forceClose: boolean | undefined ) => {

    if( !forceClose && commentData !== '' ) {

      return new Promise<boolean>((resolve) => {
        presentAlert({
          header: t('components.applicant.notes.alert.headline'),
          message: t('components.applicant.notes.alert.message'),
          buttons: [
            {
              text: t('components.applicant.notes.alert.cancel'),
              role: 'cancel',
              handler: () => {
                resolve(false);
              }
            },
            {
              text: t('components.applicant.notes.alert.confirm'),
              role: 'confirm',
              handler: () => {
                resolve(true);
              }
            },        
          ],      
        });
      });

    }

    return new Promise<boolean>((resolve) => {
      resolve(true);
    }); 

  }, [ commentData ]);

  // Close Alert
  // 
  const closeAndDismiss = useCallback(async ( forceClose?: boolean ) => {
    // ## SUPER CHEAP WORKAROUND
    // Löst den BACKDROP BUG
    // aber irgendwie ist das nciht schön.
    if( USE_HOTFIX_BACKDROP ) {      
      if( !modalDOM ) {
        const _modalDOM = modalInner.current?.closest('ion-modal');
        _modalDOM?.dismiss(null, 'cancel');
      }
      return modalDOM?.dismiss(null, 'cancel');
    }

    const wantToDismiss = await dismissIntention( forceClose ?? false );
    if( wantToDismiss ) onDismiss(null, 'cancel');
  }, [ commentData, modalInner, modalDOM ]);

  // Save Data
  //
  const saveAndDismiss = useCallback(() => {
    let saveData = commentData;
    
    // ## SUPER CHEAP WORKAROUND
    // Löst den BACKDROP BUG
    // aber irgendwie ist das nciht schön.
    if( USE_HOTFIX_BACKDROP && commentData && commentData !== '' ) {
      return modalDOM?.dismiss(commentData, 'confirm');      
    }

    if( saveData && saveData !== '' ) {
      return onDismiss(saveData, 'confirm');
    }
 
    return onDismiss(null, 'cancel');

  }, [ commentData, modalDOM ]);

  useLayoutEffect(() => {
    
    // ## SUPER CHEAP WORKAROUND
    // Löst den BACKDROP BUG
    // aber irgendwie ist das nciht schön.
    // hier wird einfach canDismiss überschrieben
    // aufpassen!
    if( USE_HOTFIX_BACKDROP && modalDOM ) {
      modalDOM.canDismiss = (data?: any, role?: string | undefined) => {
        if( role !== 'confirm' ) return dismissIntention(false);
        return new Promise((resolve) => resolve(true));        
      };
    }

  }, [ modalDOM, commentData ]);

  return (
    <IonPage className="c-modal c-modal--applicant-action-sheet" ref={modalInner}>
      
      <IonHeader className="c-modal__header">
        <IonToolbar>
          <IonTitle className="c-modal__title">
            {t('components.actionSheet.modal.title')}
            {statusObj && <div className="c-modal__subtitle"><small>{statusObj.name}</small></div>}
          </IonTitle>  
          <IonButtons slot="end">
            <IonButton onClick={() => { closeAndDismiss() } }>
              <IonIcon src={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>          
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="c-modal__content">
        <div className="c-modal__content-inner">
          <InlineAlert color={"info"}>
            <p>
              <span dangerouslySetInnerHTML={{
                __html: t('components.actionSheet.modal.privacy.content') + ' '
              }}></span>
              <IonRouterLink routerLink={getPageUrl('privacy')} onClick={() => { closeAndDismiss() } }>
                {t('components.actionSheet.modal.privacy.more')}
              </IonRouterLink>          
            </p>          
          </InlineAlert>
          <div className="ion-padding-horizontal c-modal__content-grow">
            <IonTextarea 
              className="c-textarea" 
              ref={modalTextarea} 
              autoGrow={true}
              onIonInput={(ev: any) => {
                updateCommentData( ev.detail.value );
              }}
              placeholder={t('components.applicant.notes.modal.placeholder')}></IonTextarea>
          </div>
        </div>
      </IonContent>

      <IonFooter className="c-modal__footer ion-padding">
        <IonButton expand="block" disabled={!hasTextareaText} onClick={saveAndDismiss}>{t('components.applicant.notes.save')}</IonButton>
      </IonFooter> 

    </IonPage>
  );

};

const useApplicantActionSheet = () => {
  const configQuery               = useConfigQuery( 'status' );
  const statusQuery               = useStatusQuery();
  const applicantStatusMutation   = useApplicantStatusMutation();
  
  const [ presentActionSheet ]    = useIonActionSheet();
  const [ selectedStatus, setSelectedStatus ]       = useState(null);
  const [ statusChangeText, setStatusChangeText ]   = useState(null);

  const [ presentStatusCommentModal, dismissStatusCommentModal ] = useIonModal(
    ApplicantActionSheetModal, 
    {
      selectedStatus,
      onDismiss: (data: string, role: string) => {
        return dismissStatusCommentModal(data, role);
      },  
      setStatusChangeText
    }
  );
  
  const [ presentActionSheetToast ] = useIonToast();

  const { t } = useTranslation();  

  // From Config
  // --------
  // Hier gibt es noch einen Bug
  // wenn Config noch nicht geladen aber status, 
  // dann werden die action groups irgendwie nicht registriert
  // weiß nicht, warum und wo die referenz falsch ist
  // 
  const statusActionGroups = useMemo(() => {
    if( !configQuery.data ) return;
    if( !statusQuery.data ) return;

    const configStatusActionGroups = configQuery.data?.actionGroups;

    if( !configStatusActionGroups ) return;

    const statusInActionSheet = statusQuery.data?.filter(( status: any ) => { 
      
      if( Array.isArray(status.group) ) {
        const hasGroupActionSheet = status.group.find((g: any) => { 
          return configStatusActionGroups.find(( actionGroup: any ) => { 
            return actionGroup.id === g.id 
          }) ? true : false;
        }) ? true : false;
        return hasGroupActionSheet;
      }

      return configStatusActionGroups.find(( actionGroup: any ) => { 
        return actionGroup.id === status.group?.id 
      }) ? true : false;

    });

    let statusByGroup: any = {};
    statusInActionSheet?.forEach(( actionSheetGroup: any ) => {

      let statusActionSheetGroup = actionSheetGroup.group;
      let statusActionSheetGroupId = statusActionSheetGroup?.id;
      
      if( Array.isArray(actionSheetGroup.group) ) {
        // console.log(actionSheetGroup.group);
        statusActionSheetGroup = actionSheetGroup.group.find((g: any) => { 
          return configStatusActionGroups.find(( actionGroup: any ) => { 
            return actionGroup.id === g.id 
          }) ? true : false;
        });
        statusActionSheetGroupId = statusActionSheetGroup?.id;
      }

      if( statusActionSheetGroupId && !statusByGroup.hasOwnProperty( statusActionSheetGroupId ) ) {
        statusByGroup[statusActionSheetGroupId] = {
          group: {
            ...statusActionSheetGroup,
            icon: configStatusActionGroups.find(( actionGroup: any)  => {
              return actionGroup.id === statusActionSheetGroupId
            })?.icon,
            color: configStatusActionGroups.find(( actionGroup: any)  => {
              return actionGroup.id === statusActionSheetGroupId
            })?.color,
            highlights: configStatusActionGroups.find(( actionGroup: any)  => {
              return actionGroup.id === statusActionSheetGroupId
            })?.highlight
          },
          status: []
        };
      }

      statusByGroup[statusActionSheetGroupId].status.push( actionSheetGroup );
    });
    
    // Sort items in group
    // 
    for (const [groupKey, groupObj] of Object.entries(statusByGroup)) {
      const _group: any = statusByGroup[groupKey];
      if( _group && _group.hasOwnProperty('status') ) {
        _group['status'].sort(( a: any, b: any ) => {
          return a.sort_group - b.sort_group;
        });
      }
    }

    // Sort Groups
    //
    return Object.values(statusByGroup).sort((a: any, b: any) => {
      const configForGroupA = configStatusActionGroups.find(( actionGroup: any ) => {
        return a.group.id === actionGroup.id
      });
      const configForGroupB = configStatusActionGroups.find(( actionGroup: any ) => {
        return b.group.id === actionGroup.id
      });
      return configForGroupA?.sort - configForGroupB?.sort;
    });

  }, [ configQuery.data, statusQuery.data ]);

  // Build Status Action Map (Button in Sheet)
  //
  const statusActionMap = useMemo(() => {
    if( !statusActionGroups ) return;
    
    const _statusActionMap: any = {};

    statusActionGroups?.forEach(( actionGroup: any ) => {
      const buttons = actionGroup.status?.map((status: any) => {
        const button: any = {
          text: status.name,
          data: {            
            action: status.status,
            statusId: status.id
          },
        };
        
        if( actionGroup.group.highlights?.includes(status.id) ) {
          button.icon = flashOutline;
        }

        return button
      });
      
      // Add Cancel button
      // 
      buttons.push({
        text: t('components.actionSheet.cancel'),
        role: 'cancel',
        data: {
          action: 'cancel',
        },
      });

      _statusActionMap[actionGroup.group.id] = {
        header: actionGroup.group.name,
        buttons
      };
    });

    return _statusActionMap;
  }, [ configQuery.data, statusActionGroups ]);

  // Comment Data
  //
  const _statusChangeText = useMemo(() => {
    return statusChangeText;
  }, [ statusChangeText ]);

  // Send Data to Server
  //
  const mutate = useCallback(async ( applicantId: any, statusId: any, jobId?: any ) => {

    return applicantStatusMutation.mutateAsync({
      applicant: {
        id: applicantId,
        jobId
      },
      status: {
        id: statusId
      },
      comment: _statusChangeText
    }, {      
      onSuccess: ( applicantStatusMutationResponse ) => {
        // be creative
      },
      onError: () => {
        // be creative
      },
      onSettled: async ( data, error, variables, context) => {
        // be creative       
      }
    });

  }, [ _statusChangeText ]);

  // Show a comment Modal
  // And listen for Close event
  //
  const onIonActionSheetModalDismiss = useCallback(async ( statusId?: string ) => {
    return new Promise((resolve, reject) => {

      presentStatusCommentModal({     
        htmlAttributes: {
          'data-status-id': statusId,
        },
        onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
          resolve( ev.detail );
        },      
        ...{
          backdropDismiss: USE_HOTFIX_BACKDROP
        }
      });

   });
  }, [ selectedStatus, statusChangeText ]);

  // Actionsheet
  // select Status
  //
  const onIonActionSheetDismiss = useCallback(async ( applicantId: string, detail: any, jobId?: string ) => {

    if( !detail || (detail && (detail.role === 'cancel' || detail.role === 'backdrop')) ) {
      return;
    }

    const statusId = detail.data.statusId;
    setSelectedStatus(statusId);

    // Let user write a Comment
    // 
    const statusCommentObj: any = await onIonActionSheetModalDismiss( statusId );

    if( 
      !statusCommentObj || 
      !statusCommentObj.data || 
      statusCommentObj.role === 'cancel' || 
      statusCommentObj.role === 'backdrop' 
    ) return;

    const statusComment = statusCommentObj.data;

    try {

      const resultDismiss: any = await mutate( applicantId, statusId, jobId );

      if( resultDismiss?.success ) {
        presentActionSheetToast({
          ...TOAST_OPTIONS_DEFAULT,
          message: 'Auswahl gespeichert: ' + detail.data.action + ' | Kommentar: ' + statusComment,
          color: 'success',
        });  

        return;
      }
  
      if( !resultDismiss?.success ) {
        presentActionSheetToast({
          ...TOAST_OPTIONS_DEFAULT,        
          message: 'Speichern fehlgeschlagen: ' + detail.data.action + ' | Kommentar: ' + statusComment,
          color: 'danger'
        });  

        return;
      }  
      
    } catch ( error ) {

      presentActionSheetToast({
        ...TOAST_OPTIONS_DEFAULT,        
        message: 'Speichern fehlgeschlagen: ' + detail.data.action + ' | Kommentar: ' + statusComment,
        color: 'danger'
      }); 

      return error;
      
    }

  }, [ selectedStatus, statusChangeText ]);

  // Present Actionsheet
  //
  const present = useCallback(( applicantId: string, statusGroupId: string, jobId?: string, settings: any = {} ) => {

    // console.log(statusGroupId, statusActionMap);

    // prevented den bug
    // aber eigentlich muss sichergestellt werden,
    // das diese variable immer gefüllt ist
    if( !statusActionMap ) return alert('@bug useApplicantActionSheet');

    const statusObj = statusActionMap[ statusGroupId ];

    if( !applicantId || !statusObj ) return;

    const statusSheetSettings = objectMap(statusObj, ( setting: any ) => {
      return setting;
    });

    presentActionSheet({
      ...statusSheetSettings,
      onWillDismiss: ( { detail }: any ) => onIonActionSheetDismiss( applicantId, detail, jobId ),
      ...settings      
    });

  }, [ statusActionGroups, statusActionMap ]);

  return {
    mutate,
    present,
    data: statusActionGroups,
    isSuccess: configQuery.isSuccess && statusQuery.isSuccess,
    isError: configQuery.isError || statusQuery.isError,
    isLoading: configQuery.isLoading || statusQuery.isLoading,
    isFetching: configQuery.isFetching || statusQuery.isFetching,
    isFetched: configQuery.isFetched && statusQuery.isFetched
  }
};

export default useApplicantActionSheet;