import axios from "axios";
import { objectMap, sleep } from "../utils/util";
import { getStorageData } from "../services/storageService";
import { mapJobApplicantStatusMetrics } from "../utils/helper";

export const FAKE_RESPONSE_TIME = 1000;
export const FAKE_ITEM_LIMIT = 10;

const USE_COMPANY_FAKE_DATA = true;

const FAKE_ENDPOINTS_COMPANY: any = {
  config:           { url: '/db/config.json' },
  i18n:             { url: '/db/i18n.json' },
  
  status:           { url: '/db/dm/status.json' },
  jobs:             { url: '/db/dm/jobs.json' },
  applicants:       { url: '/db/dm/applicants.json' },

  'status.set':     { url: '/dm/NOT_DEFINED' }
};

export const FAKE_ENDPOINTS: any = USE_COMPANY_FAKE_DATA ? FAKE_ENDPOINTS_COMPANY : {
  config:           { url: '/db/config.json' },
  i18n:             { url: '/db/i18n.json' },
  
  status:           { url: '/db/status.json' },
  jobs:             { url: '/db/jobs.json' },
  applicants:       { url: '/db/applicants.json' },

  'status.set':     { url: '/NOT_DEFINED' }
};

async function apiRequest( endpoint: string, data?: any, method: any = 'GET', baseUrl?: string ){  

  const datasource = await getStorageData("datasource");
  let TEMP_ENDPOINT = FAKE_ENDPOINTS[ endpoint ]['url'];  
  if( datasource !== '' && datasource !== 'default' ) {    
    TEMP_ENDPOINT = TEMP_ENDPOINT.replace('dm/', datasource + '/');
  }
  
  return axios({
    method,
    url: TEMP_ENDPOINT,
    data
  });
}

interface FAKE_BACKEND_FN {
  (
    query?: { 
      tables: any,
      ids: any,
      limit?: number,
      offset?: number 
    }, 
    queryType?: 'filter' | 'find',
      
  ): Promise<{ applicants: any; jobs: any; status: any; }>
}

export const FAKE_BACKEND: FAKE_BACKEND_FN = async ( 
  query?: { 
    tables: any,
    ids: any,
    limit?: number,
    offset?: number
  }, 
  queryType?: string,
) => {

  await sleep(FAKE_RESPONSE_TIME * (Math.random() * (0.7 - 1.5) + 1.5)); // fake delay

  const _limit = query?.limit || query?.limit === 0 ? query.limit : -1;
  const _offset = query?.offset ? query.offset : 0;
  const _queryType = queryType ? queryType : 'filter';

  const response_jobs       = await apiRequest( 'jobs' );
  const response_applicants = await apiRequest( 'applicants' );
  const response_status     = await apiRequest( 'status' );

  let applicants = response_applicants.data.applicants;
  let jobs = response_jobs.data.jobs;

  const statusAndGroups = response_status.data;
  let status = response_status.data.status;

  // Setup Status
  // 
  if( statusAndGroups && statusAndGroups.groups ) {
    const statusQueryDataStatus: any = objectMap(statusAndGroups.status, ( status: any ) => {
      
      if( Array.isArray(status.group) ) {
        
        let groupObjs = status.group.map(( groupId: any ) => {
          return statusAndGroups.groups.find(( group: any ) => {
            return group.id == groupId;
          });
        });

        return {
          ...status,
          group: groupObjs.length > 0 ? groupObjs.length === 1 ? {
            ...groupObjs[0]
          } : groupObjs : null
        }
      }

      const groupObj = statusAndGroups.groups.find(( group: any ) => {
        return group.id == status.group;
      });

      return {
        ...status,
        group: groupObj ? {
          ...groupObj
        } : null
      }
    });

    const statusQueryDataStatusArr: any = Object.values(statusQueryDataStatus);
    
    status = [
      ...statusQueryDataStatusArr
    ];
  }

  status.forEach((stat: any) => {
    stat.count = applicants.filter((applicant: any) => { 
      if( Array.isArray(applicant.status) ) {
        return applicant.status.includes(stat.id);
      }
      return applicant.status == stat.id 
    }).length;
  });

  // Setup Applicants
  //   
  applicants.forEach((applicant: any) => {
    if( Array.isArray(applicant.status) ) {
      applicant.status = status.filter((stat: any) => {
        return applicant.status.includes(stat.id);
      });
      if( applicant.status.length === 1 ) {
        applicant.status = applicant.status[0];
      }
    } else {
      applicant.status = status.find((stat: any) => {
        return applicant.status == stat.id 
      });
    }

    applicant.job = jobs.find((job: any) => { return applicant.job == job.id });
  });

  // Setup Jobs
  // 
  jobs.forEach((job: any) => {
    // bad:
    const _applicants = JSON.parse( JSON.stringify( applicants ) );

    const jobApplicants = _applicants.filter((applicant: any) => { 
      return applicant.job.id == job.id;
    });
    job.applicants = jobApplicants;
    job.metrics = mapJobApplicantStatusMetrics(job.id, _applicants, status);
  });

  let queryTables = [];

  let isJobsQuery = false;
  let isApplicantsQuery = false;
  let isStatusQuery = false;

  // Query Element
  //
  if( query && query.tables.length === query.ids.length ) {

    if( query.tables.indexOf('jobs') !== -1 ) {
      const jobQueryId = query.ids[query.tables.indexOf('jobs')];
      if( jobQueryId ) {
        isJobsQuery = true;
        queryTables.push('jobs');
        jobs = jobs[_queryType]((job: any) => { return job.id == jobQueryId });        
      }
    }
    if( query.tables.indexOf('applicants') !== -1 ) {
      const applicantQueryId = query.ids[query.tables.indexOf('applicants')];
      if( applicantQueryId ) {
        isApplicantsQuery = true;
        queryTables.push('applicants');
        applicants = applicants[_queryType]((applicant: any) => { return applicant.id == applicantQueryId });
      } 
    }
    if( query.tables.indexOf('status') !== -1 ) {
      const statusQueryId = query.ids[query.tables.indexOf('status')];
      if( statusQueryId ) {
        isStatusQuery = true;
        queryTables.push('status');
        status = status[_queryType]((stat: any) => { return stat.id == statusQueryId }); 
      }
    } 

  }

  let backend: any = {
    applicants,
    jobs,
    status    
  };

  if( _limit > 0 ) {

    // bad
    const _backend = JSON.parse( JSON.stringify( backend ) );

    if( isJobsQuery ) {
      if( Array.isArray(_backend.jobs) ) {
        _backend.jobs = _backend.jobs.slice(_offset, _offset + _limit);
      }    
      _backend.applicants = _backend.applicants.slice(_offset, _offset + _limit );
      _backend.jobs.applicants = _backend.jobs.applicants.slice(_offset, _offset + _limit);
    }

    if( isApplicantsQuery ) {
 
      if( Array.isArray(_backend.applicants) ) {
        _backend.applicants = _backend.applicants.slice(_offset, _offset + _limit);
      }      
      _backend.jobs = _backend.jobs.slice(_offset, _offset + _limit);
    } 

    backend = _backend;

  }

  return backend;
};