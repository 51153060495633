import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react';
import { initialState, AppState, reducers } from './state'
import { createStorage, setStorageData } from '../services/storageService';
import { useConfigQuery, useI18nQuery, useStatusQuery } from '../hooks/useApi';
import { createGlobalStyle } from 'styled-components';
import hexRgb from 'hex-rgb';
import { APP_DATASOURCES, APP_USE_DATASOURCES } from '../utils/const';

 
// Interfaces
//
interface AppContextState {
  appState: AppState;
  appDispatch: React.Dispatch<any>;
}

interface AppContextProps {
  children?: React.ReactNode;
}

const AppContext = createContext<AppContextState>({
  appState: initialState,
  appDispatch: () => undefined
});

const CustomCSSStyles = createGlobalStyle((props: any) => {
  const stylesData = props.stylesData;
  
  if( !stylesData ) return;
  
  console.warn('(NOT IMPLEMENTED YET) - CustomCSSStyles', stylesData);
  
  return null;  
});

const StatusStyles = createGlobalStyle((props: any) => {
  
  const statusData = props.statusData;

  if( !statusData || statusData.length === 0 ) return null;

  return `:root {
    ${statusData.map((_status: any) => {

      const statusId = _status.appstatusid;
      const statusColorMain = _status.statushexcode;

      const colorRgbFromHex = hexRgb(statusColorMain, {format: 'array'});
      colorRgbFromHex.pop();

      return `--color-status-${statusId}: ${statusColorMain};
          --color-status-${statusId}--rgb: ${colorRgbFromHex.join(',')};`;
      }).join('')}
    }

    ${statusData.map((_status: any) => {
      const statusId = _status.appstatusid;
      return `
        .c-applicant-item.is-status-${statusId} { --status-color: var(--color-status-${statusId}); }     
        .c-applicant-card.is-status-${statusId} { --status-color: var(--color-status-${statusId}); }
        .c-alert.is-status-${statusId} {
          --background: rgb(var(--color-status-${statusId}--rgb), 0.1);
          --border-color: var(--color-status-${statusId});
          --color: var(--color-status-${statusId});   
        }
      `;
    }).join('')}
  `;

});

export const AppContextProvider: React.FC<AppContextProps> = (props => {

  const [store, dispatch] = useReducer(reducers, initialState);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const [ isStoreSetup, setIsStoreSetup ] = useState(false);

  const getDatasource = useCallback(() => {
    const urlParams = new URLSearchParams( window.location.search );
    const datasource = urlParams.get('datasource');
    return datasource && APP_DATASOURCES.includes(datasource) ? datasource : 'default';
  }, [ window.location.search ]);  

  const configQuery   = useConfigQuery();  
  const i18nQuery     = useI18nQuery();
  const statusQuery   = useStatusQuery('status');

  // Remove Splashscreen
  // with listener
  //
  const removeSplashscreen = useCallback(() => {
    document.querySelector('.c-splashscreen__svg')?.addEventListener(
      "animationiteration",
      (event: any) => {
        document.documentElement.classList.remove('is-loading');
      },
      { once: true }
    );
  }, [ document ]);


  // Perform Action before Rendering
  useLayoutEffect(() => {
    
  }, [ ]);

  // set "language" to IndexedDB
  //  
  useEffect( () => {
    if( i18nQuery.data ) {  
      // setStorageData("i18n", i18nQuery.data);
      for (const [i18nKey, i18nValue] of Object.entries( i18nQuery.data )) {
        setStorageData('i18n.' + i18nKey, i18nValue);
      }
    }
  }, [ i18nQuery.data ]);

  useEffect( () => {
    if( !configQuery.isLoading && !i18nQuery.isLoading && !statusQuery.isLoading ) {   
      removeSplashscreen();
    }
    // console.log('%c -- AppContext.tsx ACCESS | MOUNT', 'color: orange');
    // return () => {
    //   console.log('%c -- AppContext.tsx ACCESS | UNMOUNT', 'color: orange');
    // }
  }, [ configQuery.isLoading, i18nQuery.isLoading, statusQuery.isLoading ]);

  const memoedValue = useMemo(() => ({
    appState: store,
    appDispatch: dispatch
  }), [ store, dispatch ]);

  return (
    <AppContext.Provider value={memoedValue}>
      <CustomCSSStyles stylesData={configQuery.data?.styles} />
      <StatusStyles statusData={statusQuery.data}/>
      {props.children}
    </AppContext.Provider>
  )
});

// Let's only export the `useAppContext` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAppContext() {
  return useContext(AppContext);
}