import { IonCol, IonGrid, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";
import { useJobTotalsQuery } from "../hooks/useApi";

import './JobStatusMetric.css';
import { TJob, TJobTotal } from "../types";

const JobCardMetric: React.FC<{job: TJob}> = ({...props}) => {
  const job = props.job;
  const jobId = job?.jobReqId;

  // Queries
  //
  const jobStatusMetricsQuery = useJobTotalsQuery( jobId );  

  return (
    <IonGrid className='c-job-status-metric' style={{'--ion-grid-padding': 0}}>
      <IonRow>

        {(!jobStatusMetricsQuery.isSuccess || jobStatusMetricsQuery.isLoading) && [1,2,3].map((dummyMetric: any, dummyIndex: number) => {
          return <IonCol key={`c-job-status-metric__item--dummy-${jobId}-${dummyIndex}`} size='auto' className='c-job-status-metric__item'>
            <IonLabel className="c-job-status-metric__item-label">
              <strong><IonSkeletonText animated  style={{ width: '2ch', height: '0.9em' }} /></strong>
              <IonSkeletonText animated  style={{ width: '6ch', height: '1em', marginTop: '0.6em' }} />
            </IonLabel>
          </IonCol>
        })}

        {jobStatusMetricsQuery.isSuccess && !jobStatusMetricsQuery.isLoading && jobStatusMetricsQuery?.data?.map(( metric ) => {
          
          if( metric.hasOwnProperty('applicationFilterId') && metric.amount === 0 ) return;

          return <IonCol key={`c-job-status-metric__item--${jobId}-${metric.label}`} size='auto' className='c-job-status-metric__item'>         
            <IonLabel className="c-job-status-metric__item-label">
              <strong>{metric.amount}</strong><br />
              {metric.defaultText}
            </IonLabel>
          </IonCol>
        })}

      </IonRow>
    </IonGrid>
  );
};

export default JobCardMetric;