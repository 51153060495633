import { TListJobsBase, TListJobsItem, TListJobsItemComponent } from "../../../types";
import { LAYOUT_JOBS_LIST, LAYOUT_LIST_STYLE_ID_CARD } from "../../../utils/const";
import { ListJobsItemCard } from "./ListJobsItemCard";
import { ListJobsItemList } from "./ListJobsItemList";

export const ListJobsItem: React.FC<TListJobsBase & TListJobsItem & TListJobsItemComponent> = ({ ...props }) => {
  const job       = props.job;
  const listType  = props.type ?? LAYOUT_JOBS_LIST;

  return listType === LAYOUT_LIST_STYLE_ID_CARD ? 
    <ListJobsItemCard className={`${props.className ?? ''}`} job={job} /> : 
    <ListJobsItemList className={`${props.className ?? ''}`} job={job} />
};