import { IonBadge, IonIcon, IonItem, IonLabel, IonSkeletonText } from "@ionic/react";
import { getPageUrl } from "../../../utils/urls";
import { APP_COMPONENTS_AVAILABLE, APP_COMPONENT_ID_NOTES, APP_COMPONENT_ID_PRESELECT, LAYOUT_CARD_VIEWS_POSITION_ID_BODY, LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER, LAYOUT_CARD_VIEWS_POSITION_ID_HEADER, LAYOUT_LIST_ITEM_INDICATOR, LAYOUT_LIST_ITEM_LEGACY } from "../../../utils/const";
import { chatboxEllipsesOutline } from "ionicons/icons";
import { TApplicant, TListApplicantsItem } from "../../../types";
import { useMemo } from "react";
import { getCardViewDetail, getCardViewPart } from "../../cardviews/CardViews";
import { renderCardViewDetails } from "../../cardviews/CardViewsItem";
import { renderCardView } from "../../cardviews/CardViews";
import CardViews from "../../cardviews/CardViews";

/*
 * LIST DEFAULT 
 * ## Item Inner
 */
export const ListApplicantsItemList: React.FC<{
  applicant?: TApplicant;
  indicator?: boolean | undefined;
  skeleton?: boolean
}> = ({ ...props }) => {
  
  const applicant       = props.applicant;
  const hasIndicator    = props.indicator ?? LAYOUT_LIST_ITEM_INDICATOR;

  const cardViews = applicant?.cardViews;
  const headerRows = useMemo(() => cardViews && getCardViewPart('header', cardViews), [ cardViews ]);
  const bodyRows = useMemo(() => cardViews && getCardViewPart('body', cardViews), [ cardViews ]);
  const footerRows = useMemo(() => cardViews && getCardViewPart('footer', cardViews), [ cardViews ]);
 
  const isOverdue = useMemo(() => {
    if( !applicant ) return;
    const isOverdueReference       = getCardViewDetail('msOverdue', applicant.cardViews);
    return isOverdueReference?.value;
  }, [ applicant?.cardViews ]);

  const applicantRating          = cardViews && getCardViewDetail('applicationRating', applicant.cardViews);
  const applicationCommentsCount = cardViews && getCardViewDetail('applicationCommentsCount', applicant.cardViews);

  if( !props.applicant || !cardViews ) {

    if( props.skeleton ) {
      return <IonItem className={`c-applicant-item${hasIndicator ? ` has-indicator` : ``}`}>
        <IonLabel>          
          <strong className="c-applicant-item__title">
            <IonSkeletonText animated style={{backgroundColor: 'rgba(var(--ion-color-primary-rgb),0.1)', height: '1.1em', width: '20ch'}} />
          </strong>    
          <span className="c-applicant-item__body" style={{marginBlock: '0.6em'}}>
            <IonSkeletonText animated style={{height: '0.8em', width: '10ch'}} />
          </span>    
          <span className="c-applicant-item__footer">
            <IonSkeletonText animated style={{height: '0.8em', width: '10ch'}} />
          </span>
        </IonLabel>    
      </IonItem>
    }

    return null;
  }

  return <IonItem routerLink={getPageUrl('applicant', { id: applicant!.applicationId })} className={`c-applicant-item is-status-${applicant.applicationStatus}${isOverdue ? ` is-overdue` : ``}${hasIndicator ? ` has-indicator` : ``}`}>
    <IonLabel>
      
      {headerRows && headerRows?.length > 0 && <strong style={{'color': 'var(--ion-color-primary)'}} className="c-applicant-item__title u-value-flow">
        <CardViews cardViews={cardViews} part="header" />
      </strong>}

      {bodyRows && bodyRows?.length > 0 && <span className="c-applicant-item__body">
        <CardViews cardViews={cardViews} part="body" />
      </span>}

      {footerRows && footerRows?.length > 0 && <span className="c-applicant-item__footer">
        <CardViews cardViews={cardViews} part="footer" />
      </span>}     

    </IonLabel>

    { (applicationCommentsCount?.value > 0 || applicantRating?.value !== null) && <IonLabel slot='end'>
      {applicantRating?.value !== null && <IonBadge className='c-applicant-item__preselection'>
        {applicantRating?.value}
      </IonBadge>} 
      {applicationCommentsCount?.value > 0 && <IonBadge className='c-applicant-item__notes'>
        <IonIcon src={chatboxEllipsesOutline}></IonIcon>
        <span>{applicationCommentsCount?.value}</span>
      </IonBadge>}              
    </IonLabel> }

  </IonItem>
};