import { IonCard, IonCardContent, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow, IonSkeletonText } from "@ionic/react";
import { useMemo } from "react";

import './ListApplicants.css';
import InlineSeperator from "../inline/InlineSeperator";
import { LAYOUT_APPLICANT_LIST, LAYOUT_CARD_VIEWS_POSITION_ID_HEADER, LAYOUT_LIST_STYLE_ID_CARD, STATUS_ID_ALL } from "../../utils/const";
import { useTranslation } from "react-i18next";
import { ListApplicantsGroup } from "./ListApplicants/ListApplicantsGroup";
import { ListApplicantsItem } from "./ListApplicants/ListApplicantsItem";
import { sortArrayBy } from "../../utils/util";
import { TApplicant, TApplicants, TJob, TJobs, TListApplicantsProps } from "../../types";
import { getCardViewPart } from "../cardviews/CardViews";
import { renderCardViewDetailsValue } from "../cardviews/CardViewsItemDetail";
import { closeCircleOutline } from "ionicons/icons";
import CardViewsItemDetail from "../cardviews/CardViewsItemDetail";
import CardViews from "../cardviews/CardViews";
import { ListApplicantsItemList } from "./ListApplicants/ListApplicantsItemList";

const ListApplicants: React.FC<TListApplicantsProps> = ({ ...props }) => {
  
  const { t }         = useTranslation();

  const data          = props.data;

  // define defaults
  const listType      = props.type ?? LAYOUT_APPLICANT_LIST;
  const isSlide       = props.slide !== false ? true : false;
  const showSkeleton  = true;

  // api data checks
  //
  const hasPages: boolean = useMemo(() => {
    return data?.hasOwnProperty('pages');
  }, [ data ]);

  const hasApplicants = useMemo(() => {
    if(  
      data?.hasOwnProperty('data') && 
      Array.isArray( data.data ) && 
      data.data.length > 0 && 
      data.data[0].hasOwnProperty('jobApplications')
    ) {
      return true;
    }

    if(
      Array.isArray(data) &&
      data.length > 0 &&
      data[0].hasOwnProperty('applicationId')
    ) { 
      return true;
    }

    return false;
  }, [ data ]);

  const showEmptyMsg = useMemo(() => {
    const _showEmptyMsg  = props.emptyMsg === false ? false : true;    
    return _showEmptyMsg && (
      (hasPages && !hasApplicants) ||
      !hasApplicants          
    );
  }, [props.emptyMsg, hasPages, hasApplicants]);

  // Check if wished grouping is possoble
  //
  // Jobs
  const isGroupedByJobs: boolean = useMemo(() => {
    if( props.groupedBy !== 'job' ) return false;
    return hasApplicants;
  }, [props.groupedBy, hasApplicants]);

  const jobs: TJobs | undefined = useMemo(() => {
    if( !isGroupedByJobs ) return;
    return data.data;
  }, [ isGroupedByJobs, data ]);

  // 
  // Status
  //
  const isGroupedByStatus: boolean = useMemo(() => {
    if( !data || props.groupedBy !== 'status' ) return false;
    
    if( hasPages ) {
      return data.pages.every((_page: any) => { 
        return _page.every((_entry: any) => _entry.hasOwnProperty('jobApplications'));
      });
    }

    return data.every((_entry: any) => _entry.hasOwnProperty('jobApplications') );  

  }, [props.groupedBy, hasPages, data]); 

  // Get Applicants from given data
  //
  const applicants: TApplicants = useMemo(() => {
    if( !hasApplicants ) return [];
    
    if( Array.isArray( data ) && data.length > 0 && data[0].hasOwnProperty('applicationId') ) {
      return data;
    }

    const _applicants: TApplicants = [];
    
    if( jobs ) {
      jobs.forEach(( job: any ) => {
        job.jobApplications.forEach(( applicant: TApplicant ) => {
          _applicants.push(applicant);
        });
      });
    }

    return _applicants;
  }, [hasApplicants, jobs]);  
 
  /*
   * If Type is CARD
   * @TODO: Check if this is actually working
   * because we did a lot to the list style.
   */
  if( listType === LAYOUT_LIST_STYLE_ID_CARD ) {

    return <IonRow className={`c-applicants-list c-applicants-list--${listType}${!hasApplicants ? ` c-applicants-list--empty`: ''}${isGroupedByJobs ? ' c-applicants-list--is-grouped' : ''}`}>
      {(() => {

        if( showSkeleton && !data ) {
          return null;
        }

        // if No Items
        //
        if( showEmptyMsg ) {
          return <IonCard className="c-applicants-list__no-items">
            <IonCardContent>
              <IonIcon icon={closeCircleOutline}/>
              <span>{t('no-application-available')}</span>
            </IonCardContent>
          </IonCard>
        }

        // If List is grouped by Jobs
        //
        if( isGroupedByJobs && jobs && !hasPages ) {
          return jobs.map((job: any, jobIndex: number) => {
            const jobApplicants = job.jobApplications; 

            return <ListApplicantsGroup applicants={jobApplicants} groupHeader={() => {
              return <>
                <strong>{job.title}</strong>
                <InlineSeperator />
                <span>ID: {job.id}</span>
              </>
            }} groupId={job.id} type={listType} />
          });
        } 

        // If List is grouped by Status
        //
        if( isGroupedByStatus ) {
          return hasPages ? data.pages.map((_page: any) => _page.map((status: any) => {
            
            const statusApplicants = status.applicants;  
            // const sortedStatusApplicants = sortArrayBy(statusApplicants, 'name');
            const sortedStatusApplicants = statusApplicants;

            return <ListApplicantsGroup applicants={sortedStatusApplicants} groupHeader={() => {
              return <strong>{status.name}</strong>
            }} groupId={status.id} type={listType} />

          })) : data.map((status: any, statusIndex: number) => {
            
            const statusApplicants = status.applicants;  
            const sortedStatusApplicants = sortArrayBy(statusApplicants, 'name');
            
            return <ListApplicantsGroup applicants={sortedStatusApplicants} groupHeader={() => {
              return <strong>{status.name}</strong>
            }} groupId={status.id} type={listType} />

          });
        }         

        return hasPages ? data.pages.map((page: any) => {

          return page.map((applicant: any) => {
            return <IonCol key={`c-applicants-list__item-item--${applicant.applicationId}`} size='12' sizeMd="6" className="c-jobs-applicants-list__item-body">
              <ListApplicantsItem type={listType} slide={isSlide} key={`c-applicants-list-item--${applicant.applicationId}`} applicant={applicant} />
            </IonCol>
          });

        }) : data.map((applicant: any) => {
          
          return <IonCol key={`c-applicants-list__item-item--${applicant.applicationId}`} size='12' sizeMd="6" className="c-jobs-applicants-list__item-body">
            <ListApplicantsItem type={listType} slide={isSlide} key={`c-applicants-list-item--${applicant.applicationId}`} applicant={applicant} />
          </IonCol>

        });

      })()}  
    </IonRow>
  }

  /*
   * If Type is LIST
   */  
  return <IonList key={`c-applicants-list`} lines='full' className={`c-applicants-list c-applicants-list--${listType}${isGroupedByJobs ? ' c-applicants-list--is-grouped' : ''}${!hasApplicants ? ` c-applicants-list--empty`: ''}`}>
    {(() => {

      if( showSkeleton && !data ) {
        return [1,2,3].map((d) => <ListApplicantsItemList key={`c-applicants-list--dummy-${d}`} skeleton />);
      }

      // if No Items
      //
      if( showEmptyMsg ) {
        return <IonItem className="c-applicants-list__no-items">
          <IonLabel>
            <IonIcon icon={closeCircleOutline}/>
            <span>{t('no-application-available')}</span>
          </IonLabel>
        </IonItem>
      }

      // If List is grouped by Jobs
      //
      if( isGroupedByJobs && jobs ) {
        return jobs.map(( job ) => {
          
          return <ListApplicantsGroup applicants={job.jobApplications} groupHeader={() => {

            return <CardViews cardViews={job.jobReqInfo.cardViews} part="header" />

          }} groupId={job.jobReqId} type={listType} key={`c-applicants-list__group--job-${job.jobReqId}`}/>
        });
      }

      // If List is grouped by Status
      //      
      if( isGroupedByStatus && false ) {
        //
        // @TODO
        //
      }

      // List no grouping
      //       
      return hasPages ? data.pages.map((page: TApplicants) => {

        return page.map(( applicant ) => {
          return <ListApplicantsItem type={listType} slide={isSlide} key={`c-applicants-list-item--${applicant.applicationId}`} applicant={applicant} />
        });

      }) : applicants.map(( applicant ) => {
        
        return <ListApplicantsItem type={listType} slide={isSlide} key={`c-applicants-list-item--${applicant.applicationId}`} applicant={applicant} />
      
      });

    })()}

  </IonList>

};

export default ListApplicants;