import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRouterLink, IonRow, IonSkeletonText, IonText, IonTitle, IonToolbar, createAnimation, useIonRouter } from '@ionic/react';

import './ApplicantDetail.css';
import { useApplicantQuery, useConfigQuery } from '../hooks/useApi';
import { RouteComponentProps } from 'react-router';
import QueryStateIndicator from '../components/QueryStateIndicator';
import ModalApplicantNotes from '../modals/ModalApplicantNotes';
import { getPageUrl } from '../utils/urls';
import { arrowUpSharp } from 'ionicons/icons';
import { APPLICANT_SEGMENTS_AVAILABLE, APPLICANT_SEGMENT_DEFAULT, APPLICANT_SEGMENT_ID_CONTACT, APPLICANT_SEGMENT_ID_HISTORY, APPLICANT_SEGMENT_ID_NOTES, APPLICANT_SEGMENT_ID_PROFILE, APP_COMPONENTS_AVAILABLE, APP_COMPONENT_ID_NOTES, APP_COMPONENT_ID_PRESELECT, APP_DEBUG, STATUS_ID_ALL } from '../utils/const';
import ModalApplicantDocuments from '../modals/ModalApplicantDocuments';
import ApplicantPreselection from '../components/applicant/ApplicantPreselection';
import useApplicantActionSheet from '../hooks/useApplicantActionSheet';
import ResponsiveButtonGroup from '../components/ResponsiveButtonGroup';
import { useTranslation } from 'react-i18next';
import InlineSeperator from '../components/inline/InlineSeperator';
import useAppTracking from '../hooks/useAppTracking';
import { getIoniconByString } from '../utils/helper';
import { TApplicant, TApplicantDetail, TCardViews } from '../types';
import { getCardViewDetail, getCardViewPart } from "../components/cardviews/CardViews";
import CardViewsItemDetail from '../components/cardviews/CardViewsItemDetail';
import CardViewsItem from '../components/cardviews/CardViewsItem';
import CardViews from '../components/cardviews/CardViews';

/*
 * Detail Profile Actions
 */
interface ApplicantProfilActionsProps {
	applicant: TApplicantDetail
};

const ApplicantProfileActions: React.FC<ApplicantProfilActionsProps> = ({ ...props }) => {
  const applicant = props.applicant;
  
  // is preselect component available
  //
  const showPreselect: boolean = APP_COMPONENTS_AVAILABLE.includes(APP_COMPONENT_ID_PRESELECT);

  // Translation
  //
  const { t }          = useTranslation();

  // get Applicant Rating
  //
  const applicantRating = getCardViewDetail('applicationRating', applicant.cardViews);

  return <IonGrid className='v-applicant__actions'>
    <IonRow className='ion-align-items-center'>
      <IonCol size='4'><strong>{t('components.applicant.profile.documents')}:</strong></IonCol>
      <IonCol size='8'>
        <IonButton expand='block' id="trigger-modal-docs">{t('components.applicant.profile.open-documents')}</IonButton>
      </IonCol>
    </IonRow>
    {showPreselect && <IonRow className='ion-align-items-center'>
      <IonCol size='4'><strong>{t('preselect')}:</strong></IonCol>
      <IonCol size='8'>
        <ApplicantPreselection initialValue={applicantRating?.value}/>
      </IonCol>
    </IonRow>}
  </IonGrid>
};

/*
 * Detail Profile
 */
interface ApplicantProfileProps {
	applicant: TApplicantDetail
};

const ApplicantProfile: React.FC<ApplicantProfileProps> = ({ ...props }) => {
  const applicant       = props.applicant;
  const { t }           = useTranslation();

  const profileRows     = useMemo(() => {
    return applicant.cardViews.filter(( view: any ) => { 
      return view.grouping === APPLICANT_SEGMENT_ID_PROFILE;
    });
  }, [ applicant ]);

  const cardViews       = profileRows;
  const headerRows      = useMemo(() => getCardViewPart('header', cardViews), [ cardViews ]);
  const bodyRows        = useMemo(() => getCardViewPart('body', cardViews), [ cardViews ]);
  const footerRows      = useMemo(() => getCardViewPart('footer', cardViews), [ cardViews ]);  

  const primaryStatusId  = applicant.applicationStatus;
  
  const headerContentExcludedDetails = [
    'staticLabelApplicantAvailableFrom',
    'applicantAvailableFrom',
    'staticLabelApplicantDesiredSalary',
    'applicantDesiredSalary',
    'staticLabelMotivation',
    'jobApplicationMotivation',
    'jobApplicationQuestion',
    'jobApplicationAnswer' ,
  ];
  const headerContentSeperatorAfterValueCount = 2;

  const detailContentExcludedDetails = [
    'staticLabelMotivation',
    'jobApplicationMotivation',
    'jobApplicationQuestion',
    'jobApplicationAnswer'
  ];

  const applicantQuestions = useMemo(() => {
    const isQuestionReference       = getCardViewDetail('jobApplicationQuestion', cardViews);
    return isQuestionReference?.value;
  }, [ cardViews ]);

  const applicantMotivation = useMemo(() => {
    const isQuestionReference       = getCardViewDetail('jobApplicationMotivation', cardViews);
    return isQuestionReference?.value;
  }, [ cardViews ]);  
  
  const isOverdue = useMemo(() => {
    const isOverdueReference       = getCardViewDetail('msOverdue', cardViews);
    return isOverdueReference?.value;
  }, [ cardViews ]); 

  return <div className={`v-applicant__profile is-status-${primaryStatusId}${isOverdue ? ' is-overdue' : ''}`}>
    
    {/*
      * Applicant Header
      */}      
    <IonGrid className={`v-applicant__header ion-padding-bottom`}>
      <IonRow>
        <IonCol size='12'>
          <h1 className='v-applicant__title u-value-flow'>
            <CardViews cardViews={headerRows} classNameItemDetail='v-applicant__title-inner' />
          </h1>
          <div className='v-applicant__header-content'>
            {bodyRows.map(( row, rowIndex ) => {
              let outputCount = 0;                

              return <CardViewsItem key={`v-applicant__details-row--index-${rowIndex}`} cardViewItem={row} render={(cardView) => {
               
                const firstCardViewDetails = cardView.cardViewDetails[0];

                if(                 
                  firstCardViewDetails.displayType === 'reference_only' ||
                  headerContentExcludedDetails.includes(firstCardViewDetails.labelName)
                ) return;                
  
                if( firstCardViewDetails.labelName === 'staticLabelApplicantAddress' ) {
                  outputCount++;

                  return <>
                    {outputCount >= headerContentSeperatorAfterValueCount && <InlineSeperator />}
                    <span className='c-value c-value--wrapper v-applicant__header-content-address'>{cardView.cardViewDetails.map(( cardViewDetail, cardViewDetailIndex ) => {
                      if( cardViewDetailIndex === 0 ) return;
                      return <CardViewsItemDetail  key={`v-applicant__header-content-inner-value--${cardViewDetail.labelName}`} cardViewItemDetail={cardViewDetail} />
                    })}</span>
                  </>

                }

                return <CardViewsItem cardViewItem={cardView} map={( cardViewDetail, cardViewDetailIndex ) => {
                  return <CardViewsItemDetail key={`v-applicant__header-content-inner-value--${cardViewDetail.labelName}`} cardViewItemDetail={cardViewDetail} className='v-applicant__header-content-inner' render={(cardViewItemDetail) => {
                    outputCount++;
                    if( cardViewItemDetail.labelName === 'jobReqTitle' ) {
                      return <>
                        {outputCount > headerContentSeperatorAfterValueCount && <InlineSeperator />}
                        <IonRouterLink className='c-value c-value--link c-value--wrapper' routerLink={getPageUrl('job', { id: applicant.jobReqId })}>
                          <CardViewsItemDetail cardViewItemDetail={cardViewItemDetail} />
                        </IonRouterLink>
                      </>
                    }

                    return <>
                      {outputCount > headerContentSeperatorAfterValueCount && <InlineSeperator />}
                      <CardViewsItemDetail cardViewItemDetail={cardViewItemDetail} />
                    </>

                  }} />
                }} />

              }} />
       
            })} 
          </div>
        </IonCol>
      </IonRow>

    </IonGrid>

    {/*
      * Applicant Profile Action
      * ( Open Documents, Preselect )
      */}
    <ApplicantProfileActions applicant={applicant} />
    
    {/*
      * Applicant Details 
      */}
    <IonGrid className='v-applicant__details ion-padding-vertical'>
      <IonRow className='v-applicant__details-row'>
        <IonCol size='12' sizeMd='9'>
          {bodyRows.map(( row, rowIndex ) => {

            return <CardViewsItem key={`v-applicant__details-row--index-${rowIndex}`} cardViewItem={row} render={( cardView ) => {
              
              const firstCardViewDetails = cardView.cardViewDetails[0];

              if( detailContentExcludedDetails.includes(firstCardViewDetails.labelName) ) return null;

              if( 
                cardView.cardViewDetails.length > 1 && 
                firstCardViewDetails.displayType === 'show_localized_label' &&
                firstCardViewDetails.labelName.startsWith('staticLabel') 
              ) {   

                return <IonRow className='u-row-no-padding'>
                  
                  <IonCol size='6' sizeMd="5">
                    <strong>{firstCardViewDetails.labelName}:</strong>
                  </IonCol>

                  <IonCol className="u-value-flow" size='6' sizeMd="7">
                    { cardView.cardViewDetails.map(( cardViewDetail, cardViewDetailIndex ) => {

                      if( cardViewDetailIndex === 0 ) return null;

                      return <CardViewsItemDetail key={`v-applicant__details-row-item--${cardViewDetail.labelName}`} cardViewItemDetail={cardViewDetail}/>
                      
                    })}
                  </IonCol>

                </IonRow>

              }

              return null;
            }} />
            
          })}

        </IonCol>
      </IonRow>      
    </IonGrid>

    {/*
      * Applicant Questions
      */}
    {<IonGrid className='v-applicant__questions ion-padding-vertical'>      
      {/* No Question */}
      {!applicantQuestions?.length && <IonRow className='v-applicant__questions-row'>
        <IonCol size='12' className='v-applicant__questions-item'>
          <strong>{t('components.applicant.profile.no-questions')}</strong>
        </IonCol>
      </IonRow>}

      {/* Questions Available */}
      {applicantQuestions?.length > 0 && applicantQuestions.map((question: any, questionIndex: number) => {
        return <IonRow key={`question-${applicant.applicationId}-${questionIndex}`} className='v-applicant__questions-row'>
          <IonCol size='12' className='v-applicant__questions-item'>
            {JSON.stringify(question)}
          </IonCol>
        </IonRow>
      })}

    </IonGrid>}

    {/*
      * Applicant Motivation
      */}
    <IonGrid className='v-applicant__motivation ion-padding-vertical'>
      <IonRow className='v-applicant__motivation-row'>
        <IonCol size='12' className='v-applicant__motivation-item'>
          <strong>
            {getCardViewDetail('staticLabelMotivation', cardViews)?.labelName}
          </strong><br />
          {applicantMotivation ?? '--' }
        </IonCol>
      </IonRow>
    </IonGrid>    
    
    {/*
      * Document Modal
      */}    
    <ModalApplicantDocuments trigger='trigger-modal-docs'/>

  </div>
};

/*
 * Detail Notes
 */
const applicantHasNotes = (cardViews: TCardViews) => {
  return (
    cardViews.length > 0 && 
    cardViews[0].cardViewDetails.length > 0 &&
    ( 
      (cardViews[0].cardViewDetails[0].displayType === 'show_value' &&
      cardViews[0].cardViewDetails[0].value) ||
      (cardViews[0].cardViewDetails[0].displayType === 'show_localized_label')
    )
  );
};

const applicantGetNotes = (cardViews: TCardViews) => {
  return cardViews.filter(( view: any ) => { 
    return view.grouping === APPLICANT_SEGMENT_ID_NOTES;
  });
};

interface ApplicantNotesProps {
	applicant: TApplicantDetail
};

const ApplicantNotes: React.FC<ApplicantNotesProps> = ({ ...props }) => {
  const applicant       = props.applicant;
  
  const { t }           = useTranslation();

  const notesRows       = useMemo(() => applicantGetNotes(applicant.cardViews), [ applicant ]);
  const cardViews       = notesRows;
  const hasNotes        = useMemo(() => applicantHasNotes(cardViews), [cardViews]);

  return (
    <div className="v-applicant__notes">
      <IonGrid style={{ paddingBottom: 'var(--_spacing-inner)', paddingTop: 0 }}>  
        <IonRow>
          <IonCol size='12'>
            <IonButton expand='block' id="trigger-modal-notes">{t('components.applicant.comments.add')}</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      
      <IonList lines='full' className='v-applicant__list' style={{borderTop: '1px solid', borderColor: 'var(--ion-item-border-color, rgba(0,0,0,0.13))', paddingTop: 0}}>        
        
        {hasNotes && <CardViews cardViews={cardViews} render={( cardView ) => {
          return <CardViewsItem cardViewItem={cardView} render={(cardViewItem, cardViewItemDetails) => {
            return <IonItem key={`note-${applicant.applicationId}`}>
              <IonGrid style={{paddingBlock: 'var(--_spacing-inner)'}}>
                <IonRow>                  
                  <IonCol size='12' style={{paddingBottom: 0}}><strong>
                    {cardViewItemDetails.find((c) => c.labelName === 'commentatorFirstName')?.value}
                    {cardViewItemDetails.find((c) => c.labelName === 'commentatorLastName')?.value}
                  </strong></IonCol>
                  <IonCol size='12'><IonLabel color='medium'>
                    {cardViewItemDetails.find((c) => c.labelName === 'commentatorSummaryName')?.value}
                  </IonLabel></IonCol>
                  <IonCol size='12' style={{paddingTop: 0}}>
                    {cardViewItemDetails.find((c) => c.labelName === 'commentContent')?.value}
                  </IonCol>
                </IonRow>
              </IonGrid>              
            </IonItem>
          }} />
        }} />}
        
        {!hasNotes && <IonItem>
          <IonGrid style={{paddingBlock: 'var(--_spacing-inner)'}}>
            <IonRow>
              <IonCol className='ion-text-center'>{t('components.applicant.comments.no-items')}</IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>}
      </IonList>

      <ModalApplicantNotes applicantId={applicant.applicationId} trigger='trigger-modal-notes'/>
    </div>
  );
};

/*
 * Detail Contact
 */
interface ApplicantContactProps {
	applicant: TApplicantDetail
};

const ApplicantContact: React.FC<ApplicantContactProps> = ({ ...props }) => {
  const applicant = props.applicant;

  const contactRows     = useMemo(() => {
    return applicant.cardViews.filter(( view: any ) => { 
      return view.grouping === APPLICANT_SEGMENT_ID_CONTACT;
    });
  }, [ applicant ]);

  const headerRows = getCardViewPart('header', contactRows);
  const bodyRows   = getCardViewPart('body', contactRows);

  const applicantEmailDetail = getCardViewDetail('applicantEmail', headerRows);

  const cardViews       = contactRows;
  
  return (<IonGrid>  
    <IonRow>
      <IonCol size='12' sizeMd='8'>
        <h1 className='v-applicant__title u-value-flow'>
          <CardViews cardViews={cardViews} part='header' excludeDetails={['applicantEmail']} />
        </h1>

        <CardViews cardViews={cardViews} part='body' render={(cardView) => {
          return <CardViewsItem cardViewItem={cardView} render={(cardViewItem) => {
            const firstCardViewDetails = cardViewItem.cardViewDetails[0];

            if( 
              cardView.cardViewDetails.length > 1 && 
              firstCardViewDetails.displayType === 'show_localized_label' &&
              firstCardViewDetails.labelName.startsWith('staticLabel') 
            ) {   

              return <IonRow className='u-row-no-padding'>
                <IonCol size='4'><CardViewsItemDetail cardViewItemDetail={firstCardViewDetails} />:</IonCol>
                <IonCol size='8'>
                  { firstCardViewDetails.labelName === 'staticLabelAddress' ? <address>
                    {cardView.cardViewDetails.map(( cardViewDetail, cardViewDetailIndex ) => {
                      if( cardViewDetailIndex === 0 ) return null;
                      return <CardViewsItemDetail key={`v-applicant__details-row-item--${cardViewDetail.labelName}`} cardViewItemDetail={cardViewDetail}/>
                    })}
                  </address> : cardView.cardViewDetails.map(( cardViewDetail, cardViewDetailIndex ) => {
                    if( cardViewDetailIndex === 0 ) return null;
                    return <CardViewsItemDetail key={`v-applicant__details-row-item--${cardViewDetail.labelName}`} cardViewItemDetail={cardViewDetail}/>
                  }) }
                </IonCol>
              </IonRow>

            }

            return null;

          }} />
        
        }} />

        <IonRow className='u-row-no-padding'>
          <IonCol size='4'>
            <CardViewsItemDetail cardViewItemDetail={getCardViewDetail('staticLabelApplicantEmail', headerRows)!} />:
          </IonCol>
          <IonCol size='8'><a href={`mailto:${applicantEmailDetail?.value}`}>
            <CardViewsItemDetail cardViewItemDetail={applicantEmailDetail!} /></a>
          </IonCol>
        </IonRow>

      </IonCol>
    </IonRow>
  </IonGrid>);
};

/*
 * Detail Notes
 */
interface ApplicantHistoryProps {
	applicant: TApplicantDetail
};

const ApplicantHistory: React.FC<ApplicantHistoryProps> = ({ ...props }) => {
  const applicant = props.applicant;
  const { t }          = useTranslation();

  const historyRows     = useMemo(() => {
    return applicant.cardViews.filter(( view: any ) => { 
      return view.grouping === APPLICANT_SEGMENT_ID_HISTORY;
    });
  }, [ applicant ]);

  const hasHistory = useMemo(() => {
    return historyRows.length > 0;
  }, [ historyRows ]);

  return (
    <div className="v-applicant__history">

      <IonList className="v-applicant__list v-applicant__list--history" lines='full' style={{paddingTop: 0}}>
        
        {hasHistory && <CardViews cardViews={historyRows} render={(cardView) => {
          return <CardViewsItem cardViewItem={cardView} render={(cardViewItem) => {            
            return <IonItem>
              <IonGrid style={{paddingBlock: 'var(--_spacing-inner)'}}>
                <IonRow className='ion-justify-content-between'>
                  {cardViewItem.cardViewDetails.map((detail, index) => <IonCol size='auto'>
                    { detail.labelName === 'auditHistoryItemStatus' ? <strong>
                      <CardViewsItemDetail cardViewItemDetail={detail}/>
                    </strong> : <CardViewsItemDetail cardViewItemDetail={detail}/>}                     
                  </IonCol>)}
                </IonRow>
              </IonGrid>              
            </IonItem>
          }}/>
        }} />}
        
        {!hasHistory && <IonItem>
          <IonGrid style={{paddingBlock: 'var(--_spacing-inner)'}}>
            <IonRow>
              <IonCol>{t('components.applicant.auditHistory.no-items')}</IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>}
      </IonList>

    </div>
  );
};

const ApplicantSegment: React.FC<{
  segment: string
}> = ({ segment }) => {

  const configQuery    = useConfigQuery( 'applicant' );

  const availableSegments = useMemo(() => {
    if( !configQuery.data ) return;
    return configQuery.data?.tabs.filter((tab: string) => 
      APPLICANT_SEGMENTS_AVAILABLE.includes( tab )
    )?.map((item: string) => item);
  }, [ configQuery.data ]);

  const isSegmentAvailable = useCallback( ( _segment: string ) => {
    if( !availableSegments ) return false;
    return availableSegments.includes( _segment );
  }, [ availableSegments ]);

  if( !isSegmentAvailable( segment ) ) return null;

  return null;
};

interface ApplicantActionFooterProps {
	applicant: TApplicantDetail
};

const ApplicantActionFooter: React.FC<ApplicantActionFooterProps> = ({ applicant }) => {

  const applicantActionSheet = useApplicantActionSheet();
  const applicantId = applicant?.applicationId;
  
  const configSettingCountGroups = 3;

  const doStateAction = useCallback(( statusId: any ) => {
    if( statusId === STATUS_ID_ALL ) {
      return;
    }
    applicantActionSheet.present( applicantId, statusId );
  }, [ applicantActionSheet.isSuccess, applicantId ]);

  return applicantActionSheet.isSuccess && applicantActionSheet.data && applicantActionSheet.data.length > 0 && <IonGrid className='c-applicant-action-footer'>
    <IonRow>
      { applicantActionSheet?.data?.map(( group: any, groupIndex: number ) => {
        if( groupIndex >= configSettingCountGroups ) return;
        return <IonCol key={`v-applicant__footer-action-btn--${group.group.id}`} size={parseInt((12 / configSettingCountGroups) + '', 10) + ''} className='c-applicant-action-footer__col'>          
          <IonButton onClick={() => { doStateAction( group.group.id ); }} expand='block' className='c-applicant-action-footer__btn'>
            <IonIcon src={getIoniconByString(group.group.icon)} className='c-applicant-action-footer__icon'></IonIcon>
            <IonText style={{marginLeft: '5px'}} className='c-applicant-action-footer__text'>{group.group.name}</IonText>
          </IonButton>
        </IonCol>
      }) }
    </IonRow>
  </IonGrid>
};

/*
 * Detail Wrapper
 */
interface ApplicantDetailProps
  extends RouteComponentProps<{
    id: string;
    segment: string;
  }> {}

const ApplicantDetail: React.FC<ApplicantDetailProps> = ({ match }) => {
  const applicantId = match.params.id;

  const configQuery    = useConfigQuery();
  const applicantQuery = useApplicantQuery( applicantId );

  // Translation
  //
  const { t }          = useTranslation();

  // Tracking
  //  
  const tracking       = useAppTracking();

  const contentArea = useRef<HTMLIonContentElement>(null);

  const router = useIonRouter();

  // Is notes component available
  //
  const isNotesComponentAvailable: boolean = APP_COMPONENTS_AVAILABLE.includes(APP_COMPONENT_ID_NOTES) && APPLICANT_SEGMENTS_AVAILABLE.includes(APPLICANT_SEGMENT_ID_NOTES);
  const cardViews      = useMemo(() => {
    return applicantQuery.data?.cardViews;
  }, [ applicantQuery.data ]);
  const notesRows       = useMemo(() => cardViews && applicantGetNotes(cardViews), [ cardViews ]);  
  const hasNotes        = useMemo(() => isNotesComponentAvailable && notesRows && applicantHasNotes(notesRows), [cardViews]);

  // get available segements. defined in const
  // 
  const availableSegments = useMemo(() => {
    const applicantAvailableSegments: any = [];
    applicantQuery.data?.cardViews?.forEach(( cardDetail: any ) => {
      if( !applicantAvailableSegments.includes(cardDetail.grouping) ) {
        applicantAvailableSegments.push(cardDetail.grouping)
      }
    });
    return applicantAvailableSegments;
  }, [ applicantQuery.data ]);

  // check if segment is availble
  //
  const isSegmentAvailable = useCallback( ( segment: string ) => {
    if( !availableSegments ) return false;
    return availableSegments.includes( segment );
  }, [ availableSegments ]);

  // get current segment
  //
  const currentSegment = useMemo(() => {
    if( match.params.segment && isSegmentAvailable( match.params.segment ) ) {
      return match.params.segment;
    }
    return APPLICANT_SEGMENT_DEFAULT;
  }, [ match.params.segment, isSegmentAvailable ]);

  // on segment button click
  //
  const onButtonClick = useCallback(( value: any ) => {
    const routeUrl: string = getPageUrl('applicant', { id: applicantId, segment: value });
    router.push( routeUrl, 'none', 'replace', undefined, (baseEl: any) => createAnimation());
  }, [ applicantId, router ]);

  // on component render
  //
  useEffect(() => {    
    if( APP_DEBUG ) console.log('%c ApplicantDetail.tsx | MOUNTED', 'color: yellow');
    return () => {
      if( APP_DEBUG ) console.log('%c ApplicantDetail.tsx | UNMOINT cleanup', 'color: red');
    }
  }, []);

  return (
    <IonPage className='v-applicant u-page-above-menu'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref='/'></IonBackButton>
          </IonButtons>
          <IonTitle>
            {t('applicant.header')}
          </IonTitle>        
        </IonToolbar>
        <IonToolbar className='c-toolbar c-toolbar--applicant'>
          <ResponsiveButtonGroup onButtonClick={onButtonClick}>
            {(!configQuery.isSuccess || availableSegments.length === 0) && APPLICANT_SEGMENTS_AVAILABLE.map(( comp: string ) => {
              return <IonButton key={`c-toolbar__item--segment-dummy-${comp}`}>
              <span className='c-toolbar__item-inner'>
                <IonSkeletonText animated={true} style={{ width: '8ch', height: '1em' }} />
              </span>
            </IonButton>
            })}
            {configQuery.isSuccess && availableSegments.map(( segment: string ) => {
              return <IonButton key={`c-toolbar__item--segment-${segment}`} className={`${currentSegment === segment ? 'is-active' : ''}`} data-value={segment}>
                <span className='c-toolbar__item-inner' data-inner-text={t('components.applicant.' + segment + '.toolbar')}>
                  {t('components.applicant.' + segment + '.toolbar')}
                  {segment === APPLICANT_SEGMENT_ID_NOTES && <span> ({hasNotes ? notesRows!.length : '0'})</span>}
                </span>
              </IonButton>
            })}            
          </ResponsiveButtonGroup>                   
        </IonToolbar>   
      </IonHeader>

      <IonContent fullscreen ref={contentArea}>
        
        <QueryStateIndicator isError={applicantQuery.isError} isLoading={applicantQuery.isLoading} error={applicantQuery.error}></QueryStateIndicator>
        
        {configQuery.isSuccess && applicantQuery.isSuccess && <div className='v-applicant__inner'>
          {/* Profile Segment */}
          { currentSegment === APPLICANT_SEGMENT_ID_PROFILE && 
            <ApplicantProfile applicant={applicantQuery.data}/> }

          {/* Notes Segment */}
          { isNotesComponentAvailable && currentSegment === APPLICANT_SEGMENT_ID_NOTES && 
            <ApplicantNotes applicant={applicantQuery.data}/> }
          
          {/* Contact Segment */}
          { currentSegment === APPLICANT_SEGMENT_ID_CONTACT && 
            <ApplicantContact applicant={applicantQuery.data}/> }

          {/* History Segment */}
          { currentSegment === APPLICANT_SEGMENT_ID_HISTORY && 
            <ApplicantHistory applicant={applicantQuery.data}/> }
        </div>}
        
        { applicantQuery.isSuccess && 
          currentSegment === APPLICANT_SEGMENT_ID_PROFILE &&           
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-no-padding v-applicant__scroll-to-top">
            <IonCol className="ion-no-padding" size='12' style={{borderTop: '1px solid var(--_border-color, rgba(0,0,0,0.1))'}}>
              <a href={`${match.url}#top`} 
                onClick={(event: any) => { 
                  contentArea.current?.scrollToTop(696);
                  event.preventDefault(); 
                }}
                className="ion-padding-horizontal ion-align-items-center" 
                style={{
                  paddingBlock: 'calc(var(--ion-padding, 16px) * 2)', 
                  width: '100%', 
                  display: 'flex', 
                  textDecoration: 'none'
                }}
              >
                <IonIcon color='primary' slot="start" icon={arrowUpSharp}></IonIcon>
                <IonText color='primary' style={{ marginLeft: '0.5em' }}>
                  <strong>{t('scroll-to-top')}</strong>
                </IonText>
              </a>
            </IonCol>
          </IonRow>
        </IonGrid>}


      </IonContent>

      <IonFooter>
        {applicantQuery.isSuccess && <ApplicantActionFooter applicant={applicantQuery.data}/>}
      </IonFooter>  

    </IonPage>
  );
};

export default ApplicantDetail;
