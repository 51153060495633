import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import { TListJobsItem, TListJobsItemComponent } from "../../../types";
import InlineSeperator from "../../inline/InlineSeperator";
import JobCardMetric from "../../JobStatusMetric";
import { getDateReadable } from "../../../utils/util";
import { getPageUrl } from "../../../utils/urls";

export const ListJobsItemCard: React.FC<TListJobsItem & TListJobsItemComponent> = ({ ...props }) => {
  const job = props.job;
  
  return (
    <IonCard className={`c-job-card ${props.className ?? ''}`} button routerLink={getPageUrl('job_applicants', { id: job.id })}>
      <IonCardHeader>
        <IonCardTitle color={'primary'}><strong>{job.title}</strong></IonCardTitle>        
        <IonCardSubtitle color={'medium'}>
          <span>ID: {job.id}</span>
          <InlineSeperator />
          <span>{job.status === 'open' ? 'Offen' : ''} seit {getDateReadable(job.date, false)}</span>
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <JobCardMetric job={job} />
      </IonCardContent>
    </IonCard>    
  );
};