import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import { LAYOUT_LIST_ITEM_INDICATOR } from "../../../utils/const";
import { getPageUrl } from "../../../utils/urls";
import BadgeInternal from "../../inline/BadgeInternal";
import InlineStatus from "../../inline/InlineStatus";
import { getDateReadable } from "../../../utils/util";
import { TListApplicantsItem } from "../../../types";
import { getPrimaryStatus } from "../../../utils/helper";

export const ListApplicantsItemCard: React.FC<TListApplicantsItem> = ({ ...props }) => {

  const applicant       = props.applicant;
  const primaryStatus   = getPrimaryStatus(applicant.applicationStatus);
  const hasIndicator    = props.indicator ?? LAYOUT_LIST_ITEM_INDICATOR;

  // const showNotes: boolean      = APP_COMPONENTS_AVAILABLE.includes(APP_COMPONENT_ID_NOTES) && applicant.notes.length > 0;
  // const showPreselect: boolean  = APP_COMPONENTS_AVAILABLE.includes(APP_COMPONENT_ID_PRESELECT) && applicant.preselection;

  return <IonCard routerLink={getPageUrl('applicant', { id: applicant.applicationId })} className={`c-applicant-card${applicant.status ? ` is-status-${primaryStatus.id}`: ``}${hasIndicator ? ` has-indicator` : ``}`}>
    <IonCardHeader>
      <IonCardTitle color='primary' style={{display: 'flex'}}>
        <strong>{applicant.name}</strong>
        {applicant.internal && <BadgeInternal />}
      </IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <InlineStatus applicant={applicant} className='c-applicant-card__text-status' />
      <div className="c-applicant-card__text-application-date">
        Bewerbung vom {getDateReadable(applicant.application_date, false)}
      </div>
    </IonCardContent>
  </IonCard>
};