import './InlineAlert.css';

interface InlineAlertProps {
  color?: undefined | 'info' | 'warning' | 'danger' | 'success',
  children?: React.ReactNode,
  className?: string
};

const InlineAlert: React.FC<InlineAlertProps> = ({ ...props }) => {

  return (
    <div className={`c-alert c-alert--${props.color ?? 'info'} ${props.className}`}>
      {props.children ?? false}
    </div>
  );

};

export default InlineAlert;