import { ExtractStringKeys, ExtractStringKeysArr } from "./types";

/**
 * NEEDED APP Urls
 */
const URLS_APP     = {
  overview: '/overview',
  overview_jobs: '/overview/jobs',
  jobs: '/overview/jobs',
  job: '/job/:id',
  job_applicants: '/job/:id/applicants/:status?',
  applicant: '/applicant/:id/:segment?',
} as const;

/**
 * NEEDED PAGE Urls
 */
const URLS_PAGES    = {
  privacy: '/privacy',
  license: '/license'
} as const

const URLS          = {
  ...URLS_APP,
  ...URLS_PAGES
} as const;

const SEPERATOR     = '/' as const;

const buildUrl = ( rawPageUrl: string, params: any ) => {
    
  const urlFragments = rawPageUrl.split( SEPERATOR );

  let pageUrl = urlFragments.map(( routeParam, routeParamIndex ) => {
    
    if( !routeParam.startsWith(':') ) {
      return routeParam;
    }

    let _routeParam = routeParam.replace(`:`, '').replace(`?`, '');

    if( params.hasOwnProperty( _routeParam ) ) {
      return routeParam
        .replace(`:${_routeParam}`, params[_routeParam])
        .replace(`?${_routeParam}`, params[_routeParam])
        .replace(`${_routeParam}?`, params[_routeParam]);
    } else if ( 
      urlFragments.length - 1 === routeParamIndex && 
      routeParam.startsWith(':') 
    ) {
      return '';
    }

    return routeParam;

  }).join( SEPERATOR );

  if( 
    pageUrl.charAt(pageUrl.length - 1) === '/' || 
    pageUrl.charAt(pageUrl.length - 1) === '?'
  ) {
    pageUrl = pageUrl.slice(0, -1);
  }

  return `${pageUrl}` as const;
};

type TPageUrl = ExtractStringKeys<typeof URLS>;
type TPageParams = {
  id: string
  segment?: string
  status?: string
};

export const getPageUrl = (
  page: TPageUrl | typeof SEPERATOR, 
  params?: TPageParams, 
  plain?: boolean
) => {
  
  if( page === SEPERATOR ) return SEPERATOR;

  const urlPath = URLS[page];

  if( plain ) return `${urlPath}` as const;

  switch( page ) {
    default:
      const returnUrl = buildUrl( urlPath, params );
      return returnUrl;
  }
  
};

const _ddd = getPageUrl('/');

export default URLS;