import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import { useEffect, useLayoutEffect, useRef } from 'react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/utils.css';
import './theme/fonts.css';

/* Theme Components */
import './theme/components/value.css';
import './theme/components/input.css';
import './theme/components/button.css';
import './theme/components/list.css';
import './theme/components/card.css';
import './theme/components/alert.css';
import './theme/components/actionsheet.css';
import './theme/components/modal.css';
import './theme/components/toolbar.css';

import './theme/app.css';

// Utils
import URLS, { getPageUrl } from './utils/urls';

// Pages
import Overview from './pages/Overview';
import JobDetail from './pages/JobDetail';
import JobApplicants from './pages/JobApplicants';
import ApplicantDetail from './pages/ApplicantDetail';

// Components
import Menu from './components/Menu';
import SplashScreen from './components/SplashScreen';
import { useConfigQuery } from './hooks/useApi';
import AppHeader from './components/AppHeader';
import useAppContext from './data/AppContext';
import FAQ from './pages/FAQ';
import Page from './components/Page';
import AppFooter from './components/AppFooter';
import License from './pages/License';
import { APP_DEBUG } from './utils/const';
 
// Setup App
setupIonicReact({
  mode: 'md',
});

// Load Webfonts
const WebFont = require('webfontloader');
WebFont.load({
  custom: {
    families: [
      'dmBrand', 
      // '72'
    ],
    urls: [
      '/assets/fonts/dm/stylesheet.css', 
      // '/assets/fonts/72/stylesheet.css'
    ]
  },
});

const App: React.FC = () => {
  
  const { appState }    = useAppContext();
  const configData      = useConfigQuery();
  const configDataPages = useConfigQuery('pages');
  // const configDataPages = {isSuccess: true, data: null};

  useEffect( () => {
    if( APP_DEBUG ) console.log('%c App.tsx ACCESS | MOUNT', 'color: yellow');
    return () => {
      if( APP_DEBUG ) console.log('%c App.tsx ACCESS | UNMOUNT', 'color: red');
    }
  }, []);  

  const routerRef = useRef<HTMLIonRouterOutletElement | null>(null);

  return (
    <div className="ion-page-outer">
      <IonApp>
        <AppHeader />

        {configData.isSuccess && <IonReactRouter>          
        
          <IonRouterOutlet ref={routerRef} id="l-main">             
            <Redirect exact path="/" to={getPageUrl('overview', undefined, true)} />
            <Route path={getPageUrl('overview', undefined, true)} component={Overview} />
            
            <Route path={getPageUrl('job', undefined, true)} exact component={JobDetail} />
            <Route path={getPageUrl('job_applicants', undefined, true)} component={JobApplicants} />
            
            <Route path={getPageUrl('applicant', undefined, true)} component={ApplicantDetail} />

            {/* Normal Pages */}
            {/* {(() => {
              if( !configDataPages.data ) return;

              const _pages = [];
              for (const [key, value] of Object.entries( configDataPages.data )) {
                if( !value ) return;

                const pageConfigData: any = value;
                _pages.push(
                  <Route key={`page-route--${key}`} path={pageConfigData?.route} render={() => {
                    return <Page componentName={key} pageData={value} />
                  }} />                 
                );               

              }

              return _pages;
            })()} */}

            {/* License (package.json) */}
            <Route path={getPageUrl('license', undefined, true)} component={License} />

          </IonRouterOutlet>

          <Menu routerRef={routerRef} />
          
        </IonReactRouter>}

        <AppFooter />
      </IonApp>
    </div>
  );
}

export default App;

