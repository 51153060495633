import React, { ReactNode } from 'react';
import { TCardViewItem, TCardViewItemDetail } from '../../types';
import CardViewsItemDetail from './CardViewsItemDetail';

interface CardViewsItemProps {
  cardViewItem: TCardViewItem, 
  render?: ( cardView: TCardViewItem, cardViewDetail: TCardViewItemDetail[] ) => ReactNode,
  map?: ( cardViewDetail: TCardViewItemDetail, cardViewDetailIndex: number ) => ReactNode,
  className?: string
  classNameItemDetail?: string
}

const CardViewsItems: React.FC<CardViewsItemProps> = ( {...props} ) => {  
  return null;
}

const CardViewsItem: React.FC<CardViewsItemProps> = ( {...props} ) => {  

  const cardView = props.cardViewItem;

  const _renderViewItem = (cardViewDetail: TCardViewItemDetail, cardViewDetailIndex: number ) => {
    if( typeof props.map !== 'undefined' ) {
      return props.map(cardViewDetail, cardViewDetailIndex);
    }
    return <CardViewsItemDetail key={`c-card-view-item-detail--${cardViewDetail.labelName}`} className={props.classNameItemDetail} cardViewItemDetail={cardViewDetail}/>
  };

  if( typeof props.render !== 'undefined' ) {
    return props.render(cardView, cardView.cardViewDetails);
  }

  if( props.className ) {
    return <div className={props.className}>
      {cardView.cardViewDetails.map(_renderViewItem)}
    </div>
  }

  return cardView.cardViewDetails.map(_renderViewItem);
};

export default CardViewsItem;

export function renderCardViewDetails(cardViewItem: TCardViewItem, className?: string) {
  return <CardViewsItem cardViewItem={cardViewItem} className={className} />;
}

