import React, { useMemo } from 'react';
import { IonContent, IonPage } from '@ionic/react';

import './Jobs.css';
import { useAppConfigQuery, useJobsByStatusQuery } from '../hooks/useApi';
import QueryStateIndicator from '../components/QueryStateIndicator';
import ListJobs from '../components/list/ListJobs';
import { TJobStatus, TLayoutListStyles, TStatus } from '../types';
import { LAYOUT_LIST_STYLE_DEFAULT } from '../utils/const';

interface JobsByStatusProps {
  status: TJobStatus
}

const JobsByStatus: React.FC<JobsByStatusProps> = ( {...props} ) => {
  
  const appConfig       = useAppConfigQuery('layout.list_type_jobs');

  const status       = props.status; 
  const statusId     = status.id;
  const jobsByStatus = useJobsByStatusQuery( statusId );

  const listType = appConfig.isSuccess ? appConfig.data as TLayoutListStyles : LAYOUT_LIST_STYLE_DEFAULT;
  const listTypeStyles = listType === 'list' ? undefined : { '--background': 'transparent' };

  const jobs = useMemo(() => {
    return jobsByStatus?.data?.data;
  }, [ jobsByStatus.data ] ); 

  return (
    <IonPage className={`v-jobs v-jobs--${listType}`}>
      <IonContent fullscreen style={listTypeStyles} className='has-border-radius'>
        
        <QueryStateIndicator colorSpinner={listType === 'card' ? 'light' : undefined} isLoading={jobsByStatus.isLoading} isError={jobsByStatus.isError} error={jobsByStatus.error} />
        { jobsByStatus.isSuccess && jobs && <ListJobs data={jobs} type={listType} />}
      </IonContent>  
    </IonPage>
  );
};

export default JobsByStatus;
