import { IonCol, IonGrid, IonList, IonRow } from "@ionic/react";
import { useMemo } from "react";
import './ListJobs.css';
import { LAYOUT_JOBS_LIST, LAYOUT_LIST_STYLE_ID_CARD } from "../../utils/const";
import { TListJobsProps } from "../../types";
import { ListJobsItem } from "./ListJobs/ListJobsItem";

const ListJobs: React.FC<TListJobsProps> = ({ ...props }) => {
  
  const data      = props.data;
  const listType  = props.type ?? LAYOUT_JOBS_LIST;
  const hasPages  = useMemo(() => {
    return data?.hasOwnProperty('pages');
  }, [ data ]);

  if( listType === LAYOUT_LIST_STYLE_ID_CARD ) {
    return <IonGrid className={`c-jobs-list c-jobs-list--${listType}`}>
      <IonRow className={`c-jobs-list__inner`}>
        {hasPages ? data?.pages?.map(( _page ) => _page.map(( job ) => {
          
          return <IonCol className='c-jobs-list__item v-overview__col' size='12' sizeMd='6' key={`v-jobs-item--${job.jobReqId}`}>
            <ListJobsItem type={listType} key={`c-jobs-list__item--${job.jobReqId}`} job={job} />
          </IonCol>

        })) : data.map(( job ) => {

          return <IonCol className='c-jobs-list__item v-overview__col' size='12' sizeMd='6' key={`v-jobs-item--${job.jobReqId}`}>
            <ListJobsItem type={listType} key={`c-jobs-list__item--${job.jobReqId}`} job={job} />
          </IonCol>

        })}
      </IonRow>
    </IonGrid>
  }

  return <IonList lines="full" className={`c-jobs-list c-jobs-list--${listType}`}>
    {hasPages ? data?.map((_page: any) => _page.map(( job: any ) => {

      return <ListJobsItem type={listType} key={`c-jobs-list__item--${job.jobReqId}`} job={job} />

    })) : data.map(( job: any ) => {

      return <ListJobsItem type={listType} key={`c-jobs-list__item--${job.jobReqId}`} job={job} />
      
    })}
  </IonList>
};

export default ListJobs;