import { LAYOUT_CARD_VIEWS_POSITION_ID_BODY, LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER, LAYOUT_CARD_VIEWS_POSITION_ID_HEADER, LAYOUT_LIST_STYLES } from '../utils/const';

/*
 * Card Views
 */
export type TCardViewItemDetailDisplayType = 'show_value' | 'reference_only' | 'frontend_component' | 'show_localized_label';

// from config
export type TConfigCardViewItemDetail = {
  displayorder: number,
  displaytype: TCardViewItemDetailDisplayType, 
  islookupvalueonly: boolean,
  jobstatusdisplaycalculation: any[],
  labelname: string,
  postretrievaloperation: string,
  propertyname: string,
  sfexpand: string
};
export type TConfigCardViewItem = {
  position: typeof LAYOUT_CARD_VIEWS_POSITION_ID_HEADER | typeof LAYOUT_CARD_VIEWS_POSITION_ID_BODY | typeof LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER,
  category: number,
  cardviewdetails: TConfigCardViewItemDetail[]    
};
export type TConfigCardView = TConfigCardViewItem[];

// from job or applicant
export type TCardViewItemDetail = {
  displayOrder: number,
  labelName: string,
  displayType: TCardViewItemDetailDisplayType,
  value?: any,
  [key: string]: any
};

export type TCardViewItem = {
  position: typeof LAYOUT_CARD_VIEWS_POSITION_ID_HEADER | typeof LAYOUT_CARD_VIEWS_POSITION_ID_BODY | typeof LAYOUT_CARD_VIEWS_POSITION_ID_FOOTER,
  grouping?: string,
  cardViewDetails: TCardViewItemDetail[],
  [key: string]: any   
};
export type TCardViews = TCardViewItem[];

/*
 * Status
 */
export type TStatus = {
  appstatusid: string, 
  appstatusname: string, 
  appstatussetid: string,
  statushexcode: string,
  [key: string]: any
};
export type TStatuses = TStatus[];
export function isTStatus( status: TJobStatus | TStatusSet | TStatus ): status is TStatus {
  return (status as TStatus).appstatusid !== undefined;
}

/*
 * Job Status
 */
export type TJobStatus = {
  id: number,
  displayname: string,
  displayorder: number,
  displaytext: string,
  displaytype: number,
  showinkpi: boolean,
  cardview: TConfigCardView,
  jobapplicationstatuses: TStatuses,
  jobstatusdisplayfilters: any[]
}
export function isTJobStatus( status: TJobStatus | TStatusSet | TStatus ): status is TJobStatus {
  return (status as TJobStatus).id !== undefined && (status as TJobStatus).showinkpi !== undefined;
}

/*
 * Status Set
 */
export type TStatusSet = {
  appstatussetid: string,
  appstatuses: TStatuses
  [key: string]: any
}
export function isTStatusSet( status: TJobStatus | TStatusSet | TStatus ): status is TStatusSet {
  return (status as TStatusSet).appstatuses !== undefined && (status as TStatusSet).appstatussetid !== undefined;
}

/*
 * Applicant
 */
export type TApplicant = {
  applicationId: string, 
  applicationStatus: string,
  applicationStatusHexCode: string,
  applicableDisplayStatuses: string[],
  jobReqId: string,
  cardViews: TCardViews
};
export type TApplicants = TApplicant[];
export type TApplicantDetail = Omit<TApplicant, "applicableDisplayStatuses">;

export type TApplicantComment = {
  applicationId: string,
  content: string,
  commentedBy: string
};

/*
 * Job
 */
export type TJob = {
  jobReqId: string, 
  jobReqInfo: {
    cardViews: TCardViews,
    [key: string]: any
  },
  jobApplications: TApplicants,
  jobApplicationsTotals: TJobTotal[],
};
export type TJobs = TJob[];
export type TJobDetail = {
  jobReqId: number,
  details: {
    cardViews: TCardViews
  }
};

/*
 * Job Total
 */
export type TJobTotal = {
  amount: number,
  defaultText: string,
  displayStatusId: number,
  label: string,
}

/**
 * Allowed List Styles
 */
type TListStyles = typeof LAYOUT_LIST_STYLES;
export type TLayoutListStyles = never | TListStyles[number];

/**
 * Applicant List
 */
export type TListApplicantsBase = {
  type?: TLayoutListStyles,
  slide?: boolean
}

export type TListApplicantsItem = {
	applicant: TApplicant,
  indicator?: boolean
}

export type TListApplicantsComponent = {
	className?: string
}

export type TListApplicantsGroup = {  
  applicants: TApplicants,
  groupHeader?: () => React.ReactNode,
  groupId?: string
}

export type TListApplicantsPropsGroupedBy = 'job' | 'status';
export interface TListApplicantsProps extends TListApplicantsBase {
  data: any,
  groupedBy?: TListApplicantsPropsGroupedBy,
  emptyMsg?: boolean
}

/**
 * Job List
 */
export type TListJobsBase = {
  type?: TLayoutListStyles
}

export type TListJobsItem = {
	job: TJob,
}

export type TListJobsItemComponent = {
  className?: string
}

export interface TListJobsProps extends TListJobsBase {
  data: TJobs & {
    pages?: TJobs[]
  },
}


