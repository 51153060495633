import { useCallback, useEffect, useId, useLayoutEffect, useMemo, useRef, useState } from 'react';
import './ResponsiveButtonGroup.css';
import { IonButton, IonIcon, IonItem, IonList, IonPopover, createAnimation } from '@ionic/react';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { chevronDown } from 'ionicons/icons';
import { APP_DEBUG } from '../utils/const';

const COMPONENT_DEBUG = false;
const RESIZE_REFRESH_RATE = 100;

interface ResponsiveButtonGroupProps {
  onButtonClick?: (defaultValue: any) => void
  className?: string
  classNameActive?: string
  btnMoreLabel?: string
  children: React.ReactNode
};

const ResponsiveButtonGroup: React.FC<ResponsiveButtonGroupProps> = ({ ...props }) => {
  const elementId               = useId();

  const baseClass               = 'c-responsive-button-group';
  const btnClass                = `${baseClass}__btn`;
  const btnClassToggleModifier  = 'toggle';
  const toggleLabel             = props.btnMoreLabel || 'Mehr';

  const [ isDropDown, setIsDropDown ] = useState(false);

  // Ref to all Buttons
  //
  const allBtnsRef = useRef<Array<any>>([]);
  // const allBtnsRef = groupRef.current?.querySelectorAll(`.${btnClass}:not(.${btnClass}--${btnClassToggleModifier})`);

  // Ref to "More" trigger
  //
  const dropdownTriggerBtnRef = useRef<any>(null);

  const toggleActiveClass = useCallback(( activeChild: any ) => {
    if( !activeChild ) return;
    activeChild.classList.add();
  }, []);

  // prepare children
  //
  const childrenArr = useMemo(() => {
    const _clonedChilds = React.Children.toArray(React.Children.map(props.children, (child: any, childIndex) => {
      if( !child ) return child;
      
      const isLink = child.props.routerLink || child.props.href;

      const cloneProps: any = {
        className: child.props.className ? btnClass + ' ' + child.props.className : btnClass,      
      };

      if( isLink && typeof props.onButtonClick === 'undefined' ){
        cloneProps.routerAnimation = (baseEl: any) => createAnimation();
      }

      if ( typeof props.onButtonClick === 'function' ) {
        cloneProps.routerLink   = undefined;
        cloneProps.href         = undefined;
        cloneProps.onClick      = ( event: any ) => {
          if( typeof child.props.onClick === 'function' ) {
            child.props.onClick( event );
          }
          if( typeof props.onButtonClick === 'function' && child.props['data-value'] ) {
            props.onButtonClick( child.props['data-value'] );
          }
        };
      }
      
      // cloneProps.ref = (el: any) => allBtnsRef.current[childIndex] = el;

      return React.cloneElement(child, cloneProps);    
    }));

    if( COMPONENT_DEBUG ) console.log(_clonedChilds);
    return _clonedChilds;
  }, [ props.children ] );

  // listen to resize on outer element
  //
  const { width: _groupWidth, ref: groupRef } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: RESIZE_REFRESH_RATE,
    skipOnMount: false
  }); 

  // Compute Group Width
  //
  const groupWidth = useMemo(() => {
    return _groupWidth || window.innerWidth;
  }, [ _groupWidth ]);

  // Compute All Button Width
  //  
  const allBtnsWidth = useMemo(() => {
    
    if( !allBtnsRef.current.length ) return 0;    
    if( !dropdownTriggerBtnRef.current ) return 0;    

    const dropdownToggleBtn = dropdownTriggerBtnRef?.current;
    const dropdownToggleStyle = dropdownToggleBtn.currentStyle || window.getComputedStyle(dropdownToggleBtn);
    const dropdownToggleWidth = dropdownToggleBtn.offsetWidth; // ? parseInt(dropdownToggleBtn.offsetWidth, 10) : parseInt(dropdownToggleStyle.width, 10);

    return Array.from(allBtnsRef?.current).reduce((accumulator: any, currentValue: any, currentIndex: number) => {
      if( !currentValue ) return accumulator;

      const style = currentValue?.currentStyle || window.getComputedStyle(currentValue);
      const width = currentValue.offsetWidth; // ? parseInt(currentValue.offsetWidth, 10) : parseInt(style.width, 10);
      const margin = parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10); 

      const currentBtnWidth = (width + (isNaN(margin) ? 0 : margin));
      let currentBtnsWidth = accumulator + currentBtnWidth;

      return currentBtnsWidth;
    }, isNaN(dropdownToggleWidth) ? 0 : dropdownToggleWidth);

  }, [ childrenArr, groupWidth, allBtnsRef.current, dropdownTriggerBtnRef.current ]);

  if( COMPONENT_DEBUG ) console.log(
    'groupWidth', groupWidth, 
    'allBtnsWidth', allBtnsWidth
  );  

  // define child split index
  //
  const childSplit = useMemo(() => {
    const defaultChildSplit = childrenArr.length - 1;

    let _childSplit = defaultChildSplit;

    let isValueSet = false;

    // Get Toggle width
    //    
    const dropdownToggleBtn = dropdownTriggerBtnRef?.current;
    const dropdownToggleBtnWidth = dropdownToggleBtn?.offsetWidth; // ? parseInt(dropdownToggleBtn.offsetWidth, 10) : parseInt(dropdownToggleStyle.width, 10);
    
    // Helper function for loop (reducer) below
    //
    // const checkForSplit = (currentBtnWidth: any, currentBtnsWidth: any, currentIndex: any) => {
    //   let _currentBtnsWidth = currentBtnsWidth;

    //   if( !isValueSet && groupWidth && _currentBtnsWidth >= groupWidth ) {
        
    //     isValueSet = true;        
    //     _childSplit = currentIndex - 1;      

    //     // at first "touch" hide 2 items.
    //     // and we have to subtract the width of the toggle button
    //     // for the first
    //     if( _childSplit === defaultChildSplit - 1 ) {
    //       isValueSet = false;
    //       _childSplit = defaultChildSplit;

    //       if( (_currentBtnsWidth - dropdownToggleBtnWidth) >= groupWidth ) {

    //         isValueSet = true;
    //         _childSplit -= 2;

    //       }
    //     }    
    //   }

    //   return _childSplit;

    // };
    
    // Loop over all buttons and look for width 
    //
    // Array.from(allBtnsRef?.current).reduce((accumulator: any, currentValue: any, currentIndex: number) => {
    //   const style = currentValue.currentStyle || window.getComputedStyle(currentValue);
    //   const width = currentValue.offsetWidth; // ? parseInt(currentValue.offsetWidth, 10) : parseInt(style.width, 10);
    //   const margin = parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10); 

    //   const currentBtnWidth = (width + (isNaN(margin) ? 0 : margin));
    //   const currentBtnsWidth = accumulator + currentBtnWidth;

    //   checkForSplit(currentBtnWidth, currentBtnsWidth, currentIndex);

    //   return currentBtnsWidth;
    // }, isNaN(dropdownToggleBtnWidth) ? 0 : dropdownToggleBtnWidth); // starting width (width of toggle)
    
    const allBtnsRefArr = Array.from(allBtnsRef?.current);
    const allBtnsFallbackWidth = 100;
    
    let _allBtnsReduce = isNaN(dropdownToggleBtnWidth) ? 0 : dropdownToggleBtnWidth;

    if( !allBtnsRefArr.length ) return defaultChildSplit;

    for(let currentIndex = 0; allBtnsRefArr.length; currentIndex++){
      if( isValueSet ) break;
      
      const currentBtn = allBtnsRefArr[currentIndex];
      const style = currentBtn?.currentStyle;
      const width = currentBtn?.offsetWidth > 0 ? currentBtn.offsetWidth : allBtnsFallbackWidth;
      const margin = parseInt(style?.marginLeft, 10) + parseInt(style?.marginRight, 10); 
      const currentBtnWidth = (width + (isNaN(margin) ? 0 : margin));
      
      _allBtnsReduce = _allBtnsReduce + currentBtnWidth;

      if( !isValueSet && groupWidth && _allBtnsReduce >= groupWidth ) {        
        isValueSet = true;        
        _childSplit = currentIndex - 1;      
        if( _childSplit === defaultChildSplit - 1 ) {
          isValueSet = false;
          _childSplit = defaultChildSplit;
          if( (_allBtnsReduce - dropdownToggleBtnWidth) >= groupWidth ) {
            isValueSet = true;
            _childSplit -= 2;
          }
        }    
      }

    }

    if( COMPONENT_DEBUG ) console.log('childsplit', _childSplit > 0 ? _childSplit : 0);
    return _childSplit > 0 ? _childSplit : 0;
  }, [ childrenArr, allBtnsRef.current, groupWidth, groupRef.current ]);

  if( COMPONENT_DEBUG ) console.log(
    'childsplit', childSplit, 
    'childrenArr.length', childrenArr.length,
    'groupWidth', groupWidth,     
  );;

  // Do we have a split at all 
  //
  const isChildSplit = useMemo(() => {
    return childSplit < childrenArr.length - 1;
  }, [ childSplit, childrenArr ]);

  // useLayoutEffect(() => {
  //   const dropdownToggleBtn = dropdownTriggerBtnRef?.current;
  //   const dropdownToggleStyle = dropdownToggleBtn.currentStyle || window.getComputedStyle(dropdownToggleBtn);
  //   const dropdownToggleWidth = dropdownToggleStyle.offsetWidth ? parseInt(dropdownToggleStyle.offsetWidth, 10) : parseInt(dropdownToggleStyle.width, 10);

  //   const btnsRefWidth = allBtnsRef.current.reduce(( currentBtnWidth: any, currentBtn: any ) => {
  //     const style = currentBtn.currentStyle || window.getComputedStyle(currentBtn);
  //     const width = style.offsetWidth ? 
  //       parseInt(style.offsetWidth, 10) : 
  //         (
  //           isNaN( parseInt(style.width, 10) ) ?
  //           0 : 
  //           parseInt(style.width, 10)
  //         );
  //     return currentBtnWidth + width;
  //   }, isNaN(dropdownToggleWidth) ? dropdownToggleWidth : 0);

  //   console.log(btnsRefWidth, groupWidth);

  // }, [ childrenArr, groupWidth, allBtnsRef ]);

  // useEffect(() => {
  //   console.log('%c ResponsiveSegmentButtonGroup.tsx | MOUNTED', 'color: yellow');
  //   return () => {
  //     console.log('%c ResponsiveSegmentButtonGroup.tsx | UNMOINT cleanup', 'color: red');
  //   }
  // }, [ childSplit ]);

  /*
   * We only hide the children with "visibility: hidden" and "position: absolute"
   * because of calculation. we need the button width at all time.
   * so this is the fix kind of.
   */
  return (
    <div ref={groupRef} className={`${baseClass} ${props.className ? props.className : ''}`}>
      
      <nav className={`${baseClass}__inner`}>
        
        {/*
        * Output children
        */}        
        {childrenArr.map((child: any, i) => {
          const isHidden = i > childSplit; 
          return React.cloneElement(child, {
            style: {
              visibility: isHidden ? 'hidden' : 'visible',
              position: isHidden ? 'absolute' : 'relative',
            },
            ref: (el: any) => allBtnsRef.current[i] = el,
            className: `${(child.props.className ?? '')} ${isHidden ? ' is-hidden' : ''}`
          });
        })}

        {/*
        * Toggle button
        */} 
        {<IonButton style={{
            visibility: isChildSplit ? 'visible' : 'hidden',
          }}
          ref={dropdownTriggerBtnRef}
          className={`${btnClass} ${btnClass}--${btnClassToggleModifier}${isChildSplit ? ' is-visible' : ''}`}
          id={`${baseClass}--${btnClassToggleModifier}--${elementId}`}
        >
          <span className="c-toolbar__item-inner">
            <strong>&nbsp;</strong>
            <span className='c-toolbar__item-desc'>{toggleLabel} <IonIcon style={{marginBottom: -3}} icon={chevronDown}></IonIcon></span>            
          </span>
        </IonButton>}
      </nav>
      
      {/*
      * Clone our children
      */}
      {isChildSplit && <IonPopover alignment='end' side='bottom' className={`${baseClass}__dropdown`} dismissOnSelect={true} trigger={`${baseClass}--${btnClassToggleModifier}--${elementId}`}>
        <IonList className={`${baseClass}__dropdown-list`} lines='full'>
          {childrenArr.map((child: any, i) => {
            if( i <= childSplit ) return;
            return <IonItem className={`${baseClass}__dropdown-list-item`} key={`${baseClass}__dropdown-list-item--${i}`}>
              {React.cloneElement(child, {
                inlist: true,
                expand: 'block',
                className: child.props.className ? child.props.className.replace(btnClass, baseClass + '__dropdown-btn') : `${baseClass}__dropdown-btn`
              })}
            </IonItem>;
          })}
        </IonList>
      </IonPopover>}

    </div>
  );

};

export default React.memo(ResponsiveButtonGroup);