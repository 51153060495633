import { useCallback, useEffect, useLayoutEffect } from "react";
import { ANALYTICS_ACTIVE, ANALYTICS_TENANT_ID } from "../utils/const";
import { stringToHash } from "../utils/util";

const TRACKING_EFNAME = 'ci360';
const TRACKING_TENANT_ID = ANALYTICS_TENANT_ID;

// Create the function
function addLibrary( libraryUrl: string, options?: any, onLoad?: () => void ) {
  if( !ANALYTICS_ACTIVE ) return;

  const libraryIdDOM: string = options?.id ? options.id : stringToHash(libraryUrl);

  const isLibraryScriptInDOM = document.getElementById(libraryIdDOM);
  if( isLibraryScriptInDOM ) {
    return true;
  }

  const script: any = document.createElement("script");
  script.src = libraryUrl;
  script.async = true;
  script.id = libraryIdDOM;
  
  script.onLoad = onLoad ? onLoad() : undefined;
  
  script.setAttribute("data-efname", TRACKING_EFNAME);

  document.body.appendChild(script);

  return script;
}

function addScript( provider: 'sas' = 'sas' ){
  if( !ANALYTICS_ACTIVE ) return;

  const scriptIdDOM: string = stringToHash(provider + '-init');
  const script: any = document.createElement("script");

  const isScriptInDOM = document.getElementById(scriptIdDOM);
  if( isScriptInDOM ) {
    return true;
  }

  script.id = scriptIdDOM;  
  
  switch ( provider ) {
    case 'sas':  
      script.innerText = `(function(ci,a){window[ci]||function(){var ef=window[ci]=function(){return ef.q.push(arguments);};ef.q=[];ef.a=a||{};}();})('${TRACKING_EFNAME}',{tenantId:'${TRACKING_TENANT_ID}'});`;
  }

  document.body.appendChild(script);

  return script;
}

function initProviderScripts( provider: 'sas' = 'sas', options?: any ){
  switch ( provider ) {
    case 'sas':
      addScript( 'sas' );
      addLibrary( 'https://exc.t.mm.dm.de/js/ot-all.min.js', {
        id: 'ob-script-async'
      } );
  }
}

export default function useAppTracking( provider: 'sas' = 'sas', options?: any ){
  
  const trackEvent = useCallback(( data: {} ) => {
    switch ( provider ) {
      case 'sas':
        (window as any)[TRACKING_EFNAME](data)
    }
  }, [ provider ]);

  useEffect(() => {
    initProviderScripts( provider, options );
  }, [ provider ]);

  return trackEvent;
}