import { IonButton, IonIcon, IonMenuButton, IonRouterLink, IonSearchbar } from "@ionic/react";
import { arrowForwardOutline, menuOutline, searchOutline } from "ionicons/icons";
import companyLogo from '../svg/logo-main.svg';
import { useCallback, useEffect, useRef } from "react";
import './AppHeader.css';
import { getStorageData } from "../services/storageService";
import { APP_USE_DATASOURCES } from "../utils/const";

const AppHeader: React.FC = () => {
  
  const searchComponentRef = useRef<any>();
  const toggleSearchbar = useCallback(() => {
    searchComponentRef.current.classList.toggle('is-show');
    searchComponentRef.current.querySelector('input[type="search"]').focus();
  }, []);

  const _TEMP_DATASOURCE_ROUTE = useRef('');
  const _TEMP_DEFINE_DATASOURCE = useCallback(async () => {
    const datasource = await getStorageData("datasource");
    _TEMP_DATASOURCE_ROUTE.current = datasource === 'default' ? '' : datasource;
    return datasource;  
  }, []);

  useEffect(() => {
    if( APP_USE_DATASOURCES ) _TEMP_DEFINE_DATASOURCE();
  }, []);
    
  return (
    <header id="l-header" className='ion-justify-content-between ion-align-items-center'>
      <IonMenuButton color={'light'}>
        <IonIcon icon={menuOutline}></IonIcon>
      </IonMenuButton>
      <div id="o-logo">
        <IonRouterLink href={`/${_TEMP_DATASOURCE_ROUTE.current !== '' ? `?datasource=${_TEMP_DATASOURCE_ROUTE.current}` : ''}`}>
          <svg className="c-logo c-logo--header">
            <use xlinkHref="#logo-company"></use>
          </svg>
        </IonRouterLink>
      </div>
      <div className="c-search" ref={searchComponentRef}>
        <div className="c-search__toggle-placeholder" style={{width: 50}}></div>
        {/*
        <IonButton shape='round' fill='clear' color={'light'} onClick={toggleSearchbar} className='c-search__toggle'>
          <IonIcon icon={searchOutline}></IonIcon>
        </IonButton>     
        <IonSearchbar showCancelButton='always' cancelButtonIcon={arrowForwardOutline} onIonBlur={toggleSearchbar}></IonSearchbar>
        */}
      </div>
    </header>   
  );
};

export default AppHeader;