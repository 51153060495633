import i18n from "i18next";
import { useCallback, useLayoutEffect } from "react";
import { initReactI18next } from "react-i18next";
import { useI18nQuery } from "../hooks/useApi";
import { APP_LANGUAGE_DEFAULT, APP_LANGUAGE_SUPPORTS } from "../utils/const";

i18n
  .use(initReactI18next)
  .init({
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false
    },
    fallbackLng: APP_LANGUAGE_DEFAULT,
    supportedLngs: APP_LANGUAGE_SUPPORTS,
    ns: [],
    resources: {}
});

const AppTranslation: React.FC<{lang?: string, children?: React.ReactNode;}> = ({ lang, children }) => {
  
  const _lang = lang || 'de';
  const i18nQuery = useI18nQuery( _lang );

  const initLang = useCallback(() => {

    if( !i18nQuery.data ) return;

    i18n.addResourceBundle('de', 'app', i18nQuery.data.de);
    i18n.addResourceBundle('en', 'app', i18nQuery.data.en);
    i18n.setDefaultNamespace('app');

  }, [ i18nQuery.data ])

  const removeLang = useCallback(() => {

    if( !i18nQuery.data ) return;
    
    i18n.removeResourceBundle('de', 'app');
    i18n.removeResourceBundle('en', 'app');
    
  }, [ i18nQuery.data ])

  useLayoutEffect(() => {
    initLang();
    return () => {
      removeLang()
    };
  }, [ i18nQuery.isLoading ]);
  
  return children;
}

export default AppTranslation;