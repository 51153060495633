import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonPage, IonRouterLink, IonSegment, IonSegmentButton, IonSkeletonText, IonTitle, IonToolbar, createAnimation, useIonRouter, useIonToast } from '@ionic/react';

import { useApplicantsByJobAndStatusInfiniteQuery, useAppConfigQuery, useJobApplicantsInfiniteQuery, useJobApplicantsQuery, useStatusQuery, useApplicantsByJobAndStatusQuery, useConfigQuery, useJobDetailQuery, useNavStatusListQuery, useJobQuery, useJobTotalsQuery, useNavStatusTotalsQuery } from '../hooks/useApi';
import { RouteComponentProps } from 'react-router';

import URLS, { getPageUrl } from "../utils/urls";
import { addOutline, chatboxEllipsesOutline, informationCircleOutline, warningOutline } from 'ionicons/icons';
import QueryStateIndicator from '../components/QueryStateIndicator';

// Styles
import './JobApplicants.css';
import ResponsiveButtonGroup from '../components/ResponsiveButtonGroup';
import { APP_DEBUG, LAYOUT_APPLICANT_LIST, STATUS_ID_ALL, STATUS_SLUG_ALL } from '../utils/const';
import { useTranslation } from 'react-i18next';
import ListApplicants from '../components/list/ListApplicants';
import useAppTracking from '../hooks/useAppTracking';
import { TJobStatus, TLayoutListStyles, TStatus, isTJobStatus } from '../types';
import { getCardViewDetail, getCardViewDetailValue, getCardViewItemWith } from "../components/cardviews/CardViews";

interface JobApplicantsToolbarProps {
  jobId: string,
  statusId?: string | number
}
const JobApplicantsToolbar: React.FC<JobApplicantsToolbarProps> = ({ jobId, statusId }) => {
  
  // Queries
  //
  const navStatusListQuery  = useNavStatusListQuery('job');
  const jobTotalsQuery      = useNavStatusTotalsQuery('job', jobId);

  // Translation
  //
  const { t, i18n } = useTranslation();

  // Get Router Instance
  //
  const router = useIonRouter();

  // Get Nav Items
  //
  const hasStatusListItems = useMemo(() => {
    return (
      navStatusListQuery?.isSuccess && 
      navStatusListQuery?.data && 
      Array.isArray(navStatusListQuery?.data) && 
      navStatusListQuery?.data.length > 0
    );
  }, [navStatusListQuery]);

  const navStatusListItems = useMemo(() => {
    return navStatusListQuery?.data;
  }, [navStatusListQuery?.data]);  

  // On Button Click
  //
  const onButtonClick = useCallback(( value: any ) => {
    let _statusSlug: string = '';

    if( _statusSlug !== STATUS_SLUG_ALL ) _statusSlug = value;
    const routeUrl: string = getPageUrl('job_applicants', { id: jobId, status: _statusSlug });

    router.push( 
      routeUrl, 
      'none', 
      'replace', 
      undefined, 
      (baseEl: any) => createAnimation()
    );
  }, [ jobId, router ]);

  // On Render
  //  
  useEffect(() => {
    if( APP_DEBUG ) console.log('%c JobApplicantsToolbar.tsx | MOUNTED', 'color: yellow');
    return () => {   
      if( APP_DEBUG ) console.log('%c JobApplicantsToolbar.tsx | UNMOINT cleanup', 'color: red');
    }    
  }, [ ]);

  return (
    <IonToolbar class="c-toolbar c-toolbar--status">
      
      <ResponsiveButtonGroup onButtonClick={onButtonClick} btnMoreLabel={t('toolbar.more')}>

        {!hasStatusListItems && [1,2,3,4].map((index: any) => {
          return <IonButton className="c-toolbar__item" key={`c-toolbar__item-dummy-${index}`}>
            <span className='c-toolbar__item-inner'>
              <strong className='c-toolbar__item-count'><IonSkeletonText animated={true} style={{ width: '2ch', height: '0.9em' }} /></strong>
              <span className="c-toolbar__item-desc"><IonSkeletonText animated={true} style={{ width: '10ch', height: '1em' }} /></span>                
            </span>
          </IonButton>
        })}

        {hasStatusListItems && navStatusListItems?.map((status, index) => {

          const statusTotalLoaded = jobTotalsQuery.isSuccess && !jobTotalsQuery.isLoading;
          const statusTotal = statusTotalLoaded && 
          jobTotalsQuery.data &&
          Array.isArray(jobTotalsQuery.data) &&
          jobTotalsQuery.data.length > 0 && 
          jobTotalsQuery.data.find(_total => _total.displayStatusId === status.id);

          return <IonButton className={`c-toolbar__item ${(!statusId && index === 0) || status.id === statusId ? 'is-active' : ''}`} data-value={status.displayname} key={`c-toolbar__item-status--${status.id}`}>
            <span className={`c-toolbar__item-inner`} data-inner-text={status.displaytext}>
              {!statusTotalLoaded && <IonSkeletonText animated={true} style={{ width: '2ch', height: '0.9em' }} />}
              {statusTotalLoaded && statusTotal && <strong className='c-toolbar__item-count'>{statusTotal.amount}</strong>}
              <span className="c-toolbar__item-desc">{status.displaytext}</span>
            </span>
          </IonButton>
        })}

      </ResponsiveButtonGroup>
      
    </IonToolbar>
  );
};

interface JobApplicantsSectionProps {
  jobId: string,
  statusId?: string | number,
  sortByField?: any
}
const JobApplicantsSection: React.FC<JobApplicantsSectionProps> = ({ jobId, statusId, sortByField }) => {
  
  const appConfig                  = useAppConfigQuery('layout.list_type_applicants');
  const jobApplicantsQuery         = useApplicantsByJobAndStatusQuery( jobId, statusId );

  const listType    = appConfig.isSuccess ? appConfig.data as TLayoutListStyles : LAYOUT_APPLICANT_LIST;
  
  const applicantsByStatus = useMemo(() => {
    return jobApplicantsQuery?.data;
  }, [ jobApplicantsQuery?.data ]);

  // useEffect(() => {
  //   if( APP_DEBUG ) console.log('%c JobApplicantsSection.tsx | MOUNTED', 'color: yellow');
  //   return () => {
  //     if( APP_DEBUG ) console.log('%c JobApplicantsSection.tsx | UNMOINT cleanup', 'color: red');
  //   }    
  // }, [ ]);  

  return <>
    <QueryStateIndicator isError={jobApplicantsQuery.isError} isLoading={jobApplicantsQuery.isLoading} error={jobApplicantsQuery.error}></QueryStateIndicator>
    <section className='c-applicants-section-infinite'>
      {<ListApplicants data={applicantsByStatus} type={listType} groupedBy='status' />}
    </section>
  </>

};

interface JobApplicantsInfiniteSectionProps {
  jobId: string,
  statusId?: string,
  sortByField?: any
}
const JobApplicantsInfiniteSection: React.FC<JobApplicantsInfiniteSectionProps> = ({ jobId, statusId, sortByField }) => {
  
  const appConfig                  = useAppConfigQuery('layout.list_type_applicants');
  const jobApplicantsInfiniteQuery = useApplicantsByJobAndStatusInfiniteQuery( jobId, statusId );

  const listType    = appConfig.isSuccess ? appConfig.data as TLayoutListStyles : LAYOUT_APPLICANT_LIST;
  
  const applicantsByStatus = useMemo(() => {
    return jobApplicantsInfiniteQuery?.data;
  }, [ jobApplicantsInfiniteQuery?.data ]);

  const onScrollEnd = useCallback(( ev: any ) => {
    if( jobApplicantsInfiniteQuery.isFetchingNextPage ) return;

    jobApplicantsInfiniteQuery.fetchNextPage().then(() => {
      ev.target.complete()
    });
  }, [ jobApplicantsInfiniteQuery.data, jobApplicantsInfiniteQuery.isFetchingNextPage ]);

  useEffect(() => {
    if( APP_DEBUG ) console.log('%c JobApplicantsInfiniteSection.tsx | MOUNTED', 'color: yellow');
    return () => {
      if( APP_DEBUG ) console.log('%c JobApplicantsInfiniteSection.tsx | UNMOINT cleanup', 'color: red');
    }    
  }, [ ]);

  return <>
    <QueryStateIndicator isError={jobApplicantsInfiniteQuery.isError} isLoading={jobApplicantsInfiniteQuery.isLoading} error={jobApplicantsInfiniteQuery.error}></QueryStateIndicator>
    <section className='c-applicants-section-infinite'>
      {jobApplicantsInfiniteQuery.isSuccess && <ListApplicants data={applicantsByStatus} type={listType} groupedBy='status' />}
      {jobApplicantsInfiniteQuery.hasNextPage && <IonInfiniteScroll onIonInfinite={onScrollEnd}>
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>}
    </section>
  </>

};

interface JobApplicantsProps extends RouteComponentProps<{
  id: string;
  status?: string;
}>{}

const JobApplicants: React.FC<JobApplicantsProps> = ({ match }) => {
  const jobId = match.params.id;

  // Tracking
  //  
  const tracking         = useAppTracking();

  // Queries
  //
  const navStatusListQuery = useNavStatusListQuery('job');
  const navStatusListItems = useMemo(() => {
    return navStatusListQuery?.data;
  }, [navStatusListQuery?.data]);

  // Get Status Slug
  //
  const statusSlug = useMemo(() => {
    return match.params.status ? match.params.status : STATUS_SLUG_ALL;
  }, [ match.params ]);

  // Get Status ID
  // because of status slug
  //
  const statusId = useMemo(() => {
    const status = navStatusListItems?.find(( stat ) => { return stat.displayname === statusSlug });
    
    if( status ) return status.id;

    return navStatusListItems && navStatusListItems.length > 0 ? 
           navStatusListItems[0].id : 
           undefined;

  }, [ statusSlug, navStatusListItems ]);

  // get Job
  // 
  const jobQuery         = useJobQuery( jobId );
  const jobTitle         = useMemo(() => {
    if( !jobQuery.data ) return;
    return getCardViewDetail('jobReqTitle', jobQuery.data.jobReqInfo.cardViews);
  }, [ jobQuery.data ]);

  return (
    <IonPage className='v-job-applicants u-page-above-menu'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref='/'></IonBackButton>
          </IonButtons>
          <IonTitle>{ !jobQuery.isSuccess ? 
            <IonSkeletonText animated={true} style={{ width: '50%', height: '1em' }}></IonSkeletonText> : 
            <IonRouterLink style={{color: 'inherit'}} routerLink={getPageUrl('job', { id: jobId })}>
              {jobTitle?.value}
            </IonRouterLink>
          }</IonTitle>       
          <IonButtons slot="end">
            <IonButton routerLink={getPageUrl('job', { id: jobId })}>
              <IonIcon icon={informationCircleOutline}></IonIcon>
            </IonButton>            
            {/* {jobApplicantsQuery.data?.job?.link && <IonButton target="_blank" href={jobApplicantsQuery.data?.job?.link}>
              <IonIcon icon={addOutline}></IonIcon>
            </IonButton>} */}
          </IonButtons>        
        </IonToolbar>
        <JobApplicantsToolbar jobId={jobId} statusId={statusId} />
      </IonHeader>
      <IonContent fullscreen>
        
        <JobApplicantsSection statusId={statusId} jobId={jobId} />
        {/* <JobApplicantsInfiniteSection statusId={statusId} jobId={jobId} sortByField={sortByField} /> */}

      </IonContent>   
    </IonPage>
  );
};

export default JobApplicants;
