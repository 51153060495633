import axios, { AxiosRequestConfig } from 'axios';
import { sleep } from '../utils/util';
import { UseQueryOptions, UseQueryResult, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { APP_DEBUG, EXPERIMENTAL_GET_STORAGE_DATA_FIRST, EXPERIMENTAL_GET_STORAGE_DATA_FIRST_DELAY, LAYOUT_STATUS_DISPLAY_TYPE_ID_APPLICANTS, LAYOUT_STATUS_DISPLAY_TYPE_ID_JOBS, QUERY_KEY_APPLICANT, QUERY_KEY_APPLICANTS, QUERY_KEY_JOB, QUERY_KEY_JOBS, QUERY_KEY_STATUS, QUERY_USE_PLACEHOLDER_DATA, STATUS_ID_ALL } from '../utils/const';
import { FAKE_ITEM_LIMIT, FAKE_RESPONSE_TIME } from './useFakeApi';
import { getQueryKey } from '../utils/helper';
import { useTranslation } from 'react-i18next';
import { ExtractStringKeys, PartialBy, WithPrefix } from '../utils/types';
import { TApplicants, TJob, TJobTotal, TJobStatus, TJobs, TStatus, TStatuses, TJobDetail, TApplicantDetail, TStatusSet, TApplicantComment } from '../types';
import { getStorageData, setStorageData } from '../services/storageService';

const MIDDLEWARE_BASE_URL    = 'https://dm-mas-app.azurewebsites.net';
const MIDDLEWARE_CUSTOMER_ID = 1;
const MIDDLEWARE_USER_ID     = "BA_BA00379";
const MIDDLEWARE_ENDPOINTS = {
  config:           `/customer/${MIDDLEWARE_CUSTOMER_ID}`,
  i18n:             '/db/i18n.json',
  
  status:           `/job-status-display/get-by-customer`,
  
  jobs:             `/job-requisition/all-status-displays?userId=${MIDDLEWARE_USER_ID}`,
  jobsByStatus:     `/job-requisition/status-display?userId=${MIDDLEWARE_USER_ID}`,

  job:              `/job-requisition/details`,

  applicant:        `/job-application/application-details`,
  'applicant.history': `/job-application-audit/get-history?applicationId=1722`,
  'applicant.comments': `/job-application-comment/get-comments?applicationId=1000961`,
  'applicant.comment.post': `/job-application-comment`,

  'applicant.status.post': `##NOT_DEFINED##`
} as const;

type TApiEndpointDefined = ExtractStringKeys<typeof MIDDLEWARE_ENDPOINTS>;
type TApiEndpoint = TApiEndpointDefined | WithPrefix<'/'>;
type TApiData = { [key: string]: any };
type TApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export async function middlewareApiRequest( 
  endpoint: TApiEndpoint, 
  data?: TApiData, 
  method: TApiMethod = 'GET', 
  baseURL: string = MIDDLEWARE_BASE_URL 
){  

  const _definedEndpoints: any = MIDDLEWARE_ENDPOINTS;
  const _method = endpoint.endsWith('.post') ? 'POST' : method;
  
  const requestPath = endpoint.startsWith('/') ? 
    endpoint : 
    (
      _definedEndpoints.hasOwnProperty(endpoint) ? 
      _definedEndpoints[ endpoint ] : 
      `/${endpoint}`
    );

  const axiosConfig: AxiosRequestConfig<any> = {
    method: _method,
    baseURL
  };
  
  // map data for axios request 
  // with right method
  //
  if( data ) {

    if( _method === 'POST' ) {
      axiosConfig.data = data;
    }

    if( _method === 'GET' ) {
      // if not config
      if( endpoint !== 'config' ) {
        axiosConfig.params = data;
      }
    }

  }

  return axios({
    ...axiosConfig,
    url: requestPath,
  });
}

export const useMiddlewareApi = () => {
  const { i18n }    = useTranslation();
  
  const locale      = i18n.language ? `${i18n.language}_${i18n.language.toUpperCase()}` : 'de_DE';
  const customerId  = MIDDLEWARE_CUSTOMER_ID;
  
  const _request = async (
    endpoint: TApiEndpoint, 
    data?: TApiData, 
    method: TApiMethod = 'GET', 
    baseURL: string = MIDDLEWARE_BASE_URL      
  ) => {

    const requestData = {
      locale,
      customerId,
      ...data
    };

    const middlewareReponse = await middlewareApiRequest(endpoint, requestData, method, baseURL);

    if( APP_DEBUG ) console.log(
      'middleware response for endpoint:', `(${endpoint.toUpperCase()})`,
      requestData,
      middlewareReponse
    );

    return middlewareReponse.data;
  };

  const request = useCallback(_request, [ customerId, locale ]);

  return {
    request
  };
};

/*
 * UI Config
 */
export const fetchAppConfig = async () => {
  const request = await axios({
    method: 'GET',
    url: '/app.config.json'
  });
  return request.data;
};

export const useAppConfigQuery = ( property?: string ) => {
  
  const inAppConfigQueryOptions: UseQueryOptions = {
    queryKey: getQueryKey([
      ['app', 'config']
    ]), 
    refetchOnMount: false,
    refetchOnWindowFocus: false,    
  };

  if( property ) {
    inAppConfigQueryOptions['select'] = ( result: any ) => {
      const hasSegements = property.includes('.');

      if( hasSegements ) {
        const propertyArr: any[] = property.split('.');
        return propertyArr.reduce((a: any, b: string) => {
          if( a.hasOwnProperty(b) ) return a[b];
        }, result);
      }

      if( result.hasOwnProperty( property ) ) {
        return result[property];
      }

      return result;
    };
  }

  const inAppConfigQuery = useQuery({
    queryFn: async () => { 
      const appConfig = await fetchAppConfig();
      setStorageData('appConfig', appConfig);
      return appConfig;
    }, 
    ...inAppConfigQueryOptions
  });

  return inAppConfigQuery;
};

/*
 * Config
 */
type TConfigData = {
  address: string,
  countrycode: string,
  id: number,
  jobstatus: TJobStatus[]
  [key: string]: any
};

export const useConfigQuery = ( segment?: string ) => {
  
  const { request } = useMiddlewareApi();
  
  const configQueryOptions: UseQueryOptions = {
    queryKey: getQueryKey([
      'config'
    ]),     
    refetchOnMount: false
  };

  if( segment ) {
    configQueryOptions['select'] = ( result: any ) => {

      const configData: TConfigData = result;

      if( segment.includes('.')) {
        const propertyArr: any[] = segment.split('.');
        return propertyArr.reduce((a: any, b: string) => {
          if( a.hasOwnProperty(b) ) return a[b];
        }, configData);
      }

      if( configData.hasOwnProperty( segment ) ) {
        return configData[segment];
      }

      return configData;
    }
  }

  const [ askedStorage, setAskedStorage ] = useState(!EXPERIMENTAL_GET_STORAGE_DATA_FIRST);

  const configQuery = useQuery({
    queryFn: async () => {       
      if( EXPERIMENTAL_GET_STORAGE_DATA_FIRST && !askedStorage ) {
        setAskedStorage(true);
        const storageConfigData = getStorageData('config');
        setTimeout(() => configQuery.refetch(), EXPERIMENTAL_GET_STORAGE_DATA_FIRST_DELAY);
        return storageConfigData;
      }
      const configData = await request('config');
      setStorageData('config', configData);
      return configData; 
    }, 
    ...configQueryOptions
  }) as UseQueryResult<TConfigData, Error>;

  return configQuery;
};


/*
 * Language
 */
export const fetchI18n = async ( langCode?: string ) => {
  await sleep(FAKE_RESPONSE_TIME * 0.8); // fake delay
  const response = await axios({
    method: 'GET',
    url: '/db/i18n.json'
  });
  const data = response.data;
  return data;
};

export const useI18nQuery = ( langCode: string = 'de'  ) => {

  return useQuery({
    queryKey: getQueryKey([
      'l18n', 
      langCode
    ]), 
    queryFn: async () => {
      const i18nData = await fetchI18n( langCode );
      
      for (const [i18nKey, i18nValue] of Object.entries( i18nData )) {
        setStorageData('i18n.' + i18nKey, i18nValue);
      }

      return i18nData;
    }, 
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });    

};

/*
 * Status
 */
export const useStatusQuery = ( type?: 'status' | 'jobstatus' | 'sets' ) => { 
  
  const configQuery = useConfigQuery();

  // All Job Status
  //
  const allJobStatuses = useMemo(() => {    
    return configQuery.data?.jobstatus as TJobStatus[];
  }, [ configQuery.data, type ]);

  // All Status
  //  
  const allStatuses = useMemo(() => {        
    let _allStatuses: TStatuses = [];
    
    allJobStatuses?.forEach((jobStatusArr: any) => {
      jobStatusArr.jobapplicationstatuses.forEach(( _jas: TStatus ) => {
        if( _allStatuses.findIndex( ( _s ) => _s.appstatusid === _jas.appstatusid ) === -1 ) {
          _allStatuses.push(_jas);
        }
      });
    });

    return _allStatuses;
  }, [ allJobStatuses ]);

  // All StatusSets
  //   
  const allStatusSets = useMemo(() => {
    const _allStatusSets: TStatusSet[] = [];
    
    allStatuses.forEach(( status ) => {      
      const groupIndex = _allStatusSets.findIndex( ( _s: any ) => _s.appstatussetid === status.appstatussetid );
      const isInGroup = groupIndex !== -1;
      
      if( !isInGroup ) {
        _allStatusSets.push({
          appstatussetid: status.appstatussetid,
          appstatuses: [ status ]
        });
      }
      
      _allStatusSets[groupIndex]?.appstatuses.push(status);
    });

    return _allStatusSets;
  }, [ allStatuses ]);

  const collectStatuses = useCallback(async () => {    
    return allStatuses;
  }, [ configQuery.isSuccess, allStatuses ]);

  const collectStatusSets = useCallback(async () => {
    return allStatusSets;
  }, [ allStatusSets ]);
  
  const collectJobsStatuses = useCallback(async () => {
    return allJobStatuses;
  }, [ allJobStatuses ]);  

  const statusQuery = useQuery({
    queryKey: getQueryKey([
      QUERY_KEY_STATUS,
      type
    ]), 
    queryFn: async () => { 
      if( type === 'jobstatus' ) return await collectJobsStatuses() as TJobStatus[];
      if( type === 'sets' ) return await collectStatusSets() as TStatusSet[];
      return await collectStatuses() as TStatuses;
    },
    refetchOnMount: false,
    enabled: configQuery.isSuccess
  });

  return {
    ...statusQuery,
  };
}

export const useJobTotalsQuery = ( jobId: string ) => {

  const jobList               = useJobListQuery();

  const collectJobTotals = useCallback(() => {
    
    const jobTotals = jobList.data?.find((job: TJob) => {
      return job.jobReqId === jobId;
    })?.jobApplicationsTotals;

    return jobTotals;

  }, [jobId, jobList.data]);

  const jobTotalsQuery = useQuery({
    queryKey: getQueryKey([
      [
        QUERY_KEY_JOB,
        'totals',
      ], 
      jobId
    ]),
    queryFn: async () => {
      return collectJobTotals();
    },
    refetchOnMount: false,
    enabled: jobList.isSuccess, 
  });

  return jobTotalsQuery;

}

export const useNavStatusTotalsQuery = ( view: 'overview' | 'job', jobId?: string ) => {

  const statusList          = useStatusQuery('jobstatus');
  const jobList             = useJobListQuery();

  const collectOverviewStatusTotals = useCallback(() => {
    if( !statusList.data || !jobList.data) return;

    let statusTotals: TJobTotal[] = [];

    for(const status of statusList.data){
      
      // count jobs
      //        
      if( status.displaytype === LAYOUT_STATUS_DISPLAY_TYPE_ID_JOBS ) {
        
        for(const job of jobList.data) {
          
          const jobStatusTotals = job.jobApplicationsTotals;
          
          for(const total of jobStatusTotals) {

            if( total.displayStatusId !== status.id ) continue;

            const _statusTotal = statusTotals.find((_total) => {
              return (
                _total.displayStatusId === total.displayStatusId && 
                _total.displayStatusId === status.id
              )
            });
            
            if( !_statusTotal ) {    
              
              statusTotals.push({
                ...total,
                amount: jobList.data.length
              });

              break;
            } 

          }

        }

      }

      // count applicants
      //
      if( status.displaytype === LAYOUT_STATUS_DISPLAY_TYPE_ID_APPLICANTS ) {

        for(const job of jobList.data) {
          
          const jobStatusTotals = job.jobApplicationsTotals;
          
          for(const total of jobStatusTotals) {

            if( total.displayStatusId !== status.id ) continue;

            const _statusTotal = statusTotals.find((_total) => {
              return (
                _total.displayStatusId === total.displayStatusId && 
                status.id === _total.displayStatusId
              )
            });
            
            if( !_statusTotal ) {    
              
              statusTotals.push({
                ...total
              });

              continue;
            } 

            _statusTotal.amount += total.amount;              
          }

        }

        continue;
      }

    }

    return statusTotals as TJobTotal[];

  }, [ statusList.data, jobList.data ]);

  const collectJobStatusTotals = useCallback(() => {
    if( !statusList.data || !jobList.data || !jobId ) return;

    let statusTotals: TJobTotal[] = [];

    for(const job of jobList.data) {
      if( job.jobReqId === jobId && Array.isArray(job.jobApplications) ) {
        
        for(const status of statusList.data) {
          
          const _statusTotal = statusTotals.find((_total) => {
            return status.id === _total.displayStatusId
          });
          const applicantsInStatus = job.jobApplications.filter(_applicant => _applicant.applicableDisplayStatuses.includes(status.displayname));

          if( !_statusTotal ) {
            statusTotals.push({
              displayStatusId: status.id,
              defaultText: status.displayname,
              label: status.displaytext,
              amount: applicantsInStatus.length
            });        
          }
        }
        
        break; // exit loop because we found jobId
      }
    }

    return statusTotals as TJobTotal[];

  }, [ statusList.data, jobList.data, jobId ]);

  const statusTotalsQuery = useQuery({
    queryKey: getQueryKey(['navTotals', view, jobId]),
    queryFn: async () => {
      if( view === 'job' ) return collectJobStatusTotals();
      return collectOverviewStatusTotals();
    },
    refetchOnMount: false,
    enabled: jobList.isSuccess,  
  });

  return statusTotalsQuery;

}

export const useNavStatusListQuery = ( view: 'overview' | 'job' ) => {

  const statusQuery  = useStatusQuery('jobstatus');

  const overviewStatusList = useCallback( () => {    
    if( !statusQuery.data ) return;    
    return statusQuery.data.filter((overview) => {
      return overview.showinkpi;
    });
  }, [ statusQuery?.data ]);

  const jobStatusList = useCallback( () => {    
    if( !statusQuery.data ) return;    
    return statusQuery.data.filter((overview) => {
      return true;
    });
  }, [ statusQuery?.data ]);  

  const navStatusListQuery = useQuery({
    queryKey: getQueryKey(['navStatusList', view]),
    queryFn: async () => {
      if( view === 'overview' ) return overviewStatusList();
      return jobStatusList();
    },
    refetchOnMount: false,
    enabled: statusQuery.isSuccess
  });

  return navStatusListQuery;

}

export const postApplicantStatus = async ( postData?: any ) => {
  await sleep(FAKE_RESPONSE_TIME); // fake delay
  return false;
};

export const useOptimisticUpdate = () => {
  const queryClient = useQueryClient();

  const update = useCallback(async ( queryKey: any, setQueryData: ( oldData: any ) => void, ensureQueryFn?: () => Promise<any> ) => {
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ 
      queryKey
    });
    
    // Snapshot the previous data
    // if ensureFn isset:
    // make a query with this function
    // to ensure we have the data
    const prevData = !ensureQueryFn ? 
      queryClient.getQueryData( queryKey ) : 
      await queryClient.ensureQueryData({
        queryKey,
        queryFn: ensureQueryFn
      });
  
    // Optimistically update to the new value      
    queryClient.setQueryData(
      queryKey,
      setQueryData
    );
  
    return {
      prevData
    };  
  }, [ queryClient ]);

  const reset = useCallback(( queryKey: any, oldData: any ) => {
    queryClient.setQueryData(
      queryKey,
      oldData
    ); 
    return queryClient.getQueryData( queryKey );
  }, [ queryClient ]);

  return {
    update,
    reset
  }
}

export const useApplicantStatusMutation = ( filter?: any ) => {
  const queryClient = useQueryClient();
  
  const statusQuery = useStatusQuery();

  const optimisticUpdate = useOptimisticUpdate();

  const findStatusById = useCallback(( statusId: any ) => {
    if( !statusQuery.data ) return {};
    return statusQuery.data.find(( status: any ) => {
      return status.id === statusId;
    });
  }, [ statusQuery.data ]);

  /**
   * Hier bei den Query Keys aufpassen 
   * wegen typing!!!! sind immer strings! 
   * oder sollten immer dahin convertiert werden
   */

  const statusMutation = useMutation({
    mutationKey: getQueryKey([
      [
        'applicant',
        QUERY_KEY_STATUS,
      ]
    ]),
    mutationFn: postApplicantStatus,
    onMutate: async ( applicantStatusData: any ) => {

      // be sure that keys are strings!
      //
      const applicantId = applicantStatusData.applicant.id + '';      
      const statusId_NEW = applicantStatusData.status.id + '';
      let statusId_OLD = null;

      const jobId = applicantStatusData.applicant.jobId ? applicantStatusData.applicant.jobId + '' : undefined;
      const statusObj: any = findStatusById( applicantStatusData.status.id );

      // Snapshot the previous applicant
      //    
      const {
        prevData: previousApplicantData
      } = await optimisticUpdate.update(
        getQueryKey([
          QUERY_KEY_APPLICANTS, 
          applicantId
        ]),
        (oldApplicantData: any) => {  
          return {
            ...oldApplicantData,
            status: {
              ...oldApplicantData?.status,
              ...statusObj,
              id: statusId_NEW
            }
          }
        },
        // @TODO
        // we want to make a fetch if no applicant data available
        // because we need the old statusId from the user
        // maybe get through another way
        // to avoid fetch
        async () => {
          // return undefined;
          // return await fetchApplicants( applicantId );
        }
      ); 
      
      // be sure that keys are strings!
      // 
      statusId_OLD = previousApplicantData?.status.id + '';      

      // Snapshot the previous applicant status value
      // ALL
      const {
        prevData: previousApplicantStatusData_ALL
      } = await optimisticUpdate.update(
        getQueryKey([
          [
            QUERY_KEY_APPLICANTS,
            QUERY_KEY_JOB,
            QUERY_KEY_STATUS,
            'infinite'
          ], 
          jobId,
          undefined         
        ]),
        // [ 'applicants.job.status.infinite', jobId, undefined ],
        (oldApplicantStatusData: any) => {
          
          if( !oldApplicantStatusData ) return oldApplicantStatusData;

          const newApplicantStatusData = oldApplicantStatusData?.pages.map(( page: any ) => {
            return page.map(( applicant: any ) => {
              if( applicant.id == applicantId ) {
                return {
                  ...applicant,
                  status: statusObj
                }
              }
              return applicant;
            })
          });

          return {
            ...oldApplicantStatusData,
            pages: newApplicantStatusData
          }
        }
      );
      
      // Snapshot the previous applicant status value
      // NEW
      // const {
      //   prevData: previousApplicantStatusData_NEW
      // } = await optimisticUpdate.update(
      //   [ 'applicants.job.status.infinite', jobId, statusId_NEW ],
      //   (oldApplicantStatusData: any) => {
          
      //     if( !oldApplicantStatusData ) return oldApplicantStatusData;

      //     const newApplicantStatusData = oldApplicantStatusData?.pages.map(( page: any, pageIndex: number ) => {
            
      //       if( pageIndex == 0 ) {              
      //         page.unshift(previousApplicantData);
      //       }

      //       return page;
      //     });

      //     return {
      //       ...oldApplicantStatusData,
      //       pages: newApplicantStatusData
      //     }
      //   }
      // );      
      
      // Snapshot the previous applicant status value
      // OLD
      const {
        prevData: previousApplicantStatusData_OLD
      } = await optimisticUpdate.update(
        getQueryKey([
          [
            QUERY_KEY_APPLICANTS,
            QUERY_KEY_JOB,
            QUERY_KEY_STATUS,
            'infinite'
          ], 
          jobId,
          statusId_OLD        
        ]),
        (oldApplicantStatusData: any) => {
          
          if( !oldApplicantStatusData ) return oldApplicantStatusData;

          const newApplicantStatusData = oldApplicantStatusData?.pages.map(( page: any ) => {
            return page.filter(( applicant: any ) => { return applicant.id != applicantId });
          });

          return {
            ...oldApplicantStatusData,
            pages: newApplicantStatusData
          }
        }
      );
      
      // Snapshot the jobs with applicants by status value
      // OLD      
      const {
        prevData: previousJobsApplicantsStatusData_OLD
      } = await optimisticUpdate.update(
        getQueryKey([
          [
            QUERY_KEY_JOBS,
            QUERY_KEY_APPLICANTS,
            QUERY_KEY_STATUS,
          ], 
          jobId,
          statusId_OLD       
        ]),
        (oldJobsApplicantsStatusData: any) => {

          const newJobsApplicantsStatusData = oldJobsApplicantsStatusData?.map(( job: any ) => {
            const newApplicants = job.applicants.filter((applicant: any ) => {
              return applicant.id != applicantId
            });
            
            return {
              ...job,
              applicants: newApplicants
            }
          }).filter(( job: any ) => { return job.applicants.length > 0 });

          // // Infinite:
          //
          // const newJobsApplicantsStatusData = oldJobsApplicantsStatusData?.pages.map(( page: any ) => {
          //   return page.filter(( applicant: any ) => { return applicant.id != applicantId });
          // });          

          return newJobsApplicantsStatusData;

          // // Infinite:
          // return {
          //   ...oldJobsApplicantsStatusData,
          //   pages: newJobsApplicantsStatusData
          // }
        }
      );      
  
      // Return a context object with the snapshotted value
      return { 
        previousApplicantData,
        previousApplicantStatusData_ALL,
        // previousApplicantStatusData_NEW,
        previousApplicantStatusData_OLD,
        previousJobsApplicantsStatusData_OLD
      }
    },
    onSuccess: ( setApplicantStatusResponse, postData ) => {
      // console.log( setApplicantStatusResponse );
      return setApplicantStatusResponse;
    },
    onError: ( error, applicantStatusData, context ) => {
      const statusObj: any = findStatusById( applicantStatusData.status.id );

      // queryClient.setQueryData(
      //   ['applicants', applicantStatusData.applicant.id + ''], 
      //   context?.previousApplicantData
      // );  
      optimisticUpdate.reset(
        getQueryKey([
          QUERY_KEY_APPLICANTS,
          applicantStatusData.applicant.id       
        ]),
        context?.previousApplicantData        
      );

      queryClient.setQueryData( // ALL APPLICANTS BY JOB AND STATUS
        getQueryKey([
          [
            QUERY_KEY_APPLICANTS,
            QUERY_KEY_JOB,
            QUERY_KEY_STATUS,
            'infinite'
          ], 
          applicantStatusData.applicant.jobId,
          undefined      
        ]),        
        context?.previousApplicantStatusData_ALL
      );     

      if( applicantStatusData.status.id ) {
        // queryClient.setQueryData( // NEW
        //   ['applicants.job.status.infinite', applicantStatusData.applicant.jobId + '', applicantStatusData.status.id + ''],
        //   context?.previousApplicantStatusData_NEW
        // );
      }

      queryClient.setQueryData( // "OLD" APPLICANTS BY JOB AND STATUS
        getQueryKey([
          [
            QUERY_KEY_APPLICANTS,
            QUERY_KEY_JOB,
            QUERY_KEY_STATUS,
            'infinite'
          ], 
          applicantStatusData.applicant.jobId,
          context?.previousApplicantData.status.id     
        ]),
        context?.previousApplicantStatusData_OLD
      ); 
      
      queryClient.setQueryData( // "OLD" JOB APPLICANTS BY STATUS
        getQueryKey([
          [
            QUERY_KEY_JOBS,
            QUERY_KEY_APPLICANTS,
            QUERY_KEY_STATUS,
          ], 
          context?.previousApplicantData.status.id     
        ]),
        context?.previousJobsApplicantsStatusData_OLD
      );

      return error;
    },
    onSettled: (data, error, variables, context) => {
      // Mark Data as Stale
      // 
      // queryClient.invalidateQueries({ 
      //   queryKey: ['server.tickets', serverAddress] 
      // });
    },    
  });

  return statusMutation;
}

export const useApplicantCommentMutation = ( filter?: any ) => {
  const { request } = useMiddlewareApi();

  const optimisticUpdate = useOptimisticUpdate();

  const commentMutation = useMutation({
    mutationKey: getQueryKey([
      [QUERY_KEY_APPLICANT, 'comment']
    ]),
    mutationFn: async ( applicantStatusData: TApplicantComment ) => {
      const applicantComment = await request('applicant.comment.post', {
        ...applicantStatusData,
        // userId: MIDDLEWARE_USER_ID,        
      });
      return applicantComment;
    },
    onMutate: async ( applicantStatusData: TApplicantComment ) => {

      // Snapshot the previous applicant
      //    
      const {
        prevData: previousApplicantData
      } = await optimisticUpdate.update(
        getQueryKey([
          QUERY_KEY_APPLICANTS, 
          applicantStatusData.applicationId
        ]),
        (oldApplicantData: any) => {  
          // @TODO
          // update data here
          // 
        }
      ); 
  
      // Return a context object with the snapshotted value
      return { 
        previousApplicantData
      }
    },
    onSuccess: ( setApplicantStatusResponse, postData ) => {
      return setApplicantStatusResponse;
    },
    onError: ( error, applicantStatusData, context ) => {
      optimisticUpdate.reset(
        getQueryKey([
          QUERY_KEY_APPLICANTS,
          applicantStatusData.applicationId      
        ]),
        context?.previousApplicantData        
      );

      return error;
    },
    onSettled: (data, error, variables, context) => {
      // Mark Data as Stale
      // 
      // queryClient.invalidateQueries({ 
      //   queryKey: ['server.tickets', serverAddress] 
      // });
    },    
  });

  return commentMutation;
}

/*
 * Job (from jobs arr)
 */
export const useJobQuery = ( jobId: string ) => {
  const jobsQuery   = useJobListQuery();

  const collectJob = useCallback(async () => {
    return jobsQuery?.data?.find((_j) => _j.jobReqId === jobId);
  }, [ jobsQuery.data ]);

  return useQuery({
    queryKey: getQueryKey([
      QUERY_KEY_JOB, 
      jobId
    ]),
    queryFn: async () => {
      return await collectJob();
    },
    enabled: jobsQuery.isSuccess
  });
}

/*
 * Job Detail
 */
export const useJobDetailQuery = ( jobId: string ) => {
  const { request } = useMiddlewareApi();

  return useQuery({
    queryKey: getQueryKey([
      QUERY_KEY_JOB, 
      jobId,
      'detail'
    ]),
    queryFn: async () => {
      return await request('job', {
        jobReqId: jobId
      }) as TJobDetail;
    },
  });
}

/*
 * Jobs List
 */
export const useJobListQuery = () => {
  const { request } = useMiddlewareApi();
  return useQuery({
    queryKey: getQueryKey([
      'jobList',
    ]), 
    queryFn: async () => {
      return await request('jobs') as TJobs;
    },
    refetchOnMount: false
  });
}

/*
 * Job Applicants
 */
export const useJobApplicantsQuery = ( jobId: string ) => {
  const jobListQuery    = useJobListQuery();

  const collectApplicants = useCallback( async () => {
    return jobListQuery?.data?.find((job) => job.jobReqId === jobId)?.jobApplications;
  }, [ jobListQuery.data ]);

  const jobApplicantsQuery = useQuery({
    queryKey: getQueryKey([
      [
        QUERY_KEY_JOB,
        QUERY_KEY_APPLICANTS,
      ], 
      jobId
    ]),
    queryFn: async () => {
      return collectApplicants();
    },
    enabled: jobListQuery.isSuccess
  });

  return {
    ...jobApplicantsQuery
  }
}

// Infinite Version
//
export const useJobApplicantsInfiniteQuery = ( jobId: string ) => {
  // const { request }   = useMiddlewareApi();

  // const jobApplicantsQuery = useInfiniteQuery({
  //   queryKey: getQueryKey([
  //     [
  //       QUERY_KEY_JOB,
  //       QUERY_KEY_APPLICANTS,
  //       'infinite'
  //     ], 
  //     jobId
  //   ]),
  //   queryFn: async ({ pageParam = 0 }) => {
  //     return await fetchJobApplicants( jobId, FAKE_ITEM_LIMIT, pageParam * FAKE_ITEM_LIMIT );
  //   },
  //   initialPageParam: 0,
  //   getNextPageParam: ( _lastPage, allPages ) => {
  //     const nextPage = _lastPage.applicants.length === FAKE_ITEM_LIMIT ? allPages.length : undefined;
  //     return nextPage;
  //   },
  // });

  // return {
  //   ...jobApplicantsQuery
  // }
}

/*
 * Single Applicant
 */
export const useApplicantQuery = ( id: string ) => {
  const { request }   = useMiddlewareApi();

  return useQuery({
    queryKey: getQueryKey([
      QUERY_KEY_APPLICANTS,
      id
    ]),
    queryFn: async () => {
      return await request('applicant', {
        applicationId: id
      }) as TApplicantDetail; 
    }
  });
}

// @TODO
export const useApplicantCommentsQuery = (id: string) => {
  return null;
}

// @TODO
export const useApplicantPostCommentQuery = (id: string) => {
  return null;
}

// @TODO
export const useApplicantHistoryQuery = (id: string) => {
  return null;
}

/*
 * Applicants by Job and Status
 */

export const useApplicantsByJobAndStatusQuery = ( jobId: string, statusId?: string | number ) => {
  
  const statusQuery   = useStatusQuery('jobstatus');
  const jobsQuery     = useJobListQuery();

  const collectApplicants = useCallback(async () => {

    if( !jobsQuery.data || !statusQuery.data ) return;

    let _applicants: TApplicants = [];
    
    for( const job of jobsQuery.data ) {
      if( job.jobReqId === jobId && Array.isArray(job.jobApplications) ) {
        if( !statusId ) {
          _applicants.push(...job.jobApplications);
          break;
        }
        const jobStatus = statusQuery?.data?.find((jobStatus) => jobStatus.id === statusId);
        for(const applicant of job.jobApplications){       
          if( applicant.applicableDisplayStatuses.includes(jobStatus?.displayname) ) {
            _applicants.push(applicant);
          }
        }
        break;
      }
    }
    
    return _applicants;
  }, [ jobsQuery.data, statusQuery.data, jobId, statusId ]);

  return useQuery({
    queryKey: getQueryKey([
      [
        QUERY_KEY_APPLICANTS,
        QUERY_KEY_JOB,
        QUERY_KEY_STATUS,
      ], 
      jobId,
      statusId
    ]),
    queryFn: async () => {
      return collectApplicants();
    },
    refetchOnMount: false,
    enabled: jobsQuery.isSuccess && statusQuery.isSuccess
  });
};

// Infinite Version
//
export const useApplicantsByJobAndStatusInfiniteQuery = ( jobId: string, statusId?: string | number ) => {
  
  return useInfiniteQuery({
    queryKey: getQueryKey([
      [
        QUERY_KEY_APPLICANTS,
        QUERY_KEY_JOB,
        QUERY_KEY_STATUS,
        'infinite'
      ], 
      jobId, 
      statusId
    ]),
    queryFn: async ({ pageParam = 0 }) => {
      //
      // @TODO
      //
      return [];
    },
    initialPageParam: 0,
    getNextPageParam: ( _lastPage, allPages ) => {
      const nextPage = _lastPage.length === FAKE_ITEM_LIMIT ? allPages.length : undefined;
      return nextPage;
    },
    refetchOnMount: false
  });  

};

/*
 * Jobs by Status
 */
type TJobsByStatusResultDataPartial = PartialBy<TJob, 'jobApplications' | 'jobApplicationsTotals'>;
type TJobsByStatusResultData = TJobsByStatusResultDataPartial[];
type TJobsByStatusResult = {
  data: TJobsByStatusResultData,
  displayName: string,
  displayOrder: number,
  displayText: string,
  id: number,
  nextResultSet?: any 
}
export const useJobsByStatusQuery = ( statusId: string | number ) => {
  const { request }   = useMiddlewareApi();
  const queryClient   = useQueryClient();

  const collectPlaceholderApplicants = useCallback((jobList: TJobs, status: TJobStatus ) => {
    const result: TJobsByStatusResult = {
      data: [],
      displayName: status.displayname,
      displayOrder: status.displayorder,
      displayText: status.displaytext,
      id: status.id
    };

    for(const job of jobList) {
      for( const applicant of job.jobApplications ) {
        
        if( applicant.applicableDisplayStatuses.includes(status.displayname) ) {

          const jobExists = result.data.find((r) => r.jobReqId === job.jobReqId);

          if( !jobExists ) {
            result.data.push({
              jobReqId: job.jobReqId,
              jobApplications: [applicant],
              jobReqInfo: job.jobReqInfo
            });

            continue;
          }

          jobExists?.jobApplications?.push(applicant);

        }

      }
    }

    return result;    
  }, []);

  const collectPlaceholderJobs = useCallback(( jobList: TJobs, status: TJobStatus ) => {
    const result: TJobsByStatusResult = {
      data: [],
      displayName: status.displayname,
      displayOrder: status.displayorder,
      displayText: status.displaytext,
      id: status.id
    };

    result.data.push(...jobList);

    return result;    
  }, []);  

  const placeholderData = useCallback(() => {
    if( !QUERY_USE_PLACEHOLDER_DATA ) return;
    
    const jobList     = queryClient.getQueryData(['jobList']) as TJobs;
    const statusQuery = queryClient.getQueryData(getQueryKey([QUERY_KEY_STATUS, 'jobstatus'])) as TJobStatus[];

    if( !jobList || !statusQuery ) return;

    const status = statusQuery.find(_s => _s.id === statusId);

    if( !status ) return;

    if( status.displaytype === LAYOUT_STATUS_DISPLAY_TYPE_ID_JOBS ) {
      return collectPlaceholderJobs(jobList, status);
    }
    return collectPlaceholderApplicants(jobList, status);

  }, [ queryClient, statusId ]);

  return useQuery({
    queryKey: getQueryKey([
      [
        QUERY_KEY_JOBS,
        QUERY_KEY_STATUS,
      ], 
      statusId
    ]),
    queryFn: async () => {
      const jobsByStatus: TJobsByStatusResult = await request('jobsByStatus', {
        'id': statusId
      });
      return jobsByStatus;
    },
    refetchOnMount: false,
    placeholderData,    
  });  
};