import { IonSegment, IonSegmentButton, useIonToast } from '@ionic/react';
import './ApplicantPreselection.css';
import { TOAST_OPTIONS_DEFAULT } from '../../utils/const';
import { sleep } from '../../utils/util';
import { useCallback, useRef, useState } from 'react';

interface ApplicantPreselectionProps {
  initialValue: string | null,
  className?: string
};

const ApplicantPreselection: React.FC<ApplicantPreselectionProps> = ({ ...props }) => {
  const initialValue = props.initialValue;

  const [ presentPreselectionToast ] = useIonToast();
  const preselectionElement = useRef<any>();
  const [ currentPreselection, setCurrentPreselection ] = useState(!initialValue ? '' : initialValue);
  const changePreselection = useCallback(async (value: any) => {
    preselectionElement.current.disabled = true;
    
    await sleep(500); // Fake delay
    
    setCurrentPreselection(value);
    preselectionElement.current.disabled = false;
    
    presentPreselectionToast({
      ...TOAST_OPTIONS_DEFAULT,
      message: 'Auswahl gespeichert',
      color: 'success'
    });
  }, [preselectionElement, setCurrentPreselection]);
  
  return (
    <IonSegment ref={preselectionElement} mode='ios' value={currentPreselection} onIonChange={(e) => changePreselection(e.detail.value)} className='c-preselection'>
      <IonSegmentButton value=''>Keine</IonSegmentButton>
      <IonSegmentButton value='A'>A</IonSegmentButton>
      <IonSegmentButton value='B'>B</IonSegmentButton>
      <IonSegmentButton value='C'>C</IonSegmentButton>
    </IonSegment>    
  );

};

export default ApplicantPreselection;