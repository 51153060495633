import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonModal, IonRow, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';

import './ModalApplicantDocuments.css';
import { APP_COMPONENT_DOCS_USE_BACK_ICON, PDF_WORKER_SRC } from "../utils/const";
import QueryStateIndicator from "../components/QueryStateIndicator";

/**
 * @TODO REFACTOR!
 * Not good because here dom elements are created
 * and a canvas. 
 * I just hide all dom elements in css except the canvas here
 * find a way to just render the canvas and nothing else
 * for performance
 */

interface ModalApplicantDocumentsProps {
  trigger: string
};

const ModalApplicantDocuments: React.FC<ModalApplicantDocumentsProps> = ({ ...props }) => {
  const trigger         = props.trigger;
  
  const modal           = useRef<HTMLIonModalElement>(null);

  const [numPages, setNumPages]       = useState(0);
	const [pageNumber, setPageNumber]   = useState(1);
  const [isPDFLoaded, setIsPDFLoaded] = useState(false);
  const [isPDFError, setIsPDFError]   = useState(false);

  const applicant                     = null;
  const pdfFilePath                   = '/db/lebenslauf.pdf';

	const onDocumentLoadSuccess = ( pdf: any ) => {
    setNumPages( pdf?._pdfInfo?.numPages );
    setIsPDFLoaded( true );
	};

	const onDocumentLoadError = ( error: any ) => {
    setIsPDFError( true );
    console.error( error );
	};  

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = PDF_WORKER_SRC.replace('{:version}', pdfjs.version);
  }, []);

  return (
    <IonModal className="c-modal c-modal--docs" ref={modal} trigger={trigger}>
      
      <IonHeader className="c-modal__header">
        <IonToolbar>
          <IonButtons slot="start">
            {APP_COMPONENT_DOCS_USE_BACK_ICON && <IonButton onClick={() => { modal.current?.dismiss() }}>
              <IonIcon src={arrowBackOutline}></IonIcon>
            </IonButton>}
          </IonButtons>
          <IonTitle className="c-modal__title">Dokument(e)</IonTitle>                  
          <IonButtons slot="end">
            {!APP_COMPONENT_DOCS_USE_BACK_ICON && <IonButton onClick={() => { modal.current?.dismiss() }}>
              <IonIcon src={closeOutline}></IonIcon>
            </IonButton>}
          </IonButtons>          
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="c-modal__content">
        <IonGrid>
          <IonRow>
            <IonCol>
              {/* <object data="/db/testdocument.pdf" type="application/pdf" width="100%" height="100%">
                <p>Es wird kein PDF angezeigt? <a href="/db/testdocument.pdf">PDF Herunterladen.</a></p>
              </object> */}
              <QueryStateIndicator isError={isPDFError} isLoading={!isPDFLoaded}></QueryStateIndicator>
              <Document file={pdfFilePath} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid class="ion-padding-bottom">
          {(isPDFError || isPDFLoaded) && <IonButton color="light" expand="block" href={pdfFilePath} download="download" target="_blank">
            Download PDF
          </IonButton>}
        </IonGrid>
      </IonContent>

    </IonModal>
  );

};

export default ModalApplicantDocuments;