import { IonButton, IonButtons, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, useIonRouter } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useCallback, useEffect, useMemo, useRef, useState} from "react";

import './Menu.css';

import { getPageUrl } from "../utils/urls";

import { APP_API_VERSION, APP_DATASOURCES, APP_USE_DATASOURCES, APP_VERSION } from "../utils/const";
import { useTranslation } from "react-i18next";
import { useConfigQuery } from "../hooks/useApi";
import { getStorageData } from "../services/storageService";

const Menu = ({ ...props }: any) => {  

  const configDataPages = {isSuccess: true, data: null};

  const { t, i18n } = useTranslation();

  const router = useIonRouter();
  const menuRef = useRef<HTMLIonMenuElement | null>(null);

  const closeMenu = useCallback(() => {
    menuRef.current?.close();
  }, []);

  const changeLanguage = useCallback(( langCode: string ) => {
    i18n.changeLanguage( langCode );
  }, [ i18n ]);

  const supportedLangs = useMemo(() => {
    const _s: any = i18n.options?.supportedLngs;
    return [
      ..._s
    ]?.filter((_l) => _l !== 'cimode');
  }, [i18n.options?.supportedLngs]);

  return (
    <IonMenu id="l-menu" ref={menuRef} side="start" swipeGesture={false} type="overlay" contentId="l-main">
      <IonContent forceOverscroll={false}>       
        <div className="l-menu__header">
          <h1 style={{marginTop: 0, marginBottom: 0}}>
            Roger Klotz
          </h1>
          <IonMenuButton>
            <IonIcon icon={closeOutline}/>
          </IonMenuButton>           
        </div>

        <div className="l-menu__content">
          <IonList lines='full'>
            
            {/* Normal Pages */}
            {(() => {
              if( !configDataPages.data ) return;

              const _pages = [];

              for (const [key, value] of Object.entries( configDataPages.data )) {
                if( !value ) return;

                const pageConfigData: any = value;
                _pages.push(
                  <IonItem key={`menu-page-route--${key}`} routerLink={pageConfigData.route} onClick={closeMenu}>
                    <IonLabel>{ t('pages.' + key + '.title') }</IonLabel>
                  </IonItem>               
                );               

              }

              return _pages;
            })()}
            
            <IonItem routerLink={getPageUrl('license')} onClick={closeMenu}>
              <IonLabel>{ t('pages.license.title') }</IonLabel>
            </IonItem>
            <IonItem routerLink={getPageUrl('overview_jobs')} onClick={closeMenu}>
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonList>
          
          <IonList lines="none" style={{marginTop: -10}}>
            <IonItem>
              <IonLabel style={{marginTop: 0, marginBottom: 0}} color='primary'>
                <strong style={{marginRight: '0.5em'}}>{ t('language') }:</strong>
              </IonLabel>
            </IonItem>
            <IonItem>
              {supportedLangs?.map((lang: string) => {
                return <IonButton key={`c-btn--lang-switch-${lang}`} color={i18n.language === lang ? 'primary' : 'light'} 
                                  className="c-btn c-btn--lang-switch" 
                                  size="small" 
                                  onClick={() => { changeLanguage(lang) }}>
                  {lang.toUpperCase()}
                </IonButton>;
              })}
            </IonItem> 
            <IonItem lines="none">
              <IonLabel>
                App-Version: {APP_VERSION}<br />
                API-Version: {APP_API_VERSION}<br />
              </IonLabel>
            </IonItem>           
          </IonList>
          
        </div>
      </IonContent>
      <IonFooter>

      </IonFooter>
    </IonMenu>
  );
}

const DataSource: React.FC<{}> = ( {...props} ) => {

  const [ datasource, setDatasource ] = useState('default');

  const onDatasetChange = ( ds: string ) => {
    window.location.href = '/?datasource=' + ds;
  };

  useEffect(() => {
    const _TEMP_DEFINE_DATASOURCE = async () => {
      const datasource = await getStorageData("datasource");
      setDatasource( datasource );
    }; _TEMP_DEFINE_DATASOURCE();
  }, [ ]);

  return <>
    <div className='ion-margin-vertical' style={{height: 1, width: '100%', backgroundColor: '#CCC'}}></div>
    Datasource: {datasource}<br />
    <IonButton key={`datasource-switch--default`} onClick={() => onDatasetChange('default')} color={datasource === 'default' ? 'primary' : 'light'}>default</IonButton>
    {APP_DATASOURCES.map(( ds: string ) => {
      return <IonButton key={`datasource-switch--${ds}`} onClick={() => onDatasetChange(ds)} color={datasource === ds ? 'primary' : 'light'}>
        {ds}
      </IonButton>
    })}
  </>
}

export default Menu;