import { checkmarkOutline, closeOutline, ellipsisHorizontalOutline } from "ionicons/icons";
import { QUERY_KEY_SEPERATOR, STATUS_ID_ALL, STATUS_SLUG_ALL } from "./const";
import { isObject, sortArrayBy } from "./util";
import { TApplicants, TStatus, TStatuses } from "../types";

export function getIoniconByString( iconString: undefined | 'check' | 'cancel' | 'close' | 'more'){
  switch (iconString) {
    case 'check':
      return checkmarkOutline;
    case 'cancel':
      return closeOutline;
    case 'close':
      return closeOutline;    
    case 'more':
      return ellipsisHorizontalOutline;
    default:
      return undefined;
  }  
}

export function getQueryKey( params: any[] ): any[]{
  let queryKeyString = '';
  const queryKeyArr: any[] = [];

  params.forEach(( param ) => {
    let _queryKeyString;
    
    if( !param) return;

    if( Array.isArray( param ) ) {
      _queryKeyString = param.join( QUERY_KEY_SEPERATOR );
    } else {
      _queryKeyString = (param + ''); // cast to string
    }

    queryKeyString += ( queryKeyString !== '' ? QUERY_KEY_SEPERATOR : '' ) + _queryKeyString;
    queryKeyArr.push( _queryKeyString );    
  });

  return queryKeyArr;
}

export function groupApplicantsBy( data: any[], field: string, onlyGroupIds?: any[] ){
  let groupedBy: any = [];
  
  const canGroupByField = data.every(a => a.hasOwnProperty(field) && a.hasOwnProperty('id'));
  const canOnlyGroupIds = onlyGroupIds && onlyGroupIds.length > 0;

  if( !canGroupByField ) return data;

  if( canOnlyGroupIds ) {
    groupedBy.push({
      id: field === 'status' ? STATUS_ID_ALL : -1,
      sort: -1,
      applicants: []
    });    
  }
  
  data.forEach((d) => {
    let dataField = d[field];
    
    if( !Array.isArray(dataField) && !isObject(dataField) ) return;
    
    if( Array.isArray(dataField) ) dataField = dataField[0];

    const isInArray = groupedBy.some((elem: any) => elem.id === dataField.id);
    
    if( canOnlyGroupIds ) {

      if( !isInArray && onlyGroupIds.includes( dataField.id ) ) {
        groupedBy.push({
          ...dataField,
          applicants: []
        });
      }

    }

    if( !canOnlyGroupIds && !isInArray ) {
      
      groupedBy.push({
        ...dataField,
        applicants: []
      });

    }

    const currentGroupIndex = groupedBy.findIndex((g: any) => { return g.id === dataField.id });
    
    if( currentGroupIndex !== -1 ) {
    
      groupedBy[currentGroupIndex].applicants.push(d);
    
    } else if( canOnlyGroupIds ) {
    
      const allGroupIndex = groupedBy.findIndex((g: any) => { return g.id == STATUS_ID_ALL });
      if( allGroupIndex !== -1 ) {
        groupedBy[allGroupIndex].applicants.push(d);
      }

    }

  });

  if( canOnlyGroupIds ) {
    groupedBy = sortArrayBy(groupedBy);
  }

  if( groupedBy.length > 0 ) return groupedBy;

  return data;
}

export function mapJobApplicantStatusMetrics(jobId: string, applicants: TApplicants, status: TStatuses ){
  const jobApplicants = applicants.filter(( applicant ) => { return applicant.job.id == jobId });
  const jobApplicantsStatus: TStatuses = status?.map(( stat ) => {    
    return {
      ...stat,
      count: jobApplicants.filter(( applicant ) => { 
        // if array:
        //
        if( Array.isArray(applicant.status) ) {
          return applicant.status.find(( _aStatus: TStatus ) => {
            return _aStatus.id == stat.id;
          }) ? true : false;
        }

        // no array
        return applicant.status.id == stat.id
      }).length
    }
  });

  jobApplicantsStatus?.unshift({
    id: STATUS_ID_ALL,
    status: STATUS_SLUG_ALL,
    name: 'Gesamt',
    count: jobApplicants?.length
  });

  return jobApplicantsStatus;
}

export function getPrimaryStatus(status: TStatus | TStatuses) {
  if( Array.isArray(status) ) {
    return status[0];
  }
  return status;
}

