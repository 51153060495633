import { combineReducers } from './combineReducers';

import userReducer from './user/user.reducer';
import appReducer from './app/app.reducer';

export const initialState: any = {
  app: {
    data: null,
    loading: true
  },
  user: {
    data: null,
    loading: true
  }
};

export const reducers = combineReducers({
  app: appReducer,
  user: userReducer,  
});

export type AppState = ReturnType<typeof reducers>;