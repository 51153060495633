import { useMemo } from "react";
import { useConfigQuery, useStatusQuery } from "../../hooks/useApi";
import { IonIcon } from "@ionic/react";
import { informationCircleOutline, warningOutline } from "ionicons/icons";

import './InlineStatus.css';
import { TCardViewItemDetail, TStatus } from "../../types";

const InlineStatus: React.FC<{
  status: TCardViewItemDetail | TStatus,
  className?: string
}> = ({ status, className }) => {
  const statusData    = useStatusQuery();
  
  const isStatusObj       = status.hasOwnProperty('appstatusid');
  const isDetailStatusObj = status.hasOwnProperty('displayType');

  return <span className={`c-inline-status${!className ? '' : ' ' + className}`} style={isStatusObj ? {'color': `--color-status-${status.appstatusid}`} : {}}>
    <strong className='c-inline-status__name'>
      {isDetailStatusObj && status?.value}
      {isStatusObj && status?.appstatusname}
    </strong>
    <IonIcon style={{ marginLeft: '0.33rem', marginBottom: -3 }} icon={informationCircleOutline}></IonIcon>
  </span>
};

export default InlineStatus;