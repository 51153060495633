import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonRouterLink, IonTextarea, IonTitle, IonToolbar, useIonAlert, useIonToast } from "@ionic/react";
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import React, { useCallback, useMemo, useRef, useState } from "react";
import InlineAlert from "../components/inline/InlineAlert";

import { useTranslation } from "react-i18next";
import { useApplicantCommentMutation } from "../hooks/useApi";
import { APP_COMPONENT_NOTES_USE_BACK_ICON, TOAST_OPTIONS_DEFAULT } from "../utils/const";
import { getPageUrl } from "../utils/urls";
import './ModalApplicantNotes.css';

interface ModalApplicantNotesProps {
  applicantId: string,
  trigger: string
};

const ModalApplicantNotes: React.FC<ModalApplicantNotesProps> = ({ ...props }) => {
  const trigger = props.trigger;
  const applicantId = props.applicantId;

  const modal = useRef<HTMLIonModalElement>(null);
  const modalTextarea = useRef<HTMLIonTextareaElement>(null);
  const { t } = useTranslation();
  
  const [ presentToast ] = useIonToast();
  const [ presentAlert ] = useIonAlert();

  const commentMutation = useApplicantCommentMutation();

  const [ commentData, setCommentData ] = useState(null);
  const hasTextareaText = useMemo(() => {
    return !commentData || commentData === '' ? false : true;
  }, [ commentData ]);

  const canDismiss = useCallback(( forceClose: boolean | undefined ) => {

    if( !forceClose && modalTextarea.current?.value !== '' ) {

      return new Promise<boolean>((resolve) => {
        presentAlert({
          header: t('components.applicant.comments.alert.headline'),
          message: t('components.applicant.comments.alert.message'),
          buttons: [
            {
              text: t('components.applicant.comments.alert.cancel'),
              role: 'cancel',
              handler: () => {
                resolve(false);
              }
            },
            {
              text: t('components.applicant.comments.alert.confirm'),
              role: 'confirm',
              handler: () => {
                resolve(true);
              }
            },        
          ],      
        });
      });

    }

    return new Promise<boolean>((resolve) => {
      resolve(true);
    }); 

  }, [ modalTextarea, t, presentAlert ]);

  const saveAndDismiss = useCallback(async () => {
    let setSave = undefined;

    if( !setSave && modalTextarea.current?.value !== '' ) {
      
      try {      
        const commentMutationResult = await commentMutation.mutateAsync({
          applicationId: applicantId,
          commentedBy: '',
          content: modalTextarea.current!.value as string
        });
    
        presentToast({
          ...TOAST_OPTIONS_DEFAULT,        
          message: t('components.applicant.comments.modal.success'),        
          color: 'success'
        });

        setSave = true;

      } catch ( error ) {

        presentToast({
          ...TOAST_OPTIONS_DEFAULT,        
          message: t('components.applicant.comments.modal.error'),        
          color: 'danger'
        });

        return;
      }

    }
 
    modal.current?.dismiss(setSave);

  }, [ modalTextarea.current?.value, modal, t, presentToast, applicantId, ]);

  return (
    <IonModal className="c-modal c-modal--notes" ref={modal} canDismiss={canDismiss} trigger={trigger}>
      
      <IonHeader className="c-modal__header">
        <IonToolbar>
          {APP_COMPONENT_NOTES_USE_BACK_ICON && <IonButtons slot="start">
            <IonButton onClick={() => { modal.current?.dismiss() }}>
              <IonIcon src={arrowBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>}
          <IonTitle className="c-modal__title">{t('components.applicant.comments.modal.title')}</IonTitle>                  
          {!APP_COMPONENT_NOTES_USE_BACK_ICON && <IonButtons slot="end">
            <IonButton onClick={() => { modal.current?.dismiss() }}>
              <IonIcon src={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>}          
        </IonToolbar>
      </IonHeader>
      
      <IonContent className="c-modal__content">
        <div className="c-modal__content-inner">
          <InlineAlert color={"info"}>
            <p>
              <span dangerouslySetInnerHTML={{
                __html: t('components.applicant.comments.privacy.content') + ' '
              }}></span>
              <IonRouterLink routerLink={getPageUrl('privacy')} onClick={() => { modal.current?.dismiss() }}>
                {t('components.applicant.comments.privacy.more')}
              </IonRouterLink>
            </p>
          </InlineAlert>
          <div className="ion-padding-horizontal c-modal__content-grow">
            <IonTextarea 
              autoFocus={true}
              className="c-textarea" 
              ref={modalTextarea} 
              autoGrow={true}
              onIonInput={(ev: any) => {
                setCommentData( ev.detail.value );
              }}
              placeholder={t('components.applicant.comments.modal.placeholder')}></IonTextarea>
          </div>
        </div>
      </IonContent>

      <IonFooter className="c-modal__footer ion-padding">
        <IonButton disabled={!hasTextareaText} expand="block" onClick={saveAndDismiss}>{t('components.applicant.comments.save')}</IonButton>
      </IonFooter> 

    </IonModal>
  );

};

export default ModalApplicantNotes;